<template>
	<div id="kiem-tra-theo-phan-tram" class="kiem-tra-theo-phan-tram">
        kiem-tra-theo-phan-tram
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
	name: "KiemTraTheoPhanTram",
	data() {
		return {

		};
	},
	computed: {
		...mapState("KiemTraTheoPhanTram", []),
	},
	beforeCreate() {
		console.log("I have not been created yet!");
	},
	created() {
		console.log("I have just been created!");
	},
	beforeMount() {
		console.log("I am about to be mounted to the DOM!");
	},
	mounted() {
		console.log("I am mounted to the DOM!");
		this.forceMovePage();
	},
	beforeDestroy() {
		console.log("I am about to be removed from the DOM!");
	},
	destroyed() {
		console.log("I no longer exist...");
	},
	methods: {
		...mapActions("KiemTraTheoPhanTram", []),
	},
};
</script>

<style lang="scss">
.kiem-tra-theo-phan-tram {
}
</style>
