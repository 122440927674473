import ApiService from "./api.service";

const Domain = {
    getAll: async (url) => {
        url = url + "/domain/list-details";
        return ApiService.get(url);
    },
};

export default Domain;
