import ApiService from "./api.service";

const AuthRepository = {
    postAuth: async (url, data) => {
        url = url + "/auth/login";
        return ApiService.post(url, data);
    },
    getDetail: async (id) => {
        let url = `account/detail?userId=${id}`;
        return ApiService.get(url);
    },
};

export default AuthRepository;
