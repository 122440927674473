<template>
	<div id="luu-tru-cong-no" class="luu-tru-cong-no">
        luu-tru-cong-no
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
	name: "LuuTruCongNo",
	data() {
		return {

		};
	},
	computed: {
		...mapState("LuuTruCongNo", []),
	},
	beforeCreate() {
		console.log("I have not been created yet!");
	},
	created() {
		console.log("I have just been created!");
	},
	beforeMount() {
		console.log("I am about to be mounted to the DOM!");
	},
	mounted() {
		console.log("I am mounted to the DOM!");
		this.forceMovePage();
	},
	beforeDestroy() {
		console.log("I am about to be removed from the DOM!");
	},
	destroyed() {
		console.log("I no longer exist...");
	},
	methods: {
		...mapActions("LuuTruCongNo", []),
	},
};
</script>

<style lang="scss">
.luu-tru-cong-no {
}
</style>
