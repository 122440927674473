<template>
    <div id="login" class="login">
        <div class="login-left">
            <div class="login-box">
                <div class="f1-invoice">
                    <div>
                        <img :src="getImg('logo', 'jpg')" />
                    </div>
                    <div>
                        <span>
                            INVOICE
                        </span>
                    </div>
                    <div v-if="!$root.isSystemMaintenance">
                        <span>
                            Vui lòng nhập thông tin đăng nhập
                        </span>
                    </div>
                </div>
                <div class="body-box mt-3 mb-3 p-3">
                    <b-row v-if="!$root.isSystemMaintenance" class="mb-3">
                        <b-col>
                            <div class="text-left">Tài Khoản</div>
                            <b-input-group>
                                <b-form-input v-model="userName"></b-form-input>
                            </b-input-group>
                        </b-col>
                    </b-row>
                    <b-row v-if="!$root.isSystemMaintenance" class="mb-3">
                        <b-col>
                            <div class="text-left">Mật Khẩu</div>
                            <b-input-group>
                                <b-form-input v-model="password" :type="isShowPass ? 'text' : 'password'">
                                </b-form-input>
                            </b-input-group>
                        </b-col>
                    </b-row>
                    <b-row v-if="!$root.isSystemMaintenance" class="mb-1">
                        <b-col>
                            <b-form-checkbox v-model="isShowPass" switch>
                                Hiển thị mật khẩu
                            </b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row v-if="!$root.isSystemMaintenance" class="mb-2">
                        <b-col>
                            <b-form-checkbox v-model="isDarkMode" switch
                                @change="setThemePreference(isDarkMode ? 'dark' : 'light')">
                                Giao diện tối
                            </b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row v-if="!$root.isSystemMaintenance && false" class="mb-3">
                        <b-col>
                            <b-form-checkbox v-model="isGhiNhoDangNhap" switch>
                                Ghi nhớ đăng nhập
                            </b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row v-if="$root.isSystemMaintenance" class="mb-3">
                        <b-col offset="2" cols="8" class="text-maintenance">
                            {{ $root.dataMaintenance.description.split('. ')[0] }}
                            <br>
                            {{ $root.dataMaintenance.description.split('. ')[1] }}
                        </b-col>
                    </b-row>
                </div>
                <div class="footer-box mt-3">
                    <b-row v-if="!$root.isSystemMaintenance" class="mb-4 button-action">
                        <b-button variant="primary" size="lg" class="mt-3" @click="submit">
                            ĐĂNG NHẬP
                        </b-button>
                    </b-row>
                    <b-row v-if="$root.isSystemMaintenance" class="mt-3 text-maintenance">
                        <b-col cols="12">Thời gian kết thúc bảo trì</b-col>
                        <b-col cols="12" class="ngay-ket-thuc">
                            {{ getFormatDate($root.dataMaintenance.endDate, 'formatThoiGianDau') }}
                        </b-col>
                    </b-row>
                    <b-row v-if="$root.isSystemMaintenance" class="mt-3 text-maintenance">
                        <b-col cols="12">Thời gian còn lại</b-col>
                    </b-row>
                    <b-row v-if="$root.isSystemMaintenance" class="mt-1 text-maintenance dem-nguoc">
                        <b-col cols="3" class="dem-nguoc-ngay">
                            <div>{{ $root.demNguocNgay }}</div>
                            <div>Ngày</div>
                        </b-col>
                        <b-col cols="3" class="dem-nguoc-gio">
                            <div>{{ $root.demNguocGio }}</div>
                            <div>Giờ</div>
                        </b-col>
                        <b-col cols="3" class="dem-nguoc-phut">
                            <div>{{ $root.demNguocPhut }}</div>
                            <div>Phút</div>
                        </b-col>
                        <b-col cols="3" class="dem-nguoc-giay">
                            <div>{{ $root.demNguocGiay }}</div>
                            <div>Giây</div>
                        </b-col>
                    </b-row>
                    <b-row v-if="$root.isSystemMaintenance" class="mt-4 mb-4 text-maintenance">
                        <b-col cols="12">Xin lỗi vì sự bất tiện này</b-col>
                    </b-row>
                    <!-- <b-row class="footer-label mt-4">
                        Phần mềm được phát triển bởi công ty <b class="ml-1">F1</b>
                    </b-row> -->
                </div>
            </div>
        </div>
        <div class="login-right">
            <div class="back-img">
                <img v-if="!$root.isSystemMaintenance" :src="getImg('back-right-login', 'png')" />
                <img v-if="$root.isSystemMaintenance" :src="getImg('bao-tri', 'svg')" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import AuthRepository from "../../../service/auth_repository.service";
import Account from "../../../service/account.service";
import Domain from "../../../service/domain.service";
import Formulas from "../../../service/formulas.service";
import { Socket } from '@/websocket/websocket'

export default {
    name: "LoginPage",
    data() {
        return {
            userName: "",
            password: "",
            isShowPass: false,
            isDarkMode: false,
            isGhiNhoDangNhap: false,
        };
    },
    computed: {
        ...mapState("LoginPage", []),
    },
    beforeCreate() {
        console.log("I have not been created yet!");
    },
    created() {
        console.log("I have just been created!");
    },
    beforeMount() {
        console.log("I am about to be mounted to the DOM!");
    },
    mounted() {
        console.log("I am mounted to the DOM!");
        this.forceMovePage();
        this.setThemePreference();

        let token = this.getCookie('token') ? this.getCookie('token') : localStorage.getItem("jwt");
        if (token) {
            localStorage.setItem("jwt", token)
            let userId = this.getCookie('userId');
            if (userId) {
                this.getDetail({ id: userId });
            } else {
                // this.$router.push("trang-chu");
                this.$router.push("nhan-giao");
            }
        }

        window.addEventListener('keydown', this.keydown);
    },
    beforeDestroy() {
        console.log("I am about to be removed from the DOM!");
    },
    destroyed() {
        console.log("I no longer exist...");
        window.removeEventListener('keydown', this.keydown);
    },
    methods: {
        ...mapActions("LoginPage", []),
        keydown(e) {
            let key = e.keyCode || e.which;
			let isForcusDropdown = e.target.className === 'multiselect__input';
			if (isForcusDropdown) {
				return;
			}
            switch (key) {
                case 13: // enter
                    this.submit();
                    e.preventDefault();
                    break;
            }
        },
        setThemePreference(mode) {
            if (mode) {
                localStorage.setItem('themeColor', mode);
                this.switchHtmlTheme(mode);
            } else {
                let theme = 'light';
                if (localStorage.getItem('themeColor')) {
                    theme = localStorage.getItem('themeColor');
                } else {
                    localStorage.setItem('themeColor', theme);
                }
                this.isDarkMode = theme === 'dark';
                this.switchHtmlTheme(theme);
            }
        },
        getDetail(dataLogin) {
            let _this = this;
            Account.getDetail(_this.$root.url, dataLogin.id)
                .then(async (response) => {
                    localStorage.setItem("user", JSON.stringify(response.data));
                    localStorage.setItem("userId", response.data.id);
                    localStorage.setItem("userName", response.data.userName);
                    localStorage.setItem('rateUsd', response.data.rateUsd ? response.data.rateUsd : 0);
                    _this.$root.rateUsd = response.data.rateUsd ? response.data.rateUsd : 0;
                    await _this.getDomain();
                    await _this.getCombo();
                    await _this.getFormulas();

                    if (localStorage.getItem("jwt") != null) {
                        if (_this.$route.params.nextUrl != null) {
                            _this.$router.push(_this.$route.params.nextUrl);
                        } else {
                            // _this.$router.push("trang-chu");
                            _this.$router.push("nhan-giao");
                        }
                    }
                    _this.$root.isLogin = true;
                    _this.$root.isLoginLanDau = dataLogin.firstLogin;
                    _this.$root.showHuongDan = dataLogin.firstLogin;

                    let prefixToken = "Bearer";
                    let url = this.$root.url.replace('https', 'wss').replace('http', 'ws');
                    Socket.onConnect(url, prefixToken + " " + response.data.lastToken);

                    if (_this.isGhiNhoDangNhap) {
                        _this.createCookie();
                    }
                })
                .catch((error) => {
                    _this.errorApi(error, _this);
                });

        },
        showPass() {
            this.isShowPass = !this.isShowPass;
        },
        submit() {
            let _this = this;
            localStorage.setItem('loginTime', new Date());
            AuthRepository.postAuth(_this.$root.url, {
                userName: _this.userName,
                password: _this.password,
            })
                .then((response) => {
                    localStorage.setItem("jwt", response.data.data.token);
                    _this.getDetail(response.data.data)
                })
                .catch((error) => {
                    _this.errorApi(error, _this);
                });
        },
        async getDomain() {
            let _this = this;
            await Domain.getAll(this.$root.url)
                .then((response) => {
                    localStorage.setItem('DATA_NHA_CAI', JSON.stringify(response.data));
                })
                .catch((error) => {
                    _this.errorApi(error, _this);
                });
        },
        async getCombo() {
            let _this = this;
            await Formulas.getCombo(this.$root.url)
                .then((response) => {
                    localStorage.setItem('DATA_COMBO', JSON.stringify(response.data));
                }).catch((error) => {
                    _this.errorApi(error, _this);
                });
        },
        async getFormulas() {
            let _this = this;
            await Formulas.getCategory(this.$root.url)
                .then((response) => {
                    localStorage.setItem('DATA_FORMULA_CATEGORY', JSON.stringify(response.data));
                })
                .catch((error) => {
                    _this.errorApi(error, _this);
                });
        },
    },
};
</script>

<style lang="scss">
.style-for-web .login {
    display: flex;
    background-image: var(--background-image);

    .login-right {
        .back-img {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;

            img {
                width: 70%;
            }
        }

        height: 100vh;
        width: 50vw;
        display: flex;
    }

    .login-left {
        height: 100vh;
        width: 50vw;
        background-size: cover;
        display: flex;
    }

    .login-box {
        width: 50%;
        margin: auto;
        padding: 10px;
        border-radius: 32px;
        background: var(--menu-bar-background);
        color: var(--text-color);
        z-index: 2;
        box-shadow: 0px 2px 6px var(--active-tab-box-shadow);

        .f1-invoice {
            padding: 10px;
            margin: auto;
            flex-wrap: wrap;

            >div {
                width: 100%;
                margin-bottom: 10px;
                text-align: center;
                font-weight: bold;

                &:last-child {
                    font-size: 16px;
                    font-weight: normal;
                }
            }

            img {
                height: 150px;
                width: 150px;
                background: var(--white-color);
                border-radius: 12px;
            }
        }

        .body-box {
            text-align: left;
        }

        .input-login {
            height: 70px;
            display: flex;
            align-items: center;
            justify-content: center;

            .vs-input-parent {
                width: 80%;

                input {
                    width: 100%;
                }
            }
        }

        .con-switch {
            display: flex;
            align-items: center;
            justify-content: center;

            .vs-switch {
                margin-right: 10px;
            }
        }

        .footer-box {

            button {
                margin-top: 5vh;
                width: 60%;
            }

            .footer-label {
                width: 100%;
                justify-content: center;
            }

            .ngay-ket-thuc {
                font-weight: bold;
                font-size: 20px;
            }

            .dem-nguoc {
                border-radius: 18px;
                width: 75%;
                margin: auto;
                justify-content: center;
                align-items: center;
                background-color: var(--dem-nguoc-background);
                padding: 10px 20px;
            }

            .dem-nguoc-ngay,
            .dem-nguoc-gio,
            .dem-nguoc-phut,
            .dem-nguoc-giay {
                div {
                    font-weight: bold;
                    color: var(--text-nguoc-color);

                    &:first-child {
                        font-size: 35px;
                        height: 40px;
                    }
                }
            }
        }
    }

    .text-maintenance {
        text-align: center;
    }
}
</style>
