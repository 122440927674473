import ApiService from "./api.service";

const Accounting = {
    printInvoiceExcel: async (url, data, loaiFile, isSave) => {
        url = url + `/accounting/print-invoice-excel?type=${loaiFile}&isSave=${isSave}`;
		let postConfig = {
			responseType: 'arraybuffer',
		}
        return ApiService.download(url, data, postConfig);
    },
    getHistoryReport: async (url, data) => {
        url = url + "/accounting/history-report";
        return ApiService.get(url, data);
    },
    getHistoryReportDetail: async (url, data) => {
        url = url + "/accounting/history-report-detail";
        return ApiService.get(url, data);
    },
};

export default Accounting;
