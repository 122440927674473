<template>
	<div id="huong-dan-nhan-giao-page" class="huong-dan-nhan-giao-page" @click="onClick()">
		<div v-for="(item, index) in dataHuongDan" :key="index" :id="`text-${index + 1}`" class="text-huong-dan">
			<div v-html="item.text"></div>
			<div :class="item.arrow" class="arrow"></div>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
	name: "HuongDanNhanGiaoPage",
	props: [
		'mode',
	],
	data() {
		return {
			dataHuongDan: [
				// tab đầu nhận
				{
					mode: 1,
					text: 'CHỌN ĐỂ XEM THÔNG TIN ĐẦU NHẬN',
					arrow: 'arrow-top'
				},
				{
					mode: 1,
					text: 'CHỈNH SỬA ĐẦU NHẬN',
					arrow: 'arrow-left'
				},
				{
					mode: 1,
					text: 'XÓA ĐẦU NHẬN',
					arrow: 'arrow-left'
				},
				{
					mode: 1,
					text: 'NHẤN VÀ CHỌN ĐỂ LỌC DỮ LIỆU',
					arrow: 'arrow-top'
				},
				// tab đầu giao
				{
					mode: 2,
					text: 'NHẤN ĐỂ XEM CHI TIẾT ĐẦU GIAO',
					arrow: 'arrow-top'
				},
				{
					mode: 2,
					text: 'CHỈNH SỬA ĐẦU GIAO',
					arrow: 'arrow-top-right'
				},
				{
					mode: 2,
					text: 'XÓA ĐẦU GIAO',
					arrow: 'arrow-top-left'
				},
				// modal thêm đầu nhận
				{
					mode: 3,
					text: 'Bước 1: NHẬP TÊN ĐẦU NHẬN',
					arrow: 'arrow-left'
				},
				{
					mode: 3,
					text: 'Bước 2: NHẬP SỐ ĐIỆN THOẠI',
					arrow: 'arrow-bottom'
				},
				{
					mode: 3,
					text: 'Bước 3: NHẬP CHÚ THÍCH<br>(Không Bắt Buộc)',
					arrow: 'arrow-right'
				},
				{
					mode: 3,
					text: 'Bước 4: CHỌN NHÀ CÁI',
					arrow: 'arrow-left'
				},
				{
					mode: 3,
					text: 'Bước 5: CHỌN CÁCH TÍNH TIỀN',
					arrow: 'arrow-right'
				},
				{
					mode: 3,
					text: 'Bước 6: CHỌN VÀ NHẬP<br>(Loại Tiền, Hoa Hồng, Giá Thầu)',
					arrow: 'arrow-left'
				},
				{
					mode: 3,
					text: 'THÊM NHÀ CÁI (Nếu Cần)',
					arrow: 'arrow-right'
				},
				{
					mode: 3,
					text: 'Bước 7: THÊM HOẶC LƯU ĐẦU NHẬN',
					arrow: 'arrow-right'
				},
				// modal thêm đầu giao
				{
					mode: 4,
					text: 'Bước 1: NHẬP TÊN ĐẦU GIAO',
					arrow: 'arrow-left'
				},
				{
					mode: 4,
					text: 'Bước 2: NHẬP BÍ DANH',
					arrow: 'arrow-right'
				},
				{
					mode: 4,
					text: 'Bước 3: NHẬP SỐ ĐIỆN THOẠI',
					arrow: 'arrow-left'
				},
				{
					mode: 4,
					text: 'Bước 4: NHẬP CHÚ THÍCH<br>(Không Bắt Buộc)',
					arrow: 'arrow-right'
				},
				{
					mode: 4,
					text: 'Bước 5: THÊM HOẶC LƯU ĐẦU GIAO',
					arrow: 'arrow-top'
				},
			]

		};
	},
	computed: {
		...mapState("HuongDanNhanGiaoPage", []),
	},
	beforeCreate() {
		console.log("I have not been created yet!");
	},
	created() {
		console.log("I have just been created!");
	},
	beforeMount() {
		console.log("I am about to be mounted to the DOM!");
	},
	mounted() {
		console.log("I am mounted to the DOM!");
		this.forceMovePage();
		this.setPosition();
	},
	beforeDestroy() {
		console.log("I am about to be removed from the DOM!");
	},
	destroyed() {
		console.log("I no longer exist...");
	},
	methods: {
		...mapActions("HuongDanNhanGiaoPage", []),
		onClick() {
			this.$emit('onClick', false);
		},
		setPosition() {
			let _this = this;
			setTimeout(() => {
				switch (this.mode) {
					case 1:
						_this.setPosition1();
						break;
					case 2:
						_this.setPosition2();
						break;
					case 3:
						_this.setPosition3();
						break;
					case 4:
						_this.setPosition4();
						break;
				}
			}, 1000);
		},
		setPosition1() {
			let domImgs = document.getElementById('nhan-giao-page').getElementsByTagName('img');
			let domItemDN = document.getElementById('nhan-giao-page').getElementsByClassName('item-dau-nhan')[0];
			let domDropdownDN = document.getElementById('nhan-giao-page').getElementsByClassName('dropdown-dau-nhan')[0];
			let textHuongDan1;
			let textHuongDan2;
			let textHuongDan3;
			let textHuongDan4;
			let top = 0;
			let left = 0;
			if (!textHuongDan1 && domItemDN) {
				textHuongDan1 = document.getElementById('text-1');
				top = domItemDN.getBoundingClientRect().top + domItemDN.getBoundingClientRect().height + 10;
				left = domItemDN.getBoundingClientRect().left - (textHuongDan1.clientWidth / 3);
				textHuongDan1.style.top = `${top}px`;
				textHuongDan1.style.left = `${left}px`;
				textHuongDan1.style.opacity = 1;
			}
			for (const ele of domImgs) {
				switch (ele.title) {
					case 'Chỉnh sửa':
						if (!textHuongDan2) {
							textHuongDan2 = document.getElementById('text-2');
							top = ele.getBoundingClientRect().top - (ele.getBoundingClientRect().height / 2);
							left = ele.getBoundingClientRect().left - textHuongDan2.offsetWidth - 30;
							textHuongDan2.style.top = `${top}px`;
							textHuongDan2.style.left = `calc(${left}px - 1rem)`;
							textHuongDan2.style.opacity = 1;
						}
						break;
					case 'Xóa':
						if (!textHuongDan3) {
							textHuongDan3 = document.getElementById('text-3');
							top = ele.getBoundingClientRect().top - (ele.getBoundingClientRect().height / 2);
							left = ele.getBoundingClientRect().left - textHuongDan3.offsetWidth - 30;
							textHuongDan3.style.top = `${top}px`;
							textHuongDan3.style.left = `calc(${left}px - 1rem)`;
							textHuongDan3.style.opacity = 1;
						}
						break;
				}
			}
			if (!textHuongDan4 && domDropdownDN) {
				textHuongDan4 = document.getElementById('text-4');
				top = domDropdownDN.getBoundingClientRect().top + 45;
				left = domDropdownDN.getBoundingClientRect().left;
				textHuongDan4.style.top = `${top}px`;
				textHuongDan4.style.left = `${left}px`;
				textHuongDan4.style.opacity = 1;
			}
		},
		setPosition2() {
			let domImgs = document.getElementById('nhan-giao-page').getElementsByTagName('img');
			let domLink = document.getElementById('nhan-giao-page').getElementsByClassName('btn-link')[0];
			let textHuongDan5;
			let textHuongDan6;
			let textHuongDan7;
			let top = 0;
			let left = 0;
			if (!textHuongDan5 && domLink) {
				textHuongDan5 = document.getElementById('text-5');
				top = domLink.getBoundingClientRect().top + domLink.getBoundingClientRect().height + 10;
				left = domLink.getBoundingClientRect().left - (textHuongDan5.clientWidth / 2);
				textHuongDan5.style.top = `${top}px`;
				textHuongDan5.style.left = `${left}px`;
				textHuongDan5.style.opacity = 1;
			}
			for (const ele of domImgs) {
				switch (ele.title) {
					case 'Chỉnh sửa':
						if (!textHuongDan6) {
							textHuongDan6 = document.getElementById('text-6');
							top = ele.getBoundingClientRect().top + 40;
							left = ele.getBoundingClientRect().left - textHuongDan6.offsetWidth + ele.getBoundingClientRect().width + 20;
							textHuongDan6.style.top = `${top}px`;
							textHuongDan6.style.left = `calc(${left}px - 1rem)`;
							textHuongDan6.style.opacity = 1;
						}
						break;
					case 'Xóa':
						if (!textHuongDan7) {
							textHuongDan7 = document.getElementById('text-7');
							top = ele.getBoundingClientRect().top + 40;
							left = ele.getBoundingClientRect().left + 10;
							textHuongDan7.style.top = `${top}px`;
							textHuongDan7.style.left = `calc(${left}px - 1rem)`;
							textHuongDan7.style.opacity = 1;
						}
						break;
				}
			}
		},
		setPosition3() {
			let domModal = document.getElementById('modal-them-dau-nhan___BV_modal_content_');
			let domText = domModal.getElementsByClassName('text-left');
			let domBtnTNC = domModal.getElementsByClassName('btn-tnc')[0];
			let domBtn = domModal.getElementsByClassName('btn btn-primary btn-block')[0];
			let textHuongDan8;
			let textHuongDan9;
			let textHuongDan10;
			let textHuongDan11;
			let textHuongDan12;
			let textHuongDan13;
			let textHuongDan14;
			let textHuongDan15;
			let top = 0;
			let left = 0;
			for (const ele of domText) {
				console.log(ele.innerText);
				switch (ele.innerText) {
					case 'Tên Đầu Nhận':
						if (!textHuongDan8) {
							textHuongDan8 = document.getElementById('text-8');
							top = ele.getBoundingClientRect().top;
							left = ele.getBoundingClientRect().left - textHuongDan8.offsetWidth;
							textHuongDan8.style.top = `${top}px`;
							textHuongDan8.style.left = `calc(${left}px - 0.5rem)`;
							textHuongDan8.style.opacity = 1;
						}
						break;
					case 'Số Điện Thoại':
						if (!textHuongDan9) {
							textHuongDan9 = document.getElementById('text-9');
							top = ele.getBoundingClientRect().top - 65;
							left = ele.getBoundingClientRect().left - (ele.getBoundingClientRect().width / 2);
							textHuongDan9.style.top = `${top}px`;
							textHuongDan9.style.left = `calc(${left}px + 0.5rem)`;
							textHuongDan9.style.opacity = 1;
						}
						break;
					case 'Chú Thích':
						if (!textHuongDan10) {
							textHuongDan10 = document.getElementById('text-10');
							top = ele.getBoundingClientRect().top;
							left = ele.getBoundingClientRect().left + ele.getBoundingClientRect().width;
							textHuongDan10.style.top = `${top}px`;
							textHuongDan10.style.left = `calc(${left}px + 0.5rem)`;
							textHuongDan10.style.opacity = 1;
						}
						break;
					case 'Tên Nhà Cái':
						if (!textHuongDan11) {
							textHuongDan11 = document.getElementById('text-11');
							top = ele.getBoundingClientRect().top;
							left = ele.getBoundingClientRect().left - textHuongDan11.offsetWidth;
							textHuongDan11.style.top = `${top}px`;
							textHuongDan11.style.left = `calc(${left}px - 0.5rem)`;
							textHuongDan11.style.opacity = 1;
						}
						break;
					case 'Chọn Cách Tính Tiền':
						if (!textHuongDan12) {
							textHuongDan12 = document.getElementById('text-12');
							top = ele.getBoundingClientRect().top;
							left = ele.getBoundingClientRect().left + ele.getBoundingClientRect().width;
							textHuongDan12.style.top = `${top}px`;
							textHuongDan12.style.left = `calc(${left}px + 1rem)`;
							textHuongDan12.style.opacity = 1;
						}
						break;
					case 'Loại Chơi':
						if (!textHuongDan13) {
							textHuongDan13 = document.getElementById('text-13');
							top = ele.getBoundingClientRect().top;
							left = ele.getBoundingClientRect().left - textHuongDan13.offsetWidth;
							textHuongDan13.style.top = `${top}px`;
							textHuongDan13.style.left = `calc(${left}px - 1.5rem)`;
							textHuongDan13.style.opacity = 1;
						}
						break;
				}
			}
			if (!textHuongDan14 && domBtnTNC) {
				textHuongDan14 = document.getElementById('text-14');
				top = domBtnTNC.getBoundingClientRect().top;
				left = domBtnTNC.getBoundingClientRect().left + domBtnTNC.getBoundingClientRect().width;
				textHuongDan14.style.top = `${top}px`;
				textHuongDan14.style.left = `calc(${left}px + 1rem)`;
				textHuongDan14.style.opacity = 1;
			}
			if (!textHuongDan15 && domBtn) {
				textHuongDan15 = document.getElementById('text-15');
				top = domBtn.getBoundingClientRect().top;
				left = domBtn.getBoundingClientRect().left + domBtn.getBoundingClientRect().width;
				textHuongDan15.style.top = `${top}px`;
				textHuongDan15.style.left = `calc(${left}px + 1rem)`;
				textHuongDan15.style.opacity = 1;
			}

		},
		setPosition4() {
			let domModal = document.getElementById('modal-them-dau-giao___BV_modal_content_');
			let domText = domModal.getElementsByClassName('text-left');
			let domBtn = domModal.getElementsByClassName('btn btn-primary btn-block')[0];
			let textHuongDan16;
			let textHuongDan17;
			let textHuongDan18;
			let textHuongDan19;
			let textHuongDan20;
			let top = 0;
			let left = 0;
			for (const ele of domText) {
				console.log(ele.innerText);
				switch (ele.innerText) {
					case 'Tên Đầu Giao':
						if (!textHuongDan16) {
							textHuongDan16 = document.getElementById('text-16');
							top = ele.getBoundingClientRect().top;
							left = ele.getBoundingClientRect().left - textHuongDan16.offsetWidth;
							textHuongDan16.style.top = `${top}px`;
							textHuongDan16.style.left = `calc(${left}px - 0.5rem)`;
							textHuongDan16.style.opacity = 1;
						}
						break;
					case 'Bí Danh':
						if (!textHuongDan17) {
							textHuongDan17 = document.getElementById('text-17');
							top = ele.getBoundingClientRect().top;
							left = ele.getBoundingClientRect().left + ele.getBoundingClientRect().width;
							textHuongDan17.style.top = `${top}px`;
							textHuongDan17.style.left = `calc(${left}px + 0.5rem)`;
							textHuongDan17.style.opacity = 1;
						}
						break;
					case 'Số Điện Thoại':
						if (!textHuongDan18) {
							textHuongDan18 = document.getElementById('text-18');
							top = ele.getBoundingClientRect().top;
							left = ele.getBoundingClientRect().left - textHuongDan18.offsetWidth;
							textHuongDan18.style.top = `${top}px`;
							textHuongDan18.style.left = `calc(${left}px - 0.5rem)`;
							textHuongDan18.style.opacity = 1;
						}
						break;
					case 'Chú Thích':
						if (!textHuongDan19) {
							textHuongDan19 = document.getElementById('text-19');
							top = ele.getBoundingClientRect().top;
							left = ele.getBoundingClientRect().left + ele.getBoundingClientRect().width;
							textHuongDan19.style.top = `${top}px`;
							textHuongDan19.style.left = `calc(${left}px + 0.5rem)`;
							textHuongDan19.style.opacity = 1;
						}
						break;
				}
			}
			if (!textHuongDan20 && domBtn) {
				textHuongDan20 = document.getElementById('text-20');
				top = domBtn.getBoundingClientRect().top + 50;
				left = domBtn.getBoundingClientRect().left - (textHuongDan20.clientWidth / 2);
				textHuongDan20.style.top = `${top}px`;
				textHuongDan20.style.left = `calc(${left}px + 4rem)`;
				textHuongDan20.style.opacity = 1;
			}

		},
	},
};
</script>

<style lang="scss">
.style-for-web .huong-dan-nhan-giao-page {
	position: absolute;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	z-index: 9999;
	// display: contents;

	.text-huong-dan {
		position: absolute;
		white-space: nowrap;
		font-weight: bold;
		font-size: 20px;
		background-color: var(--huong-dan-background);
		padding: 10px;
		border: 2px solid var(--huong-dan-border);
		border-radius: 8px;
		opacity: 0;
		transition: all 0.3s;
		z-index: 9999;

		.arrow {
			position: absolute;
			display: block;
			width: 1rem;
			height: 0.5rem;
			margin: 0 0.3rem;

			&::before,
			&::after {
				position: absolute;
				display: block;
				content: "";
				border-color: transparent;
				border-style: solid;
			}
		}

		.arrow-bottom {
			left: 50%;
			bottom: 0;

			&::before,
			&::after {
				transform: rotate(45deg);
				border-bottom-color: var(--huong-dan-border) !important;
				border-width: 0 0 1rem 1rem;
			}
		}

		.arrow-top {
			left: 50%;
			top: -0.5rem;

			&::before,
			&::after {
				transform: rotate(45deg);
				border-top-color: var(--huong-dan-border) !important;
				border-width: 1rem 1rem 0rem 0rem;
			}
		}

		.arrow-top-left {
			left: 0.5rem;
			top: -0.5rem;

			&::before,
			&::after {
				transform: rotate(45deg);
				border-top-color: var(--huong-dan-border) !important;
				border-width: 1rem 1rem 0rem 0rem;
			}
		}

		.arrow-top-right {
			right: 0.5rem;
			top: -0.5rem;

			&::before,
			&::after {
				transform: rotate(45deg);
				border-top-color: var(--huong-dan-border) !important;
				border-width: 1rem 1rem 0rem 0rem;
			}
		}

		.arrow-left {
			top: 50%;
			transform: translateY(-50%);
			right: -0.8rem;

			&::before,
			&::after {
				transform: rotate(-45deg);
				border-bottom-color: var(--huong-dan-border) !important;
				border-width: 0 0 1rem 1rem;
			}
		}

		.arrow-right {
			top: 50%;
			transform: translateY(-50%);
			left: -0.8rem;

			&::before,
			&::after {
				transform: rotate(135deg);
				border-bottom-color: var(--huong-dan-border) !important;
				border-width: 0 0 1rem 1rem;
			}
		}
	}
}
</style>
