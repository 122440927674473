<template>
	<div id="cau-hinh-page" class="cau-hinh-page">
		<b-row class="header-page">
			<b-col cols="4">
				<span class="title-page">
					CẤU HÌNH
				</span>
			</b-col>
		</b-row>

		<!-- Lưu Giá -->
		<div class="tab-ket-noi">
			<b-row>
				<b-col cols="12" class="tab-btn">
					<div>
						<b-button size="sm" :variant="showTab === '1' ? 'primary' : 'light'" @click="changeTab('1')"
							class="mr-1" :class="{ 'active-tab': showTab === '1' }">
							Trạng Thái Tài Khoản
						</b-button>
						<!-- <b-button size="sm" :variant="showTab === '2' ? 'primary' : 'light'" @click="changeTab('2')"
							class="ml-1" :class="{ 'active-tab': showTab === '2' }">
							Danh Sách Lưu Giá
						</b-button> -->
					</div>
				</b-col>
			</b-row>

			<b-row class="mt-3">
				<b-col cols="12">
					<!-- Trạng Thái Super -->
					<div v-if="showTab === '1'">
						<b-row class="mb-2">
							<b-col cols="9"></b-col>
							<b-col cols="3" class="text-right">
							</b-col>
						</b-row>
						<b-row class="mb-2">
							<b-col cols="12">
								<div class="mb-2">
									<b-row>
										<b-col cols="6">
											<b> TK đã kết nối </b>
										</b-col>
									</b-row>
								</div>
								<div class="mb-2">
									<b-row>
										<b-col cols="6">
											<b-input-group class="input-search">
												<b-input-group-prepend is-text>
													<img :src="getIcon('search-normal')" class="pr-2" />
												</b-input-group-prepend>
												<b-form-input ref="input-search" type="search" placeholder="Tìm kiếm"
													v-model="searchValueDaKetNoi"></b-form-input>
											</b-input-group>
										</b-col>
									</b-row>
								</div>
								<div class="table-cau-hinh-tai-khoan">
									<b-table sticky-header hover :head-variant="$root.theme" small show-empty
										emptyText="Không có dữ liệu" empty-filtered-text="Không có dữ liệu phù hợp tìm kiếm"
										:fields="fieldsDaKetNoi" :items="dataSuperDaKetNoi"
										:current-page="currentPageSuperDaKetNoi" :per-page="perPageSuperDaKetNoi"
										:filter="searchValueDaKetNoi" @filtered="onFilteredDaKetNoi"
										@row-clicked="onRowClicked">
										<template #empty="scope">
											<span>{{ scope.emptyText }}</span>
										</template>
										<template #cell(action)="data">
											<div class="text-left button-action">
												<b-button variant="primary" class="mr-2"
													:class="data.item.isShowSetting ? 'active-setting' : ''"
													@click="showSetting(data.item)">
													<img :src="getIcon('more')" />
												</b-button>
												<div class="group-button-action pl-2 pr-2" v-show="data.item.isShowSetting">
													<div @click="openKetNoiSuper(data.item, true)">
														<b-button size="sm" variant="primary" class="mr-2">
															<img :src="getIcon('edit-2')" />
														</b-button>
														<span class="ml-1">Chỉnh Sửa</span>
													</div>
													<div @click="setRowSelected(data.item)">
														<b-button size="sm" :id="'btn-xoa-da-' + data.item.index"
															variant="danger">
															<img :src="getIcon('trash')" />
														</b-button>
														<span class="ml-1">Xóa</span>
													</div>
												</div>
											</div>
										</template>
									</b-table>
									<b-pagination v-if="dataSuperDaKetNoi.length > 0" v-model="currentPageSuperDaKetNoi"
										pills :total-rows="totalRowsSuperDaKetNoi" :per-page="perPageSuperDaKetNoi"
										align="right">
										<template #first-text>
											<b-icon icon="chevron-bar-left"></b-icon>
										</template>
										<template #prev-text>
											<b-icon icon="chevron-left"></b-icon>
										</template>
										<template #next-text>
											<b-icon icon="chevron-right"></b-icon>
										</template>
										<template #last-text>
											<b-icon icon="chevron-bar-right"></b-icon>
										</template>
									</b-pagination>
								</div>
							</b-col>
						</b-row>
						<b-row>
							<b-col cols="12">
								<div class="mb-2">
									<b-row>
										<b-col cols="12">
											<b> TK chưa kết nối </b>
										</b-col>
									</b-row>
								</div>
								<div class="mb-2">
									<b-row>
										<b-col cols="6">
											<b-input-group class="input-search ">
												<b-input-group-prepend is-text>
													<img :src="getIcon('search-normal')" class="pr-2" />
												</b-input-group-prepend>
												<b-form-input ref="input-search" type="search" placeholder="Tìm kiếm"
													v-model="searchValueChuaKetNoi"></b-form-input>
											</b-input-group>
										</b-col>
										<b-col cols="6" class="text-right button-action">
											<b-button @click="openKetNoiSuper()" size="sm" class="btn-luu-gia">
												<img :src="getIcon('receipt-edit')" class="mr-2" />
												Lưu Giá TK
											</b-button>
										</b-col>
									</b-row>
								</div>
								<div class="table-cau-hinh-tai-khoan">
									<b-table sticky-header hover :head-variant="$root.theme" small show-empty
										emptyText="Không có dữ liệu" empty-filtered-text="Không có dữ liệu phù hợp tìm kiếm"
										:fields="fieldsChuaKetNoi" :items="dataSuperChuaKetNoi"
										:current-page="currentPageSuperChuaKetNoi" :per-page="perPageSuperChuaKetNoi"
										:filter="searchValueChuaKetNoi" @filtered="onFilteredChuaKetNoi">
										<template #empty="scope">
											<span>{{ scope.emptyText }}</span>
										</template>
										<template #cell(superName)="data">
											<div class="label-name">
												<span>{{ data.item.superName }}</span>
											</div>
										</template>
										<template #cell(action)="data">
											<div class="text-left button-action">
												<b-button size="sm" @click="openKetNoiSuper(data.item)" class="btn-luu-gia">
													<img :src="getIcon('receipt-edit')" title="Lưu giá tài khoản" />
												</b-button>
											</div>
										</template>
									</b-table>
									<b-pagination v-if="dataSuperChuaKetNoi.length > 0" v-model="currentPageSuperChuaKetNoi"
										pills :total-rows="totalRowsSuperChuaKetNoi" :per-page="perPageSuperChuaKetNoi"
										align="right">
										<template #first-text>
											<b-icon icon="chevron-bar-left"></b-icon>
										</template>
										<template #prev-text>
											<b-icon icon="chevron-left"></b-icon>
										</template>
										<template #next-text>
											<b-icon icon="chevron-right"></b-icon>
										</template>
										<template #last-text>
											<b-icon icon="chevron-bar-right"></b-icon>
										</template>
									</b-pagination>
								</div>
							</b-col>
						</b-row>
					</div>

					<!-- Danh Sách Lưu Giá -->
					<div v-if="showTab === '2'">
						<b-card-text>
							<b-form-group class="mb-2">
								<b-form-radio-group id="radio-group" v-model="showPercen" name="radio-sub-component"
									@change="changeShowPercen()">
									<b-form-radio :value="'0'">Lưu giá theo đầu giao</b-form-radio>
									<!-- <b-form-radio :value="'1'">Lưu giá theo phần trăm</b-form-radio> -->
								</b-form-radio-group>
							</b-form-group>
							<div class="table-cau-hinh-ds-ket-noi">
								<b-table sticky-header hover :head-variant="$root.theme" small show-empty
									emptyText="Không có dữ liệu" empty-filtered-text="Không có dữ liệu phù hợp tìm kiếm"
									:fields="fieldsDSKetNoi" :items="dataSuperDSKetNoi"
									:current-page="currentPageSuperDSKetNoi" :per-page="perPageSuperDSKetNoi">
									<template #head(receiverPrices)="scope">
										<div class="text-header-gia">{{ scope.label }}</div>
										<div class="header-gia">
											<div @click="changeSort('type')">
												<span>Loại Chơi</span>
												<div class="group-icon-sort">
													<b-icon class="icon-sort" :icon="getIconSort('type', 'up')">
													</b-icon>
													<b-icon class="icon-sort-down" :icon="getIconSort('type', 'down')">
													</b-icon>
												</div>
											</div>
											<div @click="changeSort('bids')">
												<span>Com</span>
												<div class="group-icon-sort">
													<b-icon class="icon-sort" :icon="getIconSort('bids', 'up')">
													</b-icon>
													<b-icon class="icon-sort-down" :icon="getIconSort('bids', 'down')">
													</b-icon>
												</div>
											</div>
											<div @click="changeSort('coefficients')">
												<span>Giá</span>
												<div class="group-icon-sort">
													<b-icon class="icon-sort" :icon="getIconSort('coefficients', 'up')">
													</b-icon>
													<b-icon class="icon-sort-down"
														:icon="getIconSort('coefficients', 'down')">
													</b-icon>
												</div>
											</div>
										</div>
									</template>
									<template #head(deliverPrices)="scope">
										<div class="text-header-gia">{{ scope.label }}</div>
										<div class="header-gia">
											<div @click="changeSort('type')">
												<span>Loại Chơi</span>
												<div class="group-icon-sort">
													<b-icon class="icon-sort" :icon="getIconSort('type', 'up')">
													</b-icon>
													<b-icon class="icon-sort-down" :icon="getIconSort('type', 'down')">
													</b-icon>
												</div>
											</div>
											<div @click="changeSort('bids')">
												<span>Com</span>
												<div class="group-icon-sort">
													<b-icon class="icon-sort" :icon="getIconSort('bids', 'up')">
													</b-icon>
													<b-icon class="icon-sort-down" :icon="getIconSort('bids', 'down')">
													</b-icon>
												</div>
											</div>
											<div @click="changeSort('coefficients')">
												<span>Giá</span>
												<div class="group-icon-sort">
													<b-icon class="icon-sort" :icon="getIconSort('coefficients', 'up')">
													</b-icon>
													<b-icon class="icon-sort-down"
														:icon="getIconSort('coefficients', 'down')">
													</b-icon>
												</div>
											</div>
										</div>
									</template>
								</b-table>
								<b-pagination v-if="dataSuperDSKetNoi.length > 0" v-model="currentPageSuperDSKetNoi" pills
									:total-rows="totalRowsSuperDSKetNoi" :per-page="perPageSuperDSKetNoi" align="right">
									<template #first-text>
										<b-icon icon="chevron-bar-left"></b-icon>
									</template>
									<template #prev-text>
										<b-icon icon="chevron-left"></b-icon>
									</template>
									<template #next-text>
										<b-icon icon="chevron-right"></b-icon>
									</template>
									<template #last-text>
										<b-icon icon="chevron-bar-right"></b-icon>
									</template>
								</b-pagination>
							</div>
						</b-card-text>
					</div>
				</b-col>
			</b-row>
		</div>

		<b-modal centered id="modal-ket-noi-super"
			:title="isEdit ? 'CHỈNH SỬA LƯU GIÁ TÀI KHOẢN' : 'THÊM LƯU GIÁ TÀI KHOẢN'" scrollable size="lg"
			content-class="style-for-mobile">
			<div class="d-block">
				<b-row class="mb-3">
					<b-col cols="6">
						<div class="text-left">Tài Khoản</div>
						<b-input-group>
							<multi-select v-model="superSelected" :options="dataSuperChuaKetNoi" :allow-empty="false"
								label="superName" track-by="superId" class="multi-select" placeholder=""
								@input="changeSuper(true)">
							</multi-select>
						</b-input-group>
					</b-col>
					<b-col cols="6">
						<div class="text-left">Nhà Cái</div>
						<b-input-group>
							<b-form-input :value="superSelected.domainName" readonly></b-form-input>
						</b-input-group>
					</b-col>
				</b-row>
				<b-row class="mb-3">
					<b-col cols="12">
						<div class="text-left">Đầu Nhận</div>
						<b-input-group>
							<b-form-input :value="superSelected.receiverName" readonly></b-form-input>
						</b-input-group>
					</b-col>
				</b-row>
				<b-row class="mb-3">
					<b-col cols="12">
						<div class="text-left group-label-dau-giao">
							Đầu Giao
							<img :src="getIcon('add-circle')" class="btn-them-dau-giao ml-1" v-b-tooltip.hover
								title="Thêm Đầu Giao" @click="openThemDauGiao" />
						</div>
						<multi-select v-model="dauGiaoSelected" :options="dataDauGiaoChuaChon" label="deliverName"
							track-by="id" class="multi-select mr-2" placeholder="" :allow-empty="false"
							@input="changeDauKhach()">
						</multi-select>
					</b-col>
				</b-row>
				<b-row>
					<b-col cols="12">
						<div class="text-left">Chọn cách tính tiền</div>
						<b-input-group>
							<multi-select v-model="comboObj" :options="dataCombo" :allow-empty="false" label="comboName"
								track-by="comboId" :preselect-first="true" class="multi-select" placeholder=""
								@input="changeCombo()">
							</multi-select>
						</b-input-group>
					</b-col>
				</b-row>
				<div v-if="listFormulaCategory && listFormulaCategory.length > 0" class="items-nha-cai">
					<b-row class="mb-3 p-2" v-for="(item, index) in listFormulaCategory" :key="index">
						<b-col cols="6">
							<div class="text-left">Loại Chơi</div>
							<b-input-group>
								<multi-select v-model="item.formulaCategory" :options="dataFormulaCategory"
									:allow-empty="false" label="shortname" track-by="formulaCategoryId"
									:preselect-first="true" class="multi-select" placeholder="" :disabled="true"
									@input="item.formulaCategoryId = item.formulaCategory.formulaCategoryId">
								</multi-select>
							</b-input-group>
						</b-col>
						<b-col cols="6">
							<div class="text-left">Loại Tiền</div>
							<b-input-group>
								<multi-select v-model="item.currencyObj" :options="dataCurrency" :allow-empty="false"
									label="name" track-by="key" :preselect-first="true" class="multi-select" placeholder=""
									@input="item.currency = item.currencyObj.key">
								</multi-select>
							</b-input-group>
						</b-col>
						<b-col cols="6">
							<div class="text-left">Hoa Hồng</div>
							<b-input-group>
								<b-form-input type="number" v-model="item.coefficients">
								</b-form-input>
							</b-input-group>
						</b-col>
						<b-col cols="6">
							<div class="text-left gia-thau">
								Giá Thầu
								<b-form-checkbox class="ml-2" v-model="item.isGiaThauPercen">
									%
								</b-form-checkbox>
							</div>
							<b-input-group>
								<b-form-input type="number" v-model="item.bids" @paste="changeBids($event, item)"
									@change="changeGiaThau(item)">
								</b-form-input>
								<template #append v-if="item.isGiaThauPercen">
									<b-input-group-text>
										<img :src="getIcon('percentage-square')" />
									</b-input-group-text>
								</template>
							</b-input-group>
						</b-col>
					</b-row>
				</div>
			</div>
			<template #modal-footer>
				<b-row class="w-100">
					<b-col cols="12">
						<b-form-checkbox v-if="!isEdit" id="checkbox-1" v-model="isThemKetNoiKhac">
							Lưu giá tài khoản khác
						</b-form-checkbox>
					</b-col>
				</b-row>
				<b-row class="w-100 mt-3">
					<b-col cols="4" class="group-btn-chia-phan-tram">
						<b-button :variant="isAnChia ? 'success' : ''" class="mr-1 btn-chia-phan-tram"
							@click="$bvModal.show('modal-chia-gia');">
							<img :src="getIcon('people')" />
						</b-button>
						{{ isAnChia ? 'Đã Chia %' : 'Chưa Chia %' }}
					</b-col>
					<b-col cols="4">
						<b-button block @click="onClickHuy('modal-ket-noi-super')">
							Hủy
						</b-button>
					</b-col>
					<b-col cols="4">
						<b-button variant="primary" block @click="submitKetNoiSuper">
							{{ isEdit ? 'Lưu' : 'Thêm' }}
						</b-button>
					</b-col>
				</b-row>
			</template>
		</b-modal>

		<b-modal centered id="modal-them-dau-giao" title="THÊM ĐẦU GIAO" scrollable size="lg"
			content-class="style-for-web">
			<div class="d-block">
				<b-row class="mb-3">
					<b-col cols="6">
						<div class="text-left">Tên Đầu Giao</div>
						<b-input-group>
							<b-form-input v-model="objThemDauGiao.deliverName"></b-form-input>
						</b-input-group>
					</b-col>
					<b-col cols="6">
						<div class="text-left">Bí Danh</div>
						<b-input-group>
							<b-form-input v-model="objThemDauGiao.code"></b-form-input>
						</b-input-group>
					</b-col>
				</b-row>
				<b-row class="mb-3">
					<b-col cols="6">
						<div class="text-left">Số Điện Thoại</div>
						<b-input-group>
							<b-form-input v-model="objThemDauGiao.phoneNumber"> </b-form-input>
						</b-input-group>
					</b-col>
					<b-col cols="6">
						<div class="text-left">Chú Thích</div>
						<b-input-group>
							<b-form-input v-model="objThemDauGiao.description"> </b-form-input>
						</b-input-group>
					</b-col>
				</b-row>
			</div>
			<template #modal-footer>
				<b-row class="w-100 mt-3">
					<b-col cols="4"></b-col>
					<b-col cols="4">
						<b-button block @click="onClickHuy('modal-them-dau-giao')">
							Hủy
						</b-button>
					</b-col>
					<b-col cols="4">
						<b-button variant="primary" block @click="submitThemDauGiao">
							Thêm
						</b-button>
					</b-col>
				</b-row>
			</template>
		</b-modal>

		<b-modal centered id="modal-chia-gia" title="CHIA PHẦN TRĂM" scrollable size="lg"
			:content-class="$root.isMobile ? 'style-for-mobile' : 'style-for-web'">
			<b-row class="mb-3">
				<b-col cols="12">
					<div class="text-left group-label-an-chia">
						<b-form-checkbox id="label-an-chia" class="mr-1" v-model="isAnChia"
							@change="changeIsAnChia"></b-form-checkbox>
						<label for="label-an-chia">
							Chia Phần Trăm
							{{ isAnChia ? `(100% = ${tongGia} giá)` : '' }} <br>
							{{ isAnChia ? `Đã chia ${tongPhanTramDaChia}%` : '' }}
							{{ isAnChia ? `- Còn lại ${phanTramConLai}%` : '' }} <br>
						</label>
					</div>
					<template v-if="isAnChia">
						<div class="group-an-chia mb-2" v-for="(item, index) in dataAnChia" :key="index">
							<div>
								<multi-select v-model="item.khachAnChia" :options="dataDauGiaoChuaChon" label="deliverName"
									track-by="id" class="multi-select mr-1" placeholder="" :allow-empty="false"
									@input="changeKhachAnChia()">
								</multi-select>
								<b-button v-if="index < dataAnChia.length - 1" variant="danger" id="btn-them-an-chia"
									@click="xoaKhachAnChia(index)">
									<img :src="getIcon('minus-cirlce')" />
								</b-button>
								<b-button v-else variant="success" id="btn-them-an-chia" @click="themKhachAnChia()">
									<img :src="getIcon('add-circle')" />
								</b-button>
							</div>
							<div>
								<b-input-group class="mr-1">
									<b-form-input type="number" v-model="item.phanTramAnChia"
										@change="tinhPhanTramVaGia(1, item)"
										:disabled="!item.khachAnChia.id"></b-form-input>
									<template #append>
										<b-input-group-text>
											<img :src="getIcon('percentage-square')" />
										</b-input-group-text>
									</template>
								</b-input-group>
								<b-input-group>
									<b-form-input type="number" v-model="item.giaAnChia"
										@change="tinhPhanTramVaGia(2, item)"
										:disabled="!item.khachAnChia.id"></b-form-input>
									<template #append>
										<b-input-group-text>Giá</b-input-group-text>
									</template>
								</b-input-group>
							</div>
						</div>
					</template>
				</b-col>
			</b-row>
			<template #modal-footer>
				<b-row class="w-100 mt-3">
					<b-col cols="4"></b-col>
					<b-col cols="4">
						<b-button id="btn-xac-nhan-ko-xoa" block @click="onClickHuy('modal-chia-gia')">
							Hủy
						</b-button>
					</b-col>
					<b-col cols="4">
						<b-button id="btn-xac-nhan-co-xoa" variant="primary" block @click="onClickLuuChiaGia()">
							Lưu
						</b-button>
					</b-col>
				</b-row>
			</template>
		</b-modal>

		<b-modal centered id="modal-xac-nhan-xoa" title="XÁC NHẬN XÓA LƯU GIÁ TÀI KHOẢN" scrollable size="sm"
			:content-class="$root.isMobile ? 'style-for-mobile' : 'style-for-web'">
			<div class="msg-xac-nhan-xoa">
				Bạn Chắc Chắn Muốn Xóa Lưu Giá Tài Khoản <b>{{ rowSelected.superName }}</b>
			</div>
			<template #modal-footer>
				<b-row class="w-100">
					<b-col cols="4"></b-col>
					<b-col cols="4">
						<b-button id="btn-xac-nhan-ko-xoa" class="mt-3" block @click="huyXoaSuperDaKetNoi()">
							Không
						</b-button>
					</b-col>
					<b-col cols="4">
						<b-button id="btn-xac-nhan-co-xoa" variant="danger" class="mt-3" block
							@click="xoaSuperDaKetNoi(rowSelected)">
							Có
						</b-button>
					</b-col>
				</b-row>
			</template>
		</b-modal>
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Map from "../../../service/map.service";
import Deliver from "../../../service/deliver.service";

export default {
	name: "CauHinhPage",
	data() {
		return {
			showTab: '1',
			showPercen: '0',
			searchValueDaKetNoi: '',
			searchValueChuaKetNoi: '',
			dataDauGiao: [],
			dataAnChia: [
				{
					khachAnChia: {},
					phanTramAnChia: '',
					giaAnChia: '',
				}
			],
			dataCombo: [],
			dataFormulaCategory: [],
			dataCurrency: [
				{
					key: 'VND',
					name: 'VNĐ',
				},
				{
					key: 'USD',
					name: 'USD',
				},
			],
			fieldsDaKetNoi: [
				{ key: 'action', label: '' },
				{ key: 'superName', label: 'Tên Tài Khoản' },
				{ key: 'domainName', label: 'Nhà Cái' },
				{ key: 'receiverName', label: 'Đầu Nhận' },
				{ key: 'deliverCount', label: 'TS Đầu Giao' },
			],
			fieldsChuaKetNoi: [
				{ key: 'action', label: '' },
				{ key: 'superName', label: 'Tên Tài Khoản' },
				{ key: 'domainName', label: 'Nhà Cái' },
			],
			fieldsDSKetNoi: [
				{ key: 'superName', label: 'Tên Tài Khoản' },
				{ key: 'domainName', label: 'Nhà Cái' },
				{ key: 'receiverName', label: 'Đầu Nhận' },
				{ key: 'receiverPrices', label: 'Giá Nhận' },
				{ key: 'deliverName', label: 'Đầu Giao' },
				{ key: 'deliverPrices', label: 'Giá Giao' },
			],
			dataSuperDaKetNoi: [],
			dataSuperChuaKetNoi: [],
			dataSuperDSKetNoi: [],
			totalRowsSuperDaKetNoi: 0,
			currentPageSuperDaKetNoi: 1,
			perPageSuperDaKetNoi: 15,
			totalRowsSuperChuaKetNoi: 0,
			currentPageSuperChuaKetNoi: 1,
			perPageSuperChuaKetNoi: 15,
			totalRowsSuperDSKetNoi: 0,
			currentPageSuperDSKetNoi: 1,
			perPageSuperDSKetNoi: 15,
			superSelected: {},
			dauGiaoSelected: {},
			comboId: '',
			comboObj: {},
			listFormulaCategory: [],
			comboSelected: {},
			formulasSelected: {},
			isEdit: false,
			objThemDauGiao: {},
			sortBy: '',
			sortDesc: true,
			isThemKetNoiKhac: false,
			isAnChia: false,
			tongGia: 0,
			phanTramConLai: 0,
			tongPhanTramDaChia: 0,
			isActiveShowSetting: false,
			rowSelected: {},
		};
	},
	computed: {
		...mapState("CauHinhPage", []),
		dataDauGiaoChuaChon() {
			let data = [];
			for (const item of this.dataDauGiao) {
				let index = this.dataAnChia.findIndex(i => i.khachAnChia.id === item.id);
				if (index === -1) {
					data.push(item);
				}
			}
			return data;
		}
	},
	beforeCreate() {
		console.log("I have not been created yet!");
	},
	created() {
		console.log("I have just been created!");
	},
	beforeMount() {
		console.log("I am about to be mounted to the DOM!");
	},
	mounted() {
		console.log("I am mounted to the DOM!");
		this.forceMovePage();
		this.getDeliver();
		this.getFormulas();
		this.changeTab(this.showTab);
		window.addEventListener('keydown', this.keydown);
	},
	beforeDestroy() {
		console.log("I am about to be removed from the DOM!");
	},
	destroyed() {
		console.log("I no longer exist...");
		window.removeEventListener('keydown', this.keydown);
	},
	methods: {
		...mapActions("CauHinhPage", []),
		keydown(e) {
			let key = e.keyCode || e.which;
			let isForcusDropdown = e.target.className === 'multiselect__input';
			if (isForcusDropdown) {
				return;
			}
			switch (key) {
				case 13: // enter
					if (document.getElementById('modal-them-super')) {
						this.themSuper();
					} else if (document.getElementById('modal-them-dau-giao')) {
						this.submitThemDauGiao();
					} else if (document.getElementById('modal-chia-gia')) {
						this.onClickLuuChiaGia();
					} else if (document.getElementById('modal-ket-noi-super')) {
						this.submitKetNoiSuper();
					} else if (document.getElementById('btn-xac-nhan-co-xoa-' + this.rowSelected.index)) {
						this.xoaSuper(this.rowSelected);
					}
					break;
				case 27: // esc
					if (document.getElementById('btn-xac-nhan-ko-xoa')) {
						this.huyXoaSuperDaKetNoi();
					}
					e.preventDefault();
					break;
				case 122: // f11
					this.openKetNoiSuper();
					e.preventDefault();
					break;
			}
		},
		getSuperStatus(isConnect) {
			let _this = this;
			Map.getSuperStatus(this.$root.url, isConnect)
				.then((response) => {
					if (isConnect) {
						_this.dataSuperDaKetNoi = response.data.map((i, index) => {
							i.index = index + 1;
							i.isShowSetting = false;
							return i;
						});
						_this.totalRowsSuperDaKetNoi = _this.dataSuperDaKetNoi.length;
					} else {
						_this.dataSuperChuaKetNoi = response.data.map((i, index) => {
							i.index = index + 1;
							return i;
						});
						_this.totalRowsSuperChuaKetNoi = _this.dataSuperChuaKetNoi.length;
						if (_this.isThemKetNoiKhac) {
							_this.superSelected = _this.dataSuperChuaKetNoi[0];
							_this.changeSuper(true);
						}
					}
				}).catch((error) => {
					_this.errorApi(error, _this);
				});
		},
		getFormulas() {
			this.dataFormulaCategory = JSON.parse(localStorage.getItem('DATA_FORMULA_CATEGORY'));
		},
		getDeliver() {
			let _this = this;
			Deliver.getTree(this.$root.url)
				.then((response) => {
					_this.dataDauGiao = response.data;
				}).catch((error) => {
					_this.errorApi(error, _this);
				});
		},
		getCombo(isEdit = false) {
			this.dataCombo = JSON.parse(localStorage.getItem('DATA_COMBO')).filter(i => i.domainId === this.superSelected.domainId);
			if (isEdit) {
				this.comboObj = this.dataCombo.filter(i => i.comboId === this.comboId)[0];
				this.changeCombo(isEdit);
			} else {
				this.comboObj = this.dataCombo.filter(i => i.comboId === this.superSelected.comboId)[0];
				if (this.comboObj) {
					this.changeCombo();
				}
			}
		},
		mapSuperDeliverFormula() {
			let formulas = [];
			for (const item of this.listFormulaCategory) {
				let obj = {
					bids: item.bids,
					categoryId: item.formulaCategoryId,
					coefficients: item.coefficients,
					comboId: this.comboSelected.comboId,
					currency: item.currency,
					isPercent: item.isGiaThauPercen,
					groupComboDomainSort: this.comboSelected.groupComboDomainSort,
				}
				formulas.push(obj);
			}
			let anChia = [];
			for (const item of this.dataAnChia) {
				if (item.khachAnChia && item.khachAnChia.id) {
					anChia.push({
						deliverId: item.khachAnChia.id,
						percent: Number(item.phanTramAnChia),
					})
				}
			}
			let data = {
				superId: this.superSelected.superId,
				deliverId: this.dauGiaoSelected.id,
				sharers: anChia,
				formulas: formulas,
			}
			let _this = this;
			let api = this.isEdit ? 'updateMapSuperDeliverFormula' : 'insertMapSuperDeliverFormula';
			Map[api](this.$root.url, data)
				.then(() => {
					if (!this.isThemKetNoiKhac) {
						_this.$bvModal.hide('modal-ket-noi-super');
					}
					if (_this.isEdit) {
						_this.showToast("Chỉnh Sửa thành công!", "Chỉnh Sửa Lưu Giá", _this, "success");
					} else {
						_this.showToast("Thêm thành công!", "Thêm Lưu Giá", _this, "success");
					}
					_this.getSuperStatus(true);
					_this.getSuperStatus(false);
				}).catch((error) => {
					_this.errorApi(error, _this);
				});
		},
		getMapSuperDeliverFormula(data) {
			this.tongGia = 0;
			this.isAnChia = false;
			this.phanTramConLai = 0;
			this.tongPhanTramDaChia = 0;
			this.dauGiaoSelected = {};
			this.dataAnChia = [
				{
					khachAnChia: {},
					phanTramAnChia: '',
					giaAnChia: '',
				}
			]
			this.formulasSelected = [];
			let _this = this;
			Map.getMapSuperDeliverFormula(this.$root.url, { superId: data.superId, deliverId: data.deliverId })
				.then((response) => {
					_this.superSelected = {
						domainId: response.data.superDto.domainId,
						domainName: response.data.superDto.domainName,
						level: response.data.superDto.level,
						loginId: response.data.superDto.loginId,
						receiverId: response.data.superDto.receiverId,
						receiverName: response.data.superDto.receiverName,
						superId: response.data.superDto.id,
						superName: response.data.superDto.userName,
						comboId: response.data.superDto.comboId,
						receiverDetail: response.data.superDto.receiverDetail,
					};
					if (response.data.sharers && response.data.sharers.length > 0) {
						_this.dataAnChia = [];
						for (const item of response.data.sharers) {
							_this.dataAnChia.push({
								khachAnChia: item.deliver,
								phanTramAnChia: item.percent,
								giaAnChia: 0,
							})
						}
					}
					_this.dauGiaoSelected = response.data.deliver;
					_this.comboId = response.data.formulas[0].comboId;
					_this.formulasSelected = response.data.formulas;
					_this.changeSuper();
				}).catch((error) => {
					_this.errorApi(error, _this);
				});
		},
		xoaSuperDaKetNoi(data) {
			let _this = this;
			Map.deleteMapSuperDeliverFormula(this.$root.url, { superId: data.superId, deliverId: data.deliverId })
				.then(() => {
					_this.showToast("Xóa thành công!", "Xóa Lưu Giá", _this, "success");
					_this.getSuperStatus(true);
					_this.getSuperStatus(false);
				}).catch((error) => {
					_this.errorApi(error, _this);
				});
		},
		huyXoaSuperDaKetNoi() {
			this.$bvModal.hide('modal-xac-nhan-xoa');
		},
		submitKetNoiSuper() {
			this.mapSuperDeliverFormula();
		},
		openKetNoiSuper(data, isEdit = false) {
			this.isEdit = isEdit;
			this.superSelected = {};
			this.tongGia = 0;
			this.isAnChia = false;
			this.phanTramConLai = 0;
			this.tongPhanTramDaChia = 0;
			this.dauGiaoSelected = {};
			this.dataAnChia = [
				{
					khachAnChia: {},
					phanTramAnChia: '',
					giaAnChia: '',
				}
			]
			this.formulasSelected = [];
			this.comboId = '';
			this.comboSelected = {};
			this.listFormulaCategory = [];
			if (this.isEdit) {
				this.getMapSuperDeliverFormula(data);
			} else if (data) {
				this.superSelected = data;
				this.changeSuper(true);
			} else {
				this.superSelected = this.dataSuperChuaKetNoi[0];
				this.changeSuper(true);
			}
			this.$bvModal.show('modal-ket-noi-super');
		},
		changeShowPercen() {
			if (this.showPercen === '0') {
				this.fieldsDSKetNoi = [
					{ key: 'action', label: '' },
					{ key: 'receiverName', label: 'Đầu Nhận' },
					{ key: 'domainName', label: 'Nhà Cái' },
					{ key: 'superName', label: 'Tên Tài Khoản' },
					{ key: 'numberThauNhan', label: 'Hệ Số Thầu Nhận' },
					{ key: 'numberThauGiao', label: 'Hệ Số Thầu Giao' },
					{ key: 'bids', label: 'Giá Thầu Nhận' },
				]
			} else {
				this.fieldsDSKetNoi = [
					{ key: 'action', label: '' },
					{ key: 'receiverName', label: 'Đầu Nhận' },
					{ key: 'domainName', label: 'Nhà Cái' },
					{ key: 'superName', label: 'Tên Tài Khoản' },
					{ key: 'numberThauNhan', label: 'Hệ Số Thầu Nhận' },
					{ key: 'numberThauGiao', label: 'Hệ Số Thầu Giao' },
					{ key: 'bids', label: 'Giá Thầu Nhận' },
					{ key: 'percen', label: 'Phần Trăm' },
				]
			}
		},
		changeTab(tab) {
			this.showTab = tab;
			switch (tab) {
				case '1':
					this.getSuperStatus(true);
					this.getSuperStatus(false);
					break;
				case '2':
					break;
			}
		},
		changeSuper(isReset = false) {
			if (isReset) {
				this.isAnChia = false;
				this.dataAnChia = [
					{
						khachAnChia: {},
						phanTramAnChia: '',
						giaAnChia: '',
					}
				];
				this.comboId = '';
				this.comboObj = {};
				this.comboSelected = {};
				this.listFormulaCategory = [];
				this.getCombo();
			} else {
				this.getCombo(this.isEdit);
			}
		},
		changeCombo(isEdit = false) {
			this.comboId = this.comboObj.comboId;
			this.comboSelected = this.comboObj;
			this.listFormulaCategory = [];
			if (isEdit) {
				for (const item of this.formulasSelected) {
					this.listFormulaCategory.push({
						formulaCategory: this.dataFormulaCategory.filter(i => i.formulaCategoryId === item.categoryId)[0],
						formulaCategoryId: item.categoryId,
						currency: item.currency,
						coefficients: item.coefficients,
						bids: item.bids,
						isGiaThauPercen: item.isPercent,
					})
				}
			} else {
				for (const item of this.comboSelected.listFormulaCategory) {
					this.listFormulaCategory.push({
						formulaCategory: this.dataFormulaCategory.filter(i => i.formulaCategoryId === item.formulaCategoryId)[0],
						formulaCategoryId: item.formulaCategoryId,
						currency: 'VND',
						coefficients: 1,
						bids: '',
						isGiaThauPercen: '',
					});
				}
			}
			if (this.dataAnChia[0].khachAnChia.id) {
				this.isAnChia = true;
				this.changeIsAnChia();
				for (const item of this.dataAnChia) {
					this.tinhPhanTramVaGia(1, item);
				}
			}
		},
		setRowSelected(item) {
			this.rowSelected = this.cloneDeep(item);
			this.$bvModal.show('modal-xac-nhan-xoa');
		},
		showSetting(item) {
			for (const i of this.dataSuperDaKetNoi) {
				if (item.index === i.index) {
					item.isShowSetting = !item.isShowSetting;
				} else {
					i.isShowSetting = false;
				}
			}
			if (item.isShowSetting) {
				this.rowSelected = item;
			} else {
				this.rowSelected = {};
			}
		},
		onRowClicked() {
			for (const i of this.dataSuperDaKetNoi) {
				i.isShowSetting = false;
			}
		},
		openThemDauGiao() {
			this.objThemDauGiao.deliverName = '';
			this.objThemDauGiao.code = '';
			this.objThemDauGiao.phoneNumber = '';
			this.objThemDauGiao.description = '';
			this.$bvModal.show('modal-them-dau-giao')
		},
		submitThemDauGiao() {
			let _this = this;
			let param = {
				code: this.objThemDauGiao.code,
				deliverName: this.objThemDauGiao.deliverName,
				description: this.objThemDauGiao.description,
				level: 0,
				parentId: '',
				phoneNumber: this.objThemDauGiao.phoneNumber,
			};
			Deliver.post(this.$root.url, param)
				.then(() => {
					_this.getDeliver();
					_this.$bvModal.hide("modal-them-dau-giao");
					_this.showToast("Thêm thành công!", "Thêm Đầu Giao", _this, "success");
				})
				.catch((error) => {
					_this.errorApi(error, _this);
				});
		},
		changeSort(key) {
			if (this.sortBy !== key) {
				this.sortBy = key;
				this.sortDesc = false;
				this.dataSuperDSKetNoi = this.cloneDeep(this.dataSuperDSKetNoiOrigin);
			} else {
				this.sortDesc = !this.sortDesc;
			}
			this.dataSuperDSKetNoi.sort((a, b) => {
				let dk = '';
				if (this.sortDesc) {
					dk = b[key] - a[key];
				} else {
					dk = a[key] - b[key];
				}
				return dk;
			});
		},
		getIconSort(key, type) {
			if (this.sortBy === key) {
				if (this.sortDesc) {
					return type === 'up' ? 'triangle' : 'triangle-fill';
				} else {
					return type === 'up' ? 'triangle-fill' : 'triangle';
				}
			}
			return 'triangle';
		},
		changeIsAnChia() {
			if (this.listFormulaCategory.length > 0) {
				let tongBids1 = 0;
				let trungBinhGiaGiao = 0;
				let tyGia = localStorage.getItem('rateUsd');
				for (const item of this.listFormulaCategory) {
					if (item.bids === '') {
						this.showToast("Chưa nhập giá thầu", "Thông báo", this, "warning");
						this.isAnChia = false;
						return;
					}
					let bids = item.isGiaThauPercen ? ((item.bids / 100) * tyGia) : item.bids;
					tongBids1 += Number(bids);
				}
				trungBinhGiaGiao = tongBids1 / this.listFormulaCategory.length;

				let listFormulas = this.superSelected.receiverDetail.formulas;
				let tongBids2 = 0;
				let trungBinhGiaNhan = 0;
				let combo = listFormulas.filter(i => i.comboId === this.superSelected.comboId);
				for (const item of combo) {
					let bids = item.isPercent ? ((item.bids / 100) * tyGia) : item.bids;
					tongBids2 += Number(bids);
				}
				trungBinhGiaNhan = tongBids2 / combo.length;

				this.tongGia = (trungBinhGiaGiao - trungBinhGiaNhan).toFixed(2);
				this.tinhConLai();
			}
		},
		changeKhachAnChia() {
			let dauGiaoDcChon = this.dataAnChia.filter(i => typeof i.khachAnChia.id !== 'undefined');
			this.tongPhanTramDaChia = 0
			for (const item of dauGiaoDcChon) {
				item.phanTramAnChia = item.phanTramAnChia ? item.phanTramAnChia : 0;
				item.giaAnChia = item.giaAnChia ? item.giaAnChia : 0;
				this.tongPhanTramDaChia += Number(item.phanTramAnChia);
			}
			this.phanTramConLai = (100 - this.tongPhanTramDaChia).toFixed(2);
			this.tongPhanTramDaChia = (this.tongPhanTramDaChia).toFixed(2);
		},
		tinhPhanTramVaGia(mode, item) {
			switch (mode) {
				case 1:
					item.giaAnChia = ((this.tongGia * item.phanTramAnChia) / 100).toFixed(2);
					item.phanTramAnChia = Number(item.phanTramAnChia).toFixed(2);
					break;
				case 2:
					item.phanTramAnChia = ((item.giaAnChia / this.tongGia) * 100).toFixed(2);
					item.giaAnChia = Number(item.giaAnChia).toFixed(2);
					break;
			}
			let tongPhanTram = 0;
			let tongGia = 0;
			for (const i of this.dataAnChia) {
				tongPhanTram += Number(i.phanTramAnChia);
				tongGia += Number(i.giaAnChia);
			}
			if (tongPhanTram > 100 || tongGia > this.tongGia) {
				item.phanTramAnChia = 0;
				item.giaAnChia = 0;
				this.showToast('Giá của bạn nhập cao hơn giá giao, không thể thực hiện !', 'Lỗi nhập số', this, "danger");
			}
			this.tinhConLai();
		},
		tinhConLai() {
			let dauGiaoDcChon = this.dataAnChia.filter(i => typeof i.khachAnChia.id !== 'undefined');
			this.tongPhanTramDaChia = 0
			for (const item of dauGiaoDcChon) {
				this.tongPhanTramDaChia += Number(item.phanTramAnChia);
			}
			this.phanTramConLai = (100 - this.tongPhanTramDaChia).toFixed(2);
			this.tongPhanTramDaChia = (this.tongPhanTramDaChia).toFixed(2);
		},
		themKhachAnChia() {
			this.dataAnChia.push(
				{
					khachAnChia: {},
					phanTramAnChia: '',
					giaAnChia: '',
				}
			)
		},
		xoaKhachAnChia(index) {
			this.dataAnChia.splice(index, 1);
		},
		changeGiaThau(item) {
			setTimeout(() => {
				if (item.bids <= 0) {
					item.bids = 1
				}
			}, 100);
			if (this.isAnChia) {
				this.changeIsAnChia();
				for (const i of this.dataAnChia) {
					this.tinhPhanTramVaGia(1, i);
				}
			}
		},
		onFilteredDaKetNoi(filteredItems) {
			this.totalRowsSuperDaKetNoi = filteredItems.length;
			this.currentPageSuperDaKetNoi = 1;
		},
		onFilteredChuaKetNoi(filteredItems) {
			this.totalRowsSuperChuaKetNoi = filteredItems.length;
			this.currentPageSuperChuaKetNoi = 1;
		},
		changeBids(event, item) {
			let text = event.clipboardData.getData('text');
			if (text.includes('%')) {
				item.isGiaThauPercen = true;
			}
		},
		onClickHuy(key) {
			this.$bvModal.hide(key);
			switch (key) {
				case 'modal-them-super':
					break;
				case 'modal-ket-noi-super':
					break;
				case 'modal-chia-gia':
					if (!this.isEdit) {
						this.isAnChia = false;
						this.dataAnChia = [
							{
								khachAnChia: {},
								phanTramAnChia: '',
								giaAnChia: '',
							}
						];
					}
					break;
			}
		},
		onClickLuuChiaGia() {
			if (this.isAnChia) {
				this.$bvModal.hide('modal-chia-gia');
			}
		},
		changeDauKhach() {
			this.isAnChia = false;
			this.dataAnChia = [];
		}
	},
};
</script>

<style lang="scss">
.style-for-mobile .cau-hinh-page {
	.tab-ket-noi {
		background: transparent;
		color: var(--text-color);
		padding: 10px;
		border-radius: 8px;
	}

	.table-cau-hinh-tai-khoan {
		background: var(--common-background);
		border-radius: 8px;
		box-shadow: 0px 2px 6px var(--active-tab-box-shadow);
	}

	.table-cau-hinh-ds-ket-noi {
		background: var(--common-background);
		border-radius: 8px;
		box-shadow: 0px 2px 6px var(--active-tab-box-shadow);

		.text-header-gia {
			text-align: center;
			margin-bottom: 5px;
			border-bottom: 1px solid var(--modal-input-border);
		}

		.header-gia {
			display: flex;

			>div {
				width: calc(100% / 3);
				display: flex;
				justify-content: space-between;
				align-items: flex-end;
				cursor: pointer;
			}
		}

		.cot-thanh-tien {
			display: flex;

			>div {
				width: calc(100% / 3);
			}
		}

		.group-icon-sort {
			position: relative;
			height: 20px;
			width: 20px;
			margin-bottom: 3px;
			text-align: center;

			.icon-sort {
				font-size: 9px;
				position: absolute;
				top: 0;
				right: 0;
			}

			.icon-sort-down {
				font-size: 9px;
				transform: rotate(180deg);
				position: absolute;
				bottom: 0;
				right: 0;
			}
		}

		.b-table {
			min-width: 300vw;
		}
	}

	.b-table-sticky-header {
		max-height: 50vh !important;
	}
}

#modal-ket-noi-super .style-for-mobile {
	.items-nha-cai {
		background-color: var(--items-nha-cai-back-color);
		border: 1px solid var(--modal-input-border);
		border-top: none;
		border-radius: 8px;
	}

	.input-group>.input-group-append>.input-group-text {
		height: calc(1.5em + 0.9rem + 4px);
	}

	.modal-body {
		min-height: 50vh;
	}

	.gia-thau {
		display: flex;
	}

	.group-label-dau-giao {
		display: flex;

		.btn-them-dau-giao {
			width: 20px;
			filter: var(--img-filter-icon-menu) !important;
			cursor: pointer;

			&:hover {
				filter: var(--img-filter-blue) !important;
			}
		}
	}
}

#modal-chia-gia .style-for-mobile {
	.group-label-an-chia {
		display: flex;
	}

	.group-an-chia {
		>div {
			display: flex;
			margin-bottom: 3px;
		}

		button {
			height: 38px;
			padding: 0 0.75rem;

			img {
				filter: var(--img-filter) !important;
			}
		}
	}

	.modal-body {
		min-height: 50vh;
	}
}
</style>
