<template>
	<div id="lich-su-xuat-hoa-don" class="lich-su-xuat-hoa-don">
		<b-row class="header-page">
			<b-col cols="4">
				<span class="title-page">
					LỊCH SỬ HÓA ĐƠN
				</span>
			</b-col>
		</b-row>
		<b-row class="group-thoi-gian-tinh-tien">
			<b-col cols="12">
				<div class="thoi-gian-tinh-tien">
					<div class="text-left"><b>Ngày tính tiền</b></div>
					<b-input-group>
						<multi-select v-model="historyReportObj" :options="dataThoiGianTinhTien" :allow-empty="false"
							label="executeDateDisplay" track-by="historyReportId" :preselect-first="true" class="multi-select" placeholder=""
							@input="changeThoiGianTinhTien()">
						</multi-select>
					</b-input-group>
				</div>
				<div class="text-right button-action ml-2">
					<b-button variant="primary" @click="xuatBaoCao('PDF')" title="Xuất ảnh chất lượng cao">
						<b-icon icon="file-earmark-pdf" aria-hidden="true" class="mr-2"></b-icon>
						Hình Nét
					</b-button>
				</div>
			</b-col>

		</b-row>

		<!-- Danh sách thắng thua chi tiết -->
		<b-row class="mt-2 ket-qua-kiem-tra">
			<b-col cols="12" class="mb-2">
				<div class="text-left"><b>Thời gian tính tiền:</b> {{ thoiGianTinhTien }}</div>
			</b-col>
			<b-col cols="12">
				<div v-for="(item, index) in dataWinLoss" :key="index" class="group-dau-khach">
					<div class="title-dau-khach">
						<span>Đầu khách: {{ item.deliverName }}</span>
					</div>
					<div v-for="(item1, index1) in item.winLossDetails" :key="index1">
						<div class="title-nha-cai">
							<span>Nhà cái: {{ item1.domainName }}</span>
							<span>
								<img class="logo-nha-cai" :src="getLogoNhaCai(item1.domainName)" />
							</span>
						</div>
						<div class="body-nha-cai">
							<b-table hover :head-variant="$root.theme" small show-empty emptyText="Không có dữ liệu"
								empty-filtered-text="Không có dữ liệu phù hợp tìm kiếm" :tbody-tr-class="rowClass"
								:fields="fieldsChiTiet" :items="item1.dataNhaCai">
								<!-- head -->
								<template #head(domainName)="scope">
									<div class="head-table-custom" @click="changeSort('domainName', data.item)">
										<span>{{ scope.label }}</span>
										<div class="group-icon-sort">
											<b-icon class="icon-sort" :icon="getIconSort('domainName', 'up', 'con')">
											</b-icon>
											<b-icon class="icon-sort-down" :icon="getIconSort('domainName', 'down', 'con')">
											</b-icon>
										</div>
									</div>
								</template>
								<template #head(userName)="scope">
									<div class="head-table-custom" @click="changeSort('userName', data.item)">
										<span>{{ scope.label }}</span>
										<div class="group-icon-sort">
											<b-icon class="icon-sort" :icon="getIconSort('userName', 'up', 'con')">
											</b-icon>
											<b-icon class="icon-sort-down" :icon="getIconSort('userName', 'down', 'con')">
											</b-icon>
										</div>
									</div>
								</template>
								<template #head(type)="scope">
									<div class="head-table-custom" @click="changeSort('type', data.item)">
										<span>{{ scope.label }}</span>
										<div class="group-icon-sort">
											<b-icon class="icon-sort" :icon="getIconSort('type', 'up', 'con')">
											</b-icon>
											<b-icon class="icon-sort-down" :icon="getIconSort('type', 'down', 'con')">
											</b-icon>
										</div>
									</div>
								</template>
								<template #head(totalWinLoss)="scope">
									<div class="head-table-custom" @click="changeSort('totalWinLoss', data.item)">
										<span>{{ scope.label }}</span>
										<div class="group-icon-sort">
											<b-icon class="icon-sort" :icon="getIconSort('totalWinLoss', 'up', 'con')">
											</b-icon>
											<b-icon class="icon-sort-down"
												:icon="getIconSort('totalWinLoss', 'down', 'con')">
											</b-icon>
										</div>
									</div>
								</template>
								<template #head(totalCom)="scope">
									<div class="head-table-custom" @click="changeSort('totalCom', data.item)">
										<span>{{ scope.label }}</span>
										<div class="group-icon-sort">
											<b-icon class="icon-sort" :icon="getIconSort('totalCom', 'up', 'con')">
											</b-icon>
											<b-icon class="icon-sort-down" :icon="getIconSort('totalCom', 'down', 'con')">
											</b-icon>
										</div>
									</div>
								</template>
								<template #head(deliverTotalUsd)="scope">
									<div class="group-header-loai-tien">{{ scope.label }}</div>
									<div class="header-loai-tien">
										<div @click="changeSort('deliverTotalUsd', data.item)">
											<span class="color-usd">USD</span>
											<div class="group-icon-sort">
												<b-icon class="icon-sort"
													:icon="getIconSort('deliverTotalUsd', 'up', 'con')">
												</b-icon>
												<b-icon class="icon-sort-down"
													:icon="getIconSort('deliverTotalUsd', 'down', 'con')">
												</b-icon>
											</div>
										</div>
										<div @click="changeSort('deliverTotalVnd', data.item)">
											<span class="color-vnd">VNĐ</span>
											<div class="group-icon-sort">
												<b-icon class="icon-sort"
													:icon="getIconSort('deliverTotalVnd', 'up', 'con')">
												</b-icon>
												<b-icon class="icon-sort-down"
													:icon="getIconSort('deliverTotalVnd', 'down', 'con')">
												</b-icon>
											</div>
										</div>
									</div>
								</template>
								<template #head(receiverTotalUsd)="scope">
									<div class="group-header-loai-tien">{{ scope.label }}</div>
									<div class="header-loai-tien">
										<div @click="changeSort('receiverTotalUsd', data.item)">
											<span class="color-usd">USD</span>
											<div class="group-icon-sort">
												<b-icon class="icon-sort"
													:icon="getIconSort('receiverTotalUsd', 'up', 'con')">
												</b-icon>
												<b-icon class="icon-sort-down"
													:icon="getIconSort('receiverTotalUsd', 'down', 'con')">
												</b-icon>
											</div>
										</div>
										<div @click="changeSort('receiverTotalVnd', data.item)">
											<span class="color-vnd">VNĐ</span>
											<div class="group-icon-sort">
												<b-icon class="icon-sort"
													:icon="getIconSort('receiverTotalVnd', 'up', 'con')">
												</b-icon>
												<b-icon class="icon-sort-down"
													:icon="getIconSort('receiverTotalVnd', 'down', 'con')">
												</b-icon>
											</div>
										</div>
									</div>
								</template>
								<template #head(companyTotalUsd)="scope">
									<div class="group-header-loai-tien">{{ scope.label }}</div>
									<div class="header-loai-tien">
										<div @click="changeSort('companyTotalUsd', data.item)">
											<span class="color-usd">USD</span>
											<div class="group-icon-sort">
												<b-icon class="icon-sort"
													:icon="getIconSort('companyTotalUsd', 'up', 'con')">
												</b-icon>
												<b-icon class="icon-sort-down"
													:icon="getIconSort('companyTotalUsd', 'down', 'con')">
												</b-icon>
											</div>
										</div>
										<div @click="changeSort('companyTotalVnd', data.item)">
											<span class="color-vnd">VNĐ</span>
											<div class="group-icon-sort">
												<b-icon class="icon-sort"
													:icon="getIconSort('companyTotalVnd', 'up', 'con')">
												</b-icon>
												<b-icon class="icon-sort-down"
													:icon="getIconSort('companyTotalVnd', 'down', 'con')">
												</b-icon>
											</div>
										</div>
									</div>
								</template>
								<!-- body -->
								<template #cell(userName)="data">
									<div v-if="data.item.status === 'ERROR'">
										<span class="error-text">{{ data.item.userName }} (Lỗi)</span>
									</div>
									<div v-else-if="data.item.level !== 4" :class="getClassLevel(data.item)">
										<span>
											{{ data.item.userName }}
										</span>
									</div>
									<div v-else :class="getClassLevel(data.item)">
										<span>
											{{ data.item.userName }}
										</span>
									</div>
								</template>
								<template #cell(type)="data">
									<div v-if="data.item.winLoss">
										<div v-for="(itemWinLoss, index) in data.item.winLoss" :key="index">
											<span>
												{{ getFormatNumber(itemWinLoss.type) }}
											</span>
											<hr v-if="index < data.item.winLoss.length - 1">
										</div>
									</div>
									<div v-else>
										<span>
											{{ getFormatNumber(data.item.type) }}
										</span>
									</div>
								</template>
								<template #cell(totalWinLoss)="data">
									<div v-if="data.item.winLoss">
										<div v-for="(itemWinLoss, index) in data.item.winLoss" :key="index">
											<span :class="itemWinLoss.totalWinLoss < 0 ? 'so-am' : 'so-duong'">
												{{ getFormatNumber(itemWinLoss.totalWinLoss) }}
											</span>
											<hr v-if="index < data.item.winLoss.length - 1">
										</div>
									</div>
									<div v-else>
										<span :class="data.item.totalWinLoss < 0 ? 'so-am' : 'so-duong'">
											{{ getFormatNumber(data.item.totalWinLoss) }}
										</span>
									</div>
								</template>
								<template #cell(totalCom)="data">
									<div v-if="data.item.winLoss">
										<div v-for="(itemWinLoss, index) in data.item.winLoss" :key="index">
											<span>
												{{ getFormatNumber(itemWinLoss.totalCom) }}
											</span>
											<hr v-if="index < data.item.winLoss.length - 1">
										</div>
									</div>
									<div v-else>
										<span>
											{{ getFormatNumber(data.item.totalCom) }}
										</span>
									</div>
								</template>
								<template #cell(deliverTotalUsd)="data">
									<div v-if="data.item.winLoss">
										<div v-for="(itemWinLoss, index) in data.item.winLoss" :key="index">
											<div class="cot-thanh-tien">
												<div :class="itemWinLoss.deliverTotalUsd < 0 ? 'so-am' : 'so-duong'">
													{{ getFormatNumber(itemWinLoss.deliverTotalUsd) }}
												</div>
												<div :class="itemWinLoss.deliverTotalVnd < 0 ? 'so-am' : 'so-duong'">
													{{ getFormatNumber(itemWinLoss.deliverTotalVnd) }}
												</div>
											</div>
											<hr v-if="index < data.item.winLoss.length - 1">
										</div>
									</div>
									<div v-else>
										<div class="cot-thanh-tien">
											<div :class="data.item.deliverTotalUsd < 0 ? 'so-am' : 'so-duong'">
												{{ getFormatNumber(data.item.deliverTotalUsd) }}
											</div>
											<div :class="data.item.deliverTotalVnd < 0 ? 'so-am' : 'so-duong'">
												{{ getFormatNumber(data.item.deliverTotalVnd) }}
											</div>
										</div>
									</div>
								</template>
								<template #cell(receiverTotalUsd)="data">
									<div v-if="data.item.winLoss">
										<div v-for="(itemWinLoss, index) in data.item.winLoss" :key="index">
											<div class="cot-thanh-tien">
												<div :class="itemWinLoss.receiverTotalUsd < 0 ? 'so-am' : 'so-duong'">
													{{ getFormatNumber(itemWinLoss.receiverTotalUsd) }}
												</div>
												<div :class="itemWinLoss.receiverTotalVnd < 0 ? 'so-am' : 'so-duong'">
													{{ getFormatNumber(itemWinLoss.receiverTotalVnd) }}
												</div>
											</div>
											<hr v-if="index < data.item.winLoss.length - 1">
										</div>
									</div>
									<div v-else>
										<div class="cot-thanh-tien">
											<div :class="data.item.receiverTotalUsd < 0 ? 'so-am' : 'so-duong'">
												{{ getFormatNumber(data.item.receiverTotalUsd) }}
											</div>
											<div :class="data.item.receiverTotalVnd < 0 ? 'so-am' : 'so-duong'">
												{{ getFormatNumber(data.item.receiverTotalVnd) }}
											</div>
										</div>
									</div>
								</template>
								<template #cell(companyTotalUsd)="data">
									<div v-if="data.item.winLoss">
										<div v-for="(itemWinLoss, index) in data.item.winLoss" :key="index">
											<div v-if="itemWinLoss.percentDeliverSuper < 100" class="cot-thanh-tien">
												<div :class="itemWinLoss.sharingUsd < 0 ? 'so-am' : 'so-duong'">
													{{ getFormatNumber(itemWinLoss.sharingUsd) }}
													{{ itemWinLoss.sharingUsd !== 0 ?
														`(${itemWinLoss.percentDeliverSuper}%)` : '' }}
												</div>
												<div :class="itemWinLoss.sharingVnd < 0 ? 'so-am' : 'so-duong'">
													{{ getFormatNumber(itemWinLoss.sharingVnd) }}
													{{ itemWinLoss.sharingVnd !== 0 ?
														`(${itemWinLoss.percentDeliverSuper}%)` : '' }}
												</div>
											</div>
											<div v-else class="cot-thanh-tien">
												<div :class="itemWinLoss.companyTotalUsd < 0 ? 'so-am' : 'so-duong'">
													{{ getFormatNumber(itemWinLoss.companyTotalUsd) }}
												</div>
												<div :class="itemWinLoss.companyTotalVnd < 0 ? 'so-am' : 'so-duong'">
													{{ getFormatNumber(itemWinLoss.companyTotalVnd) }}
												</div>
											</div>
											<hr v-if="index < data.item.winLoss.length - 1">
										</div>
									</div>
									<div v-else>
										<div v-if="data.item.percentDeliverSuper < 100" class="cot-thanh-tien">
											<div :class="data.item.sharingUsd < 0 ? 'so-am' : 'so-duong'">
												{{ getFormatNumber(data.item.sharingUsd) }}
												{{ data.item.sharingUsd !== 0 ? `(${data.item.percentDeliverSuper}%)` :
													'' }}
											</div>
											<div :class="data.item.sharingVnd < 0 ? 'so-am' : 'so-duong'">
												{{ getFormatNumber(data.item.sharingVnd) }}
												{{ data.item.sharingVnd !== 0 ? `(${data.item.percentDeliverSuper}%)` :
													'' }}
											</div>
										</div>
										<div v-else class="cot-thanh-tien">
											<div :class="data.item.companyTotalUsd < 0 ? 'so-am' : 'so-duong'">
												{{ getFormatNumber(data.item.companyTotalUsd) }}
											</div>
											<div :class="data.item.companyTotalVnd < 0 ? 'so-am' : 'so-duong'">
												{{ getFormatNumber(data.item.companyTotalVnd) }}
											</div>
										</div>
									</div>
								</template>
							</b-table>
						</div>
					</div>
				</div>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Accounting from "../../../service/accounting.service";

export default {
	name: "LichSuXuatHoaDon",
	data() {
		return {
			historyReportId: '',
			historyReportObj: {},
			thoiGianTinhTien: '',
			ngayTinhTien: '',
			dataThoiGianTinhTien: [],
			fieldsChiTiet: [
				{ key: 'userName', label: 'Tài Khoản' },
				{ key: 'type', label: 'Loại Chơi' },
				{ key: 'totalWinLoss', label: 'Thắng Thua\nWinLoss' },
				{ key: 'totalCom', label: 'Hoa Hồng\nCom' },
				{ key: 'deliverTotalUsd', label: 'Tiền Thu Khách' },
				{ key: 'receiverTotalUsd', label: 'Tiền Bù Cái' },
				{ key: 'companyTotalUsd', label: 'Lợi Nhuận' },
			],
			dataWinLoss: [],
			dataWinLossOrigin: [],
			dataBaoCao: [],
			sortBy: '',
			sortDesc: true,
			sortConBy: '',
			sortConDesc: true,
		};
	},
	computed: {
		...mapState("LichSuXuatHoaDon", []),
	},
	beforeCreate() {
		console.log("I have not been created yet!");
	},
	created() {
		console.log("I have just been created!");
	},
	beforeMount() {
		console.log("I am about to be mounted to the DOM!");
	},
	mounted() {
		console.log("I am mounted to the DOM!");
		this.forceMovePage();
		this.getHistoryReport();
	},
	beforeDestroy() {
		console.log("I am about to be removed from the DOM!");
	},
	destroyed() {
		console.log("I no longer exist...");
	},
	methods: {
		...mapActions("LichSuXuatHoaDon", []),
		getHistoryReport() {
			let _this = this;
			Accounting.getHistoryReport(this.$root.url)
				.then((response) => {
					_this.dataThoiGianTinhTien = response.data;
				})
				.catch((error) => {
					_this.errorApi(error, _this);
				});
		},
		getHistoryReportDetail() {
			let data = {
				historyReportId: this.historyReportId,
			}
			let _this = this;
			Accounting.getHistoryReportDetail(this.$root.url, data)
				.then((response) => {
					_this.dataBaoCao = _this.cloneDeep(response.data.deliverWinLoss);
					_this.dataWinLoss = [];
					let deliverWinLoss = _this.cloneDeep(response.data.deliverWinLoss);
					for (const item of deliverWinLoss) {
						let dataGroup = _this.groupBy(item.winLossDetails, 'domainName');
						let winLossDetails = [];
						for (const key of Object.keys(dataGroup)) {
							winLossDetails.push({
								domainName: key,
								dataNhaCai: dataGroup[key],
							})
						}
						let itemDataWinLoss = {
							deliverName: item.deliverName,
							winLossDetails: winLossDetails,
						}
						_this.dataWinLoss.push(itemDataWinLoss);
					}
				})
				.catch((error) => {
					_this.errorApi(error, _this);
				});
		},
		changeThoiGianTinhTien() {
			this.historyReportId = this.historyReportObj.historyReportId;
			let thoiGianDaChon = this.dataThoiGianTinhTien.filter(i => i.historyReportId === this.historyReportId)[0];
			this.ngayTinhTien = thoiGianDaChon.executeDateDisplay;
			this.thoiGianTinhTien = thoiGianDaChon.historyVersion;
			this.getHistoryReportDetail();
		},
		xuatBaoCao(loaiFile) {
			let startDate = this.thoiGianTinhTien.split(' tới ')[0];
			let endDate = this.thoiGianTinhTien.split(' tới ')[1];
			let data = {
				deliverWinLoss: this.dataBaoCao,
				startDate: this.$moment(startDate, 'DD/MM/YYYY').format(this.$root.formatDate) + 'T00:00:00+07:00',
				endDate: this.$moment(endDate, 'DD/MM/YYYY').format(this.$root.formatDate) + 'T23:59:59+07:00',
			};
			console.log(data);
			let _this = this;
			Accounting.printInvoiceExcel(this.$root.url, data, loaiFile, false)
				.then((response) => {
					let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
					let fileName = '';
					let matches = filenameRegex.exec(response.headers["content-disposition"]);
					if (matches != null && matches[1]) {
						fileName = matches[1].replace(/['"]/g, '');
					}
					let type = '';
					if (loaiFile === 'EXCEL') {
						type = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,"
					} else {
						type = 'application/pdf';
					}
					let downloadLink = window.document.createElement('a');
					downloadLink.href = window.URL.createObjectURL(new Blob([response.data], { type: type }),);
					downloadLink.download = fileName;
					document.body.appendChild(downloadLink);
					downloadLink.click();
					document.body.removeChild(downloadLink);
				})
				.catch((error) => {
					_this.errorApi(error, _this);
				});
		},
		changeSort(key, data) {
			if (data) {
				if (!data.winLossDataOrigin) {
					data.winLossDataOrigin = this.cloneDeep(data.winLossData);
				}
				if (this.sortConBy !== key) {
					this.sortConBy = key;
					this.sortConDesc = false;
					data.winLossData = this.cloneDeep(data.winLossDataOrigin);
				} else {
					this.sortConDesc = !this.sortConDesc;
				}
				data.winLossData.sort((a, b) => {
					let dk = '';
					if (this.sortConDesc) {
						dk = b[key] - a[key];
					} else {
						dk = a[key] - b[key];
					}
					return dk;
				});
			} else {
				if (this.sortBy !== key) {
					this.sortBy = key;
					this.sortDesc = false;
					this.dataWinLoss = this.cloneDeep(this.dataWinLossOrigin);
				} else {
					this.sortDesc = !this.sortDesc;
				}
				this.dataWinLoss.sort((a, b) => {
					let dk = '';
					if (this.sortDesc) {
						dk = b[key] - a[key];
					} else {
						dk = a[key] - b[key];
					}
					return dk;
				});
			}
		},
		getIconSort(key, type, typeList) {
			if (typeList === 'con') {
				if (this.sortConBy === key) {
					if (this.sortConDesc) {
						return type === 'up' ? 'triangle' : 'triangle-fill';
					} else {
						return type === 'up' ? 'triangle-fill' : 'triangle';
					}
				}
				return 'triangle';
			} else {
				if (this.sortBy === key) {
					if (this.sortDesc) {
						return type === 'up' ? 'triangle' : 'triangle-fill';
					} else {
						return type === 'up' ? 'triangle-fill' : 'triangle';
					}
				}
				return 'triangle';
			}
		},
		getDataNhaCai(data) {
			if (!data.dataNhaCai) {
				data.dataNhaCai = [];
			}
			let item = data.winLossData;
			if (item) {
				let idx = data.dataNhaCai.findIndex(i => i.name === item.domainName);
				if (idx === -1) {
					let dataDomain = [];
					item.selected = false;
					item.indexCuaDong = this.cloneDeep(data.dataNhaCai.length);
					if (item) {
						for (const item2 of item.childMembers) {
							item2.selected = false;
						}
					}
					dataDomain.push(item);
					data.dataNhaCai.push({
						name: item.domainName,
						deliverId: data.deliverId,
						data: dataDomain,
					});
				}
			} else {
				data.dataNhaCai.push({
					name: data.domainName,
					deliverId: data.deliverId,
					data: [],
				});
			}
		},
		getLevel(level) {
			return level ? level - 1 : 0;
		},
		getClassLevel(item) {
			let classStr = '';
			let levelColor = item.level ? item.level : 1;
			let levelPadding = item.level ? item.level : 1;
			switch (levelPadding) {
				case 1:
				case 2:
				case 3:
					classStr = `color-level-${levelColor} padding-level-${levelPadding}`;
					break;
				case 4:
					classStr = `color-level-${levelColor} padding-level-${levelPadding}`;
					break;
			}
			return classStr;
		},
		rowClass(item, type) {
			if (!item || type !== 'row') {
				return;
			}

			let percentDeliverSuper = item.percentDeliverSuper;
			if (percentDeliverSuper && percentDeliverSuper < 100) {
				return 'is-chia-gia';
			}
		}
	},
};
</script>

<style lang="scss">
.style-for-mobile .lich-su-xuat-hoa-don {
	.group-thoi-gian-tinh-tien {
		>div {
			display: flex;
			background: var(--menu-bar-background);
			padding: 10px;
			border-radius: 8px;
			box-shadow: 0px 2px 6px var(--active-tab-box-shadow);
		}

		.thoi-gian-tinh-tien {
			width: calc(100% - 160px);
		}

		.button-action {
			align-items: end;
			width: 160px;
		}
	}

	table {
		tr {
			th {

				&:nth-child(1) {
					width: 150px;
				}

				&:nth-child(2),
				&:nth-child(3),
				&:nth-child(4) {
					width: 115px;
				}

				&:nth-child(5),
				&:nth-child(6),
				&:nth-child(7) {
					width: 230px;
				}
			}

			td {
				font-size: 13px;
				white-space: pre-wrap;
				word-break: break-all;

				hr {
					margin: 5px -5px;
					border-color: var(--table-hr-border);
				}
			}
		}
	}

	.ket-qua-kiem-tra {
		table {
			border-radius: 8px;
			box-shadow: 0px 2px 10px -5px var(--active-tab-box-shadow);
			font-size: 13px;

			tr {
				th {
					border-top: none;

					&:first-child {
						border-top-left-radius: 8px;
					}

					&:last-child {
						border-top-right-radius: 8px;
					}
				}
			}

			.is-chia-gia {
				// td {
				// 	background-color: var(--is-chia-gia-background);
				// }
			}
		}
	}

	.checkbox-icon {
		border-radius: 5px;
		height: 15px;
		width: 15px;
		display: flex;
		margin-top: 3px;

		img {
			filter: var(--img-filter-icon-menu) !important;
			height: 15px;
			width: 15px;
		}

		&.checked {
			background: var(--btn-link);

			img {
				filter: var(--img-filter) !important;
			}
		}
	}

	.group-dau-khach {
		background-color: var(--b-table-details-background);
		padding: 5px;
		margin-bottom: 10px;
		border-radius: 8px;
	}

	.title-dau-khach {
		display: inline-block;
		margin-bottom: 5px;
		padding: 5px 10px;
		border-radius: 8px;
		background-color: var(--btn-link-3);
		color: var(--text-color);
		font-weight: bold;

		span {
			vertical-align: middle;
		}
	}

	.title-nha-cai {
		display: inline-block;
		margin-bottom: 5px;
		padding: 5px 10px;
		border-radius: 8px;
		background-color: var(--ten-nha-cai-back-color);
		color: var(--text-color);
		font-weight: bold;
		font-size: 14px;

		span {
			vertical-align: middle;
		}
	}

	.body-nha-cai {
		overflow: auto;
		margin-bottom: 15px;

		table {
			min-width: 300vw;
			margin: 0;
		}
	}

	.label-dau-giao {
		color: var(--btn-primary-color);
	}

	.ten-dau-giao {
		color: var(--ten-dau-giao-color);
		background-color: var(--ten-dau-giao-back-color);
		border-radius: 8px;
		padding: 2px 5px;
		margin-right: 15px;
		font-weight: bold;
	}

	.head-table-custom {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		cursor: pointer;
	}

	.group-header-loai-tien {
		margin-bottom: 5px;
		border-bottom: 1px solid var(--modal-input-border);
	}

	.header-loai-tien {
		display: flex;

		>div {
			width: 50%;
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
			cursor: pointer;

			&:last-child {
				margin-left: 3px;
			}
		}
	}

	.cot-thanh-tien {
		display: flex;

		>div {
			width: 50%;
		}
	}

	.group-icon-sort {
		position: relative;
		height: 15px;
		width: 15px;
		margin-bottom: 3px;
		text-align: center;

		.icon-sort {
			font-size: 7px;
			position: absolute;
			top: 0;
			right: 0;
		}

		.icon-sort-down {
			font-size: 7px;
			transform: rotate(180deg);
			position: absolute;
			bottom: 0;
			right: 0;
		}
	}

	.ket-qua-kiem-tra {
		background: var(--menu-bar-background);
		padding: 10px 5px;
		border-radius: 8px;
		box-shadow: 0px 2px 6px var(--active-tab-box-shadow);

		.row {
			margin: 0;
		}

		.header-ket-qua {
			background: var(--header-ket-qua-background);
			border-radius: 8px;
			font-weight: bold;
		}

		.body-ket-qua {
			background: var(--header-ket-qua-background);
			border-radius: 8px;
			align-items: center;
		}
	}

	.error-text,
	.so-am {
		color: var(--text-so-am-color);
		white-space: nowrap;
		font-size: 13px;
	}

	.so-duong {
		color: var(--text-so-duong-color);
		white-space: nowrap;
		font-size: 13px;
	}

	.text-binh-thuong {
		font-size: 13px;
	}
}
</style>
