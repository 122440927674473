<template>
    <div id="app" class="app" :class="$root.isMobile ? 'style-for-mobile' : 'style-for-web'">
        <div>
            <div v-if="isLogin" class="background-app-top"></div>
            <!-- menu cho PC -->
            <div v-if="isLogin && !$root.isMobile" id="menu-bar" class="menu-bar" :class="{ 'mini-menu': isMiniMenu }">
                <div>
                    <div class="f1-invoice">
                        <img :src="getImg('logo', 'jpg')" />
                    </div>
                    <div class="menu-body">
                        <div v-for="(item, index) in menuItem" :key="'item-menu-' + index" class="item-menu">
                            <div :id="'item-menu-' + index">
                                <div v-if="!isMiniMenu" class="parent-item"
                                    :class="{ 'hoover-item': !item.subMenu, 'is-active': item.isActive, }"
                                    @click="moveToPage(item)">
                                    <img :src="getIcon(item.icon)" class="icon-menu" :title="isMiniMenu ? item.name : ''" />
                                    <span class="ml-1">{{ item.name }}</span>
                                </div>
                                <div v-for="(subItem, subIndex) in item.subMenu" :key="'item-sub-menu-' + subIndex"
                                    class="item-sub-menu" :class="{ 'is-active': subItem.isActive }"
                                    @click="moveToPage(subItem)">
                                    <img :src="getIcon(subItem.icon)" class="icon-menu"
                                        :title="isMiniMenu ? subItem.name : ''" />
                                    <span v-if="!isMiniMenu" class="ml-1">{{ subItem.name }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="user-info" :class="isMiniMenu ? 'user-info-mini' : ''">
                        <b-button-group>
                            <b-button v-if="!isMiniMenu" block size="sm" id="user-info-btn" variant="primary">
                                <div>
                                    <img :src="getIcon('profile-circle')" class="icon-menu" />
                                </div>
                                <div>
                                    <span>XIN CHÀO</span>
                                    <br>
                                    <span class="small-text">
                                        {{ user.firstName + ' ' + user.lastName }}
                                    </span>
                                </div>
                            </b-button>
                            <b-button block size="sm" id="setting-user-info-btn" variant="info">
                                <img v-if="!isMiniMenu" :src="getIcon('more-circle')" class="icon-menu" />
                                <img v-else :src="getIcon('profile-circle')" class="icon-menu" />
                            </b-button>
                        </b-button-group>
                        <b-popover target="setting-user-info-btn" triggers="focus" ref="popover-user-info"
                            id="popover-user-info" variant="light" custom-class="style-for-web">
                            <div class="content-tooltip">
                                <b-form-checkbox v-model="isDarkMode" name="check-button" switch class="mb-2 "
                                    @change="setThemePreference(isDarkMode ? 'dark' : 'light')">
                                    Chế độ ban đêm
                                </b-form-checkbox>
                                <b-form-checkbox v-model="showHuongDan" name="check-button" switch class="mb-2 "
                                    @change="onClickBtnInfo(1)">
                                    Hướng dẫn sử dụng
                                </b-form-checkbox>
                                <b-button variant="light" size="sm" class="mb-2 user-info-btn text-left"
                                    @click="showModal('modal-thong-tin-user')">
                                    Thông tin tài khoản
                                </b-button>
                                <b-button variant="light" size="sm" class="mb-2 user-info-btn text-left"
                                    @click="showModal('modal-doi-mat-khau')">
                                    Đổi mật khẩu
                                </b-button>
                                <b-button variant="light" size="sm" class="mb-2 user-info-btn text-left"
                                    @click="showModal('modal-chinh-sua-ty-gia')">
                                    Chỉnh sửa tỷ giá USD
                                </b-button>
                                <b-button variant="light" size="sm" class="mb-2 user-info-btn text-left"
                                    @click="onClickBtnInfo(4)">
                                    Đăng xuất
                                </b-button>
                            </div>
                        </b-popover>
                    </div>
                    <div class="btn-toggle-mini-menu" @click="changeModeMenu()">
                        <img :src="getIcon('arrow-left-1')" :class="{ 'rotate': isMiniMenu }" />
                    </div>
                </div>
            </div>
            <!-- menu cho ĐT -->
            <b-navbar v-if="isLogin && $root.isMobile" toggleable :type="isDarkMode ? 'dark' : 'light'"
                :variant="isDarkMode ? 'dark' : 'light'">
                <b-nav-form>
                    <div v-b-toggle.sidebar class="btn btn-icon-menu mr-5">
                        <img :src="getIcon('menu')" />
                    </div>
                    <div class="f1-invoice">
                        <img :src="getImg('logo', 'jpg')" /> Invoice
                    </div>
                </b-nav-form>
            </b-navbar>
            <b-sidebar v-if="isLogin && $root.isMobile" id="sidebar" no-header shadow backdrop-variant="dark" backdrop>
                <div class="f1-invoice">
                    <img :src="getImg('logo', 'jpg')" /> Invoice
                </div>
                <div class="menu-body">
                    <div v-for="(item, index) in menuItem" :key="'item-menu-' + index" class="item-menu">
                        <div :id="'item-menu-' + index">
                            <div class="parent-item" :class="{ 'hoover-item': !item.subMenu, 'is-active': item.isActive, }"
                                @click="moveToPage(item)">
                                <img :src="getIcon(item.icon)" class="icon-menu" />
                                <span class="ml-1">{{ item.name }}</span>
                            </div>
                            <div v-for="(subItem, subIndex) in item.subMenu" :key="'item-sub-menu-' + subIndex"
                                class="item-sub-menu" :class="{ 'is-active': subItem.isActive }"
                                @click="moveToPage(subItem)">
                                <img :src="getIcon(subItem.icon)" class="icon-menu" />
                                <span class="ml-1">{{ subItem.name }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="user-info">
                    <b-button-group>
                        <b-button block size="sm" id="user-info-btn" variant="primary">
                            <div>
                                <img :src="getIcon('profile-circle')" class="icon-menu" />
                            </div>
                            <div>
                                <span>XIN CHÀO</span>
                                <br>
                                <span class="small-text">
                                    {{ user.firstName + ' ' + user.lastName }}
                                </span>
                            </div>
                        </b-button>
                        <b-button block size="sm" id="setting-user-info-btn" variant="info">
                            <img :src="getIcon('more-circle')" class="icon-menu" />
                        </b-button>
                    </b-button-group>
                    <b-popover target="setting-user-info-btn" triggers="hover focus" ref="popover-user-info"
                        id="popover-user-info" variant="light" custom-class="style-for-mobile" placement="top">
                        <div class="content-tooltip">
                            <b-form-checkbox v-model="isDarkMode" name="check-button" switch class="mb-2 "
                                @change="setThemePreference(isDarkMode ? 'dark' : 'light')">
                                Chế độ ban đêm
                            </b-form-checkbox>
                            <b-form-checkbox v-model="showHuongDan" name="check-button" switch class="mb-2 "
                                @change="onClickBtnInfo(1)">
                                Hướng dẫn sử dụng
                            </b-form-checkbox>
                            <b-button variant="light" size="sm" class="mb-2 user-info-btn text-left"
                                @click="showModal('modal-thong-tin-user')">
                                Thông tin tài khoản
                            </b-button>
                            <b-button variant="light" size="sm" class="mb-2 user-info-btn text-left"
                                @click="showModal('modal-doi-mat-khau')">
                                Đổi mật khẩu
                            </b-button>
                            <b-button variant="light" size="sm" class="mb-2 user-info-btn text-left"
                                @click="showModal('modal-chinh-sua-ty-gia')">
                                Chỉnh sửa tỷ giá USD
                            </b-button>
                            <b-button variant="light" size="sm" class="mb-2 user-info-btn text-left"
                                @click="onClickBtnInfo(4)">
                                Đăng xuất
                            </b-button>
                        </div>
                    </b-popover>
                </div>
            </b-sidebar>
            <div class="content" :class="{ 'not-login': !isLogin, 'lg-content': isMiniMenu }">
                <router-view />
            </div>
        </div>

        <!-- loading icon -->
        <div class="loading-icon" v-if="isLoading === '1'">
            <b-spinner style="width: 3rem; height: 3rem" label="Large Spinner"></b-spinner>
        </div>

        <!-- modal -->
        <b-modal centered id="modal-chinh-sua-ty-gia" title="CHỈNH SỬA TỶ GIÁ" scrollable size="sm"
            :content-class="$root.isMobile ? 'style-for-mobile' : 'style-for-web'">
            <div class="d-block">
                <b-row>
                    <b-col cols="12">
                        <div class="text-left">Tỷ giá mới</div>
                        <b-input-group class="mb-3">
                            <b-form-input v-model="rateUsd"></b-form-input>
                        </b-input-group>
                    </b-col>
                </b-row>
            </div>
            <template #modal-footer>
                <b-row class="w-100 mt-3">
                    <b-col cols="4"></b-col>
                    <b-col cols="4">
                        <b-button block @click="$bvModal.hide('modal-chinh-sua-ty-gia')">
                            Hủy
                        </b-button>
                    </b-col>
                    <b-col cols="4">
                        <b-button variant="primary" block @click="onClickBtnInfo(2)">Lưu</b-button>
                    </b-col>
                </b-row>
            </template>
        </b-modal>

        <b-modal centered id="modal-doi-mat-khau" :title="$root.isLoginLanDau ? 'ĐỔI MẬT KHẨU LẦN ĐẦU' : 'ĐỔI MẬT KHẨU'"
            scrollable size="sm" :content-class="$root.isMobile ? 'style-for-mobile' : 'style-for-web'"
            :no-close-on-backdrop="$root.isLoginLanDau" :no-close-on-esc="$root.isLoginLanDau"
            :hide-header-close="$root.isLoginLanDau">
            <div class="d-block">
                <b-row>
                    <b-col cols="12">
                        <div class="text-left">Mật khẩu hiện tại</div>
                        <b-input-group class="mb-3">
                            <b-form-input :type="isShowPass ? 'text' : 'password'" v-model="passHienTai"></b-form-input>
                        </b-input-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12">
                        <div class="text-left">Mật khẩu mới</div>
                        <b-input-group class="mb-3">
                            <b-form-input :type="isShowPass ? 'text' : 'password'" v-model="passMoi"></b-form-input>
                        </b-input-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12">
                        <div class="text-left">Xác nhận mật khẩu mới</div>
                        <b-input-group class="mb-3">
                            <b-form-input :type="isShowPass ? 'text' : 'password'" v-model="passMoiNhapLai"></b-form-input>
                        </b-input-group>
                        <div v-if="isNhapSaiMatKhauMoi" class="text-left error-text">Mật khẩu không khớp</div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12">
                        <b-form-checkbox v-model="isShowPass" switch>
                            Hiển thị mật khẩu
                        </b-form-checkbox>
                    </b-col>
                </b-row>
            </div>
            <template #modal-footer>
                <b-row class="w-100 mt-3">
                    <b-col cols="4"></b-col>
                    <b-col cols="4">
                        <b-button v-if="!$root.isLoginLanDau" block @click="$bvModal.hide('modal-doi-mat-khau')">
                            Hủy
                        </b-button>
                    </b-col>
                    <b-col cols="4">
                        <b-button variant="primary" block @click="onClickBtnInfo(3)">Lưu</b-button>
                    </b-col>
                </b-row>
            </template>
        </b-modal>

        <b-modal centered id="modal-thong-tin-user" title="THÔNG TIN TÀI KHOẢN" scrollable size="md"
            :content-class="$root.isMobile ? 'style-for-mobile' : 'style-for-web'">
            <div class="d-block">
                <b-row>
                    <b-col cols="6">
                        <div class="text-left">Ngày hết hạn:</div>
                    </b-col>
                    <b-col cols="6">
                        <div class="text-left">{{ getFormatDate(user.expirationDate, 'formatDateTimeNoSecondVN') }}</div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="6">
                        <div class="text-left">Số lần quét trong 1 giờ:</div>
                    </b-col>
                    <b-col cols="6">
                        <div class="text-left">{{ user.maxCrawlData }}</div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="6">
                        <div class="text-left">Số lần lưu lịch sử:</div>
                    </b-col>
                    <b-col cols="6">
                        <div class="text-left">{{ user.maxHistory }}</div>
                    </b-col>
                </b-row>
            </div>
            <template #modal-footer>
                <b-row class="w-100 mt-3">
                    <b-col cols="8"></b-col>
                    <b-col cols="4">
                        <b-button block @click="$bvModal.hide('modal-thong-tin-user')">
                            Hủy
                        </b-button>
                    </b-col>
                </b-row>
            </template>
        </b-modal>
    </div>
</template>

<script>
import { Socket } from '@/websocket/websocket'
import Account from "@/service/account.service";

export default {
    name: "LoginPage",
    data() {
        return {
            socket: Socket,
            user: {},
            isLoading: false,
            activeTooltipInfo: false,
            menuItem: [],
            isDarkMode: false,
            activeItem: null,
            rateUsd: '',
            isMiniMenu: false,
            passHienTai: '',
            passMoi: '',
            passMoiNhapLai: '',
            isNhapSaiMatKhauMoi: false,
            isShowPass: false,
            showHuongDan: this.$root.showHuongDan,
        };
    },
    watch: {
        "$root.isLogin": {
            handler(val) {
                this.isLogin = val;
                this.checkLogin();
                this.checkActiveItem();
            },
            deep: true,
            immediate: true,
        },
        "$root.isLoginLanDau": {
            handler(val) {
                if (val) {
                    this.showModal('modal-doi-mat-khau');
                }
            },
            deep: true,
            immediate: true,
        },
        "$root.theme": {
            handler() {
                this.setThemePreference();
            },
            deep: true,
            immediate: true,
        },
        "socket.isConnected": {
            handler(val) {
                this.$root.isConnectedSocket = val;
            },
            deep: true,
            immediate: true,
        },
    },
    computed: {},
    created() {
        console.log("I am mounted to the DOM!");
        this.$root.isMobile = this.detectMob();
        this.setThemePreference();

        if (typeof localStorage.getItem('isMiniMenu') !== 'undefined') {
            this.isMiniMenu = localStorage.getItem('isMiniMenu') === 'true';
        }

        let _this = this;
        setInterval(() => {
            _this.isLoading = _this.$root.isShowLoading ? localStorage.getItem("loading") : 0;
            _this.checkActiveItem();
        }, 100);

        let token = localStorage.getItem("jwt");
        if (token) {
            this.$root.isLogin = true;
            this.isLogin = this.$root.isLogin;
            this.$root.rateUsd = localStorage.getItem('rateUsd');
            this.checkActiveItem();
            this.ketNoiWebsocket();
        } else {
            Socket.onDisconnect();
            let theme = localStorage.getItem("themeColor");
            let isMiniMenu = localStorage.getItem("isMiniMenu");
            localStorage.clear();
            localStorage.setItem("themeColor", theme);
            localStorage.setItem('isMiniMenu', isMiniMenu);
            this.$root.isLogin = false;
            this.isLogin = false
            this.deleteCookie('token');
            this.deleteCookie('userId');
            this.movePage("login", "replace");
        }
    },
    mounted() {
        window.addEventListener('keydown', (e) => {
            let key = e.keyCode || e.which;
            switch (key) {
                case 13: // enter
                    if (document.getElementById('modal-chinh-sua-ty-gia')) {
                        this.$root.isChangeRateUSD = true;
                        this.onClickBtnInfo(2);
                        e.preventDefault();
                    }
                    break;
            }
        });
        this.setInitItemMenu();
    },
    methods: {
        setInitItemMenu() {
            this.menuItem = [
                // {
                //     name: "Tổng Quang",
                //     icon: "home-2",
                //     path: "trang-chu",
                //     isActive: true,
                // },
                {
                    name: "Cài Đặt",
                    icon: "setting-3",
                    subMenu: [
                        {
                            name: "Danh Sách\nĐầu Nhận - Đầu Giao",
                            icon: "convert-3d-cube",
                            path: "nhan-giao",
                            isActive: false,
                        },
                        {
                            name: "Lưu Tài Khoản",
                            icon: "user-add",
                            path: "luu-super",
                            isActive: false,
                        },
                        {
                            name: "Cấu Hình",
                            icon: "setting-2",
                            path: "cau-hinh",
                            isActive: false,
                        },
                    ],
                },
                {
                    name: "Kế Toán",
                    icon: "calculator",
                    subMenu: [
                        {
                            name: "Kiểm Tra Lời & Lỗ",
                            icon: "dollar-square",
                            path: "kiem-tra-loi-lo",
                            isActive: false,
                        },
                        {
                            name: "Lịch Sử Xuất Hóa Đơn",
                            icon: "layer",
                            path: "lich-su-xuat-hoa-don",
                            isActive: false,
                        },
                        // {
                        //     name: "Kiểm Tra Đầu Giao",
                        //     icon: "empty-wallet-remove",
                        //     path: "kiem-tra-dau-giao",
                        //     isActive: false,
                        // },
                        // {
                        //     name: "Kiểm Tra Theo Phần Trăm",
                        //     icon: "percentage-square",
                        //     path: "kiem-tra-theo-phan-tram",
                        //     isActive: false,
                        // },
                        // {
                        //     name: "Lưu Trữ Báo Cáo",
                        //     icon: "receive-square",
                        //     path: "luu-tru-bao-cao",
                        //     isActive: false,
                        // },
                        // {
                        //     name: "Lưu Trữ Công Nợ",
                        //     icon: "receipt-2",
                        //     path: "luu-tru-cong-no",
                        //     isActive: false,
                        // },
                    ],
                },
            ];
        },
        setThemePreference(mode) {
            if (mode) {
                localStorage.setItem('themeColor', mode);
                this.switchHtmlTheme(mode);
            } else {
                let theme = 'light';
                if (localStorage.getItem('themeColor')) {
                    theme = localStorage.getItem('themeColor');
                } else {
                    localStorage.setItem('themeColor', theme);
                }
                this.isDarkMode = theme === 'dark';
                this.switchHtmlTheme(theme);
            }
        },
        checkLogin() {
            this.setInitItemMenu();
            let user = JSON.parse(localStorage.getItem("user"));
            if (user) {
                this.user = user;
                let roles = user.roles;
                if (roles.includes('ROLE_ADMIN') && this.menuItem.findIndex(i => i.name === 'Admin') === -1) {
                    let menuAdmin = {
                        name: "Admin",
                        icon: "setting",
                        subMenu: [
                            {
                                name: "Quản Lý Tài Khoản",
                                icon: "user-square",
                                path: "quan-ly-tai-khoan",
                                isActive: false,
                            },
                        ],
                    }
                    this.menuItem.push(menuAdmin)
                }
            }
        },
        onClickBtnInfo(value) {
            let theme = 'light';
            if (localStorage.getItem('themeColor')) {
                theme = localStorage.getItem('themeColor');
            }
            let isMiniMenu = false;
            if (localStorage.getItem('isMiniMenu')) {
                isMiniMenu = localStorage.getItem('isMiniMenu');
            }
            let user = JSON.parse(localStorage.getItem("user"));
            let userName = user ? user.userName : "";
            let param = {};
            let _this = this;
            switch (value) {
                case 1:
                    // window.open('https://f1-speed.gitbook.io/huong-dan-su-dung-phan-mem-f1-speed/huong-dan-su-dung/dang-nhap', '_blank').focus();
                    this.$root.showHuongDan = this.showHuongDan;
                    this.$refs['popover-user-info'].$emit('close')
                    break;
                case 2: // chỉnh sửa tỷ giá đô
                    Account.updateRate(this.$root.url, this.rateUsd)
                        .then(() => {
                            localStorage.setItem('rateUsd', _this.rateUsd ? _this.rateUsd : '-');
                            _this.$root.rateUsd = _this.rateUsd;
                            _this.$root.isChangeRateUSD = false;
                            _this.$bvModal.hide('modal-chinh-sua-ty-gia')
                        })
                        .catch((error) => {
                            _this.errorApi(error, _this);
                        });
                    break;
                case 3: // đổi mật khẩu
                    this.isNhapSaiMatKhauMoi = false;
                    if (this.passMoi !== this.passMoiNhapLai) {
                        this.isNhapSaiMatKhauMoi = true;
                        return;
                    }
                    param = {
                        passWord: this.passMoi,
                        passWordOld: this.passHienTai,
                        userName: userName,
                    }
                    Account.changePassword(this.$root.url, param)
                        .then(() => {
                            _this.$bvModal.hide('modal-doi-mat-khau')
                        })
                        .catch((error) => {
                            _this.errorApi(error, _this);
                        });
                    break;
                case 4: // đăng xuất
                    Socket.onDisconnect();
                    localStorage.clear();
                    localStorage.setItem('themeColor', theme);
                    localStorage.setItem('isMiniMenu', isMiniMenu);
                    this.isLogin = false;
                    this.$root.isLogin = false;
                    this.deleteCookie('token');
                    this.deleteCookie('userId');
                    this.movePage("login", "replace");
                    break;
            }
            this.activeTooltipInfo = false;
        },
        moveToPage(item) {
            if (item.path) {
                for (let i of this.menuItem) {
                    i.isActive = false;
                    if (i.subMenu) {
                        for (let j of i.subMenu) {
                            j.isActive = false;
                        }
                    }
                }
                item.isActive = true;
                this.movePage(item.path);
            }
        },
        checkActiveItem() {
            let routePath = this.$route.path;
            for (let i of this.menuItem) {
                i.isActive = routePath.includes(i.path);
                if (i.isActive) {
                    this.activeItem = i;
                }
                if (i.subMenu) {
                    i.isActive = false;
                    for (let j of i.subMenu) {
                        j.isActive = routePath.includes(j.path);
                        if (j.isActive) {
                            this.activeItem = j;
                        }
                    }
                }
            }
            if (this.$root.isMobile && !routePath.includes('/mb')) {
                this.movePage(this.activeItem.path);
            }
        },
        ketNoiWebsocket() {
            let prefixToken = "Bearer";
            let token = localStorage.getItem("jwt");
            let url = this.$root.url.replace('https', 'wss').replace('http', 'ws');

            Socket.onConnect(url, prefixToken + " " + token);
        },
        changeModeMenu() {
            this.isMiniMenu = !this.isMiniMenu;
            localStorage.setItem('isMiniMenu', this.isMiniMenu);
        },
        showModal(idModel) {
            this.rateUsd = '';
            this.passHienTai = '';
            this.passMoi = '';
            this.passMoiNhapLai = '';
            this.$bvModal.show(idModel)
        },
    },
};
</script>

<style lang="scss">
@import './assets/scss/theme/light.scss';
@import './assets/scss/theme/dark.scss';
@import './assets/scss/style-for-mobile.scss';
@import './assets/scss/style-for-web.scss';
</style>
