<template>
	<div id="kiem-tra-dau-giao" class="kiem-tra-dau-giao">
		<b-row class="header-page">
			<b-col cols="4">
				<span class="title-page">
					KIỂM TRA ĐẦU GIAO
				</span>
			</b-col>
		</b-row>

		<b-row>
			<b-col>
				<div class="ty-gia-tham-khao p-2 mb-2">
					<div>
						Tỷ giá USD: {{ tyGia }}
					</div>
				</div>
			</b-col>
		</b-row>
		<b-row>
			<b-col cols="6" class="mb-2">
				<div class="group-option">
					<b-input-group class="mr-3">
						<b-form-select v-model="rangeDate" @change="changeRangeDate()">
							<b-form-select-option selected disabled>
								Chọn Đầu Giao
							</b-form-select-option>
							<b-form-select-option v-for="(range, rangeIdx) in rangeDates" :key="rangeIdx"
								:value="range.key">
								{{ range.text }}
							</b-form-select-option>
						</b-form-select>
					</b-input-group>
					<b-input-group class="mr-2">
						<b-form-input id="input-crDateFrom" :value="getFormatDate(formDate, 'formatDate')" type="text"
							autocomplete="off" readonly></b-form-input>
						<b-input-group-append>
							<b-form-datepicker id="datepicker-crDateFrom" v-model="formDate" class="btn-datepicker"
								:dark="$root.theme === 'dark'" start-weekday="1" locale="vi" button-only
								button-variant="outline-secondary" right :max="toDate"
								label-help="Sử dụng các phím con trỏ để điều hướng" :hide-header="true"
								@input="changeRangeDate(rangeDates[0].key)">
							</b-form-datepicker>
						</b-input-group-append>
					</b-input-group>
					<div class="mr-2">
						Tới
					</div>
					<b-input-group>
						<b-form-input id="input-crDateFrom" :value="getFormatDate(toDate, 'formatDate')" type="text"
							autocomplete="off" readonly></b-form-input>
						<b-input-group-append>
							<b-form-datepicker id="datepicker-crDateFrom" v-model="toDate" class="btn-datepicker"
								:dark="$root.theme === 'dark'" start-weekday="1" locale="vi" button-only
								button-variant="outline-secondary" right :min="formDate"
								label-help="Sử dụng các phím con trỏ để điều hướng" :hide-header="true"
								@input="changeRangeDate(rangeDates[0].key)">
							</b-form-datepicker>
						</b-input-group-append>
					</b-input-group>
				</div>
			</b-col>
			<b-col cols="6" class="mb-2 text-right button-action">
				<b-button variant="primary" class="ml-3" >
					Báo Cáo
				</b-button>
				<b-button variant="primary" class="ml-3" >
					Xuất Khó Đơn
				</b-button>
				<b-button variant="primary" class="ml-3" >
					Lưu Công Nợ
				</b-button>
			</b-col>
		</b-row>
		<b-row>
			<b-col>
				<div class="table-luu-super">
					<b-table sticky-header hover :head-variant="$root.theme" small show-empty emptyText="Không có dữ liệu"
						empty-filtered-text="Không có dữ liệu phù hợp tìm kiếm" :fields="fieldsTable" :items="dataTable"
						:current-page="currentPage" :per-page="perPage">
						<template #empty="scope">
							<span>{{ scope.emptyText }}</span>
						</template>
					</b-table>
					<b-pagination v-if="dataTable.length > 0" v-model="currentPage" pills :total-rows="totalRows"
						:per-page="perPage" align="right">
						<template #first-text>
							<b-icon icon="chevron-bar-left"></b-icon>
						</template>
						<template #prev-text>
							<b-icon icon="chevron-left"></b-icon>
						</template>
						<template #next-text>
							<b-icon icon="chevron-right"></b-icon>
						</template>
						<template #last-text>
							<b-icon icon="chevron-bar-right"></b-icon>
						</template>
					</b-pagination>
				</div>
			</b-col>
		</b-row>
		<b-row>
			<b-col offset="6" cols="6">
				<div class="table-luu-super">
					<b-table sticky-header hover :head-variant="$root.theme" small show-empty emptyText="Không có dữ liệu"
						empty-filtered-text="Không có dữ liệu phù hợp tìm kiếm" :fields="fieldsTable1" :items="dataTable1">
						<template #empty="scope">
							<span>{{ scope.emptyText }}</span>
						</template>
					</b-table>
				</div>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
	name: "KiemTraDauGiao",
	data() {
		return {
			tyGia: localStorage.getItem('rateUsd'),
			formDate: this.getRangeDate('homNay')[0],
			toDate: this.getRangeDate('homNay')[1],
			fieldsTable: [
				{ key: 'index', label: 'STT' },
				{ key: '1', label: 'Nhà Cái' },
				{ key: '2', label: 'Mã TK' },
				{ key: '3', label: 'Win/Loss' },
				{ key: '4', label: 'COM' },
				{ key: '5', label: 'Hệ số COM' },
				{ key: '6', label: 'NET Win/Loss' },
				{ key: '7', label: 'Hệ số Thầu' },
				{ key: '8', label: 'Số thanh Toán (VNĐ)' },
				{ key: '98', label: 'Số thanh Toán (USD)' },
				{ key: '10', label: 'Ghi chú' },
			],
			dataTable: [],
			totalRows: 0,
			currentPage: 1,
			perPage: 15,
			fieldsTable1: [
				{ key: '1', label: 'Tiền Tệ' },
				{ key: '2', label: 'VNĐ' },
				{ key: '3', label: 'USD' },
			],
			dataTable1: [],
		};
	},
	computed: {
		...mapState("KiemTraDauGiao", []),
	},
	beforeCreate() {
		console.log("I have not been created yet!");
	},
	created() {
		console.log("I have just been created!");
	},
	beforeMount() {
		console.log("I am about to be mounted to the DOM!");
	},
	mounted() {
		console.log("I am mounted to the DOM!");
		this.forceMovePage();
	},
	beforeDestroy() {
		console.log("I am about to be removed from the DOM!");
	},
	destroyed() {
		console.log("I no longer exist...");
	},
	methods: {
		...mapActions("KiemTraDauGiao", []),
	},
};
</script>

<style lang="scss">
.kiem-tra-dau-giao {
	.ty-gia-tham-khao {
		background: var(--background-ty-gia);
		border-radius: 8px;
		color: var(--black-color);
		font-weight: bold;
	}

	.group-option {
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
}
</style>
