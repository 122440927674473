import axios from "axios";

const PREFIX_TOKEN = "Bearer";

const ApiService = {
	setHeader() {
		let token = localStorage.getItem("jwt");
		axios.defaults.headers.common["Authorization"] = `${PREFIX_TOKEN} ${token}`;
		this.setLoading();
	},

	removeHeader() {
		axios.defaults.headers.common = {};
	},

	setLoading() {
		localStorage.setItem("loading", 1);
	},

	get(url, params) {
		this.setHeader();
		return axios.get(url, { params: params }).then((res) => {
			localStorage.setItem("loading", 0);
			return res;
		});
	},

	getWithNoToken(url, params) {
		return axios.get(url, { params: params }).then((res) => {
			localStorage.setItem("loading", 0);
			return res;
		});
	},

	post(resource, data) {
		this.setHeader();
		return axios.post(resource, data).then((res) => {
			localStorage.setItem("loading", 0);
			return res;
		});
	},

	download(resource, data, postConfig) {
		this.setHeader();
		return axios.post(resource, data, postConfig).then((res) => {
			localStorage.setItem("loading", 0);
			return res;
		});
	},

	put(resource, data) {
		this.setHeader();
		return axios.put(resource, data).then((res) => {
			localStorage.setItem("loading", 0);
			return res;
		});
	},

	patch(resource, data) {
		this.setHeader();
		return axios.patch(resource, data).then((res) => {
			localStorage.setItem("loading", 0);
			return res;
		});
	},

	delete(resource, data) {
		this.setHeader();
		return axios.delete(resource, { data: data }).then((res) => {
			localStorage.setItem("loading", 0);
			return res;
		});
	},
};

export default ApiService;
