import ApiService from "./api.service";

const Formulas = {
    getAll: async (url) => {
        url = url + "/formulas/list-details";
        return ApiService.get(url);
    },
    getCategory: async (url, typeDomain) => {
        url = url + "/formulas/formula-category";
        if (typeDomain) {
            url =  url + "?typeDomain=" + typeDomain;
        }
        return ApiService.get(url);
    },
    getCombo: async (url, domainId) => {
        url = url + "/formulas/category-combo";
        if (domainId) {
            url =  url + "?domainId=" + domainId;
        }
        return ApiService.get(url);
    },
    post: async (url, data) => {
        url = url + `/formulas/insert`;
        return ApiService.post(url, data);
    },
    delete: async (url, id) => {
        url = url + `/formulas/delete?ids=${id}`;
        return ApiService.delete(url);
    },
};

export default Formulas;
