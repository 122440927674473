<template>
	<div id="nhan-giao-page" class="nhan-giao-page">
		<b-row>
			<b-col cols="12" class="tab-btn mb-3">
				<div>
					<b-button size="sm" :variant="showTab === '1' ? 'primary' : 'light'" @click="changeTab('1')"
						class="mr-1" :class="{ 'active-tab': showTab === '1' }">
						<img :src="getIcon('money-recive')" class="pr-2" />
						Đầu Nhận
					</b-button>
					<b-button size="sm" :variant="showTab === '2' ? 'primary' : 'light'" @click="changeTab('2')"
						class="ml-1" :class="{ 'active-tab': showTab === '2' }">
						<img :src="getIcon('money-send')" class="pr-2" />
						Đầu Giao
					</b-button>
				</div>
			</b-col>
		</b-row>

		<!-- dau nhan -->
		<template v-if="showTab === '1'">
			<b-row class="header-page">
				<b-col cols="4">
					<span class="title-page">
						DANH SÁCH ĐẦU NHẬN
					</span>
				</b-col>
				<b-col cols="4">
					<b-input-group class="input-search">
						<b-input-group-prepend is-text>
							<img :src="getIcon('search-normal')" class="pr-2" />
						</b-input-group-prepend>
						<b-form-input ref="input-search-nhan" type="search" placeholder="Tìm kiếm" v-model="searchValueNhan"
							@input="onInputsearchValueNhan"></b-form-input>
					</b-input-group>
				</b-col>
			</b-row>

			<b-row class="header-page">
				<b-col cols="4" class="text-left button-action">
					<b-button variant="primary" id="btn-them-dau-nhan" @click="openThemDauNhan">
						<img :src="getIcon('add-circle')" class="pr-2" />
						Thêm Đầu Nhận
					</b-button>
				</b-col>
			</b-row>

			<b-row>
				<b-col cols="12" class="page-dau-nhan">
					<div class="list-dau-nhan">
						<div class="item-dau-nhan" v-for="(item, index) in dataDauNhanHienThi" :key="index">
							<b-button :variant="dauNhanDcChon.id === item.id ? 'info' : 'light'" @click="chonDauNhan(item)"
								class="mb-2">
								<img v-if="dauNhanDcChon.id === item.id" :src="getIcon('tick-square')" class="pr-2" />
								<span>
									<p>{{ item.receiverName }}</p>
									<p class="small-text">{{ item.updateTime ? item.updateTime : '-' }}</p>
								</span>
							</b-button>
						</div>
					</div>
					<div cols="10" v-if="dauNhanDcChon.receiverName" class="thong-tin-dau-nhan">
						<div class="info-dau-nhan p-3 mb-2">
							<b-row>
								<b-col class="title-info-dau-nhan mb-3">
									THÔNG TIN ĐẦU NHẬN
								</b-col>
							</b-row>
							<b-row>
								<b-col cols="11">
									<div class="value-info-dau-nhan">
										<label>Tên đầu nhận:</label>
										<label class="info-value">{{ dauNhanDcChon.detail.receiverName }}</label>
									</div>
									<div class="value-info-dau-nhan">
										<label>Số điện thoại:</label>
										<label class="info-value">{{ dauNhanDcChon.detail.phoneNumber }}</label>
									</div>
									<div class="value-info-dau-nhan">
										<label>Ghi chú:</label>
										<label class="info-value">{{ dauNhanDcChon.detail.note }}</label>
									</div>
								</b-col>
								<b-col cols="1">
									<b-button variant="primary" @click="openSuaDauNhan" class="mb-2"
										:disabled="!dauNhanDcChon.id">
										<img :src="getIcon('edit-2')" title="Chỉnh sửa" />
									</b-button>
									<b-button variant="danger" id="btn-xoa-dau-nhan" :disabled="!dauNhanDcChon.id"
										@click="$refs['popover-xoa-dau-nhan'].$emit('open')">
										<img :src="getIcon('trash')" title="Xóa" />
									</b-button>
									<b-popover id="popover-xoa-dau-nhan" ref="popover-xoa-dau-nhan"
										target="btn-xoa-dau-nhan" triggers="focus" custom-class="style-for-web">
										<template #title>XÁC NHẬN XÓA ĐẦU NHẬN</template>
										<div class="msg-xac-nhan-xoa">
											Bạn Chắc Chắn Muốn Xóa Đầu Nhận <b>{{
												dauNhanDcChon.receiverName
											}}</b>
										</div>
										<hr>
										<div class="btn-xac-nhan-xoa">
											<b-button :id="'btn-xac-nhan-ko-xoa'" class="mr-1" @click="huyXoaDauNhan">
												Không
											</b-button>
											<b-button :id="'btn-xac-nhan-co-xoa'" variant="danger" @click="xoaDauNhan">
												Có
											</b-button>
										</div>
									</b-popover>
								</b-col>
							</b-row>
						</div>
						<div class="filter-dau-nhan mb-2">
							<b-row>
								<b-col cols="3" v-for="(combo, indexCombo) in dataCombo" :key="indexCombo">
									<b-dropdown variant="light" id="dropdown-dau-nhan" class="dropdown-dau-nhan"
										:class="{ ['active-btn-combo-' + indexCombo]: combo.isActive }" no-caret>
										<template #button-content>
											<span class="label-type">{{ combo.type }}</span>
											<b-icon class="icon-arrow" icon="triangle-fill"></b-icon>
										</template>
										<b-dropdown-form>
											<b-form-checkbox class="option-filter mb-1" @change="checkDomain()"
												v-for="(domain, indexDomain) in combo.domains" :key="indexDomain"
												v-model="listDomainDaChon[domain.domainId]">
												{{ domain.domainName }}
											</b-form-checkbox>
										</b-dropdown-form>
									</b-dropdown>
								</b-col>
							</b-row>
							<b-row v-if="listTagsDomainDaChon.length > 0">
								<b-col>
									<div class="tags-domain mt-2">
										<div v-for="(itemTag, indexTag) in listTagsDomainDaChon" :key="indexTag"
											class="btn ml-2 mb-1" :class="'active-btn-combo-' + itemTag.indexCombo"
											@click="removeTag(itemTag, indexTag)">
											{{ itemTag.name }} <b-icon icon="x" class="icon-remove-tag ml-2"></b-icon>
										</div>
									</div>
								</b-col>
							</b-row>
						</div>
						<div class="chi-tiet-dau-nhan">
							<b-table sticky-header hover :head-variant="$root.theme" small show-empty
								emptyText="Không có dữ liệu" empty-filtered-text="Không có dữ liệu phù hợp tìm kiếm"
								:fields="fieldsNhan" :items="dataLoaiChoi" :current-page="currentPageNhan"
								:per-page="perPageNhan">
								<template #empty="scope">
									<span>{{ scope.emptyText }}</span>
								</template>
								<template #cell(domainName)="data">
									<div :class="getClassTheoCombo(data.item)">
										<span><b>{{ data.item.domainName }}</b></span>
									</div>
								</template>
								<template #cell(currency)="data">
									<span>{{ data.item.currency === 'VND' ? 'VNĐ' : 'USD' }}</span>
								</template>
								<template #cell(bids)="data">
									<span>
										{{ data.item.bids + (data.item.isGiaThauPercen ? '%' : '') }}
									</span>
								</template>
							</b-table>
							<b-pagination v-if="dataLoaiChoi.length > 0" v-model="currentPageNhan" pills
								:total-rows="totalRowsNhan" :per-page="perPageNhan" align="right">
								<template #first-text>
									<b-icon icon="chevron-bar-left"></b-icon>
								</template>
								<template #prev-text>
									<b-icon icon="chevron-left"></b-icon>
								</template>
								<template #next-text>
									<b-icon icon="chevron-right"></b-icon>
								</template>
								<template #last-text>
									<b-icon icon="chevron-bar-right"></b-icon>
								</template>
							</b-pagination>
						</div>
					</div>
				</b-col>
			</b-row>
		</template>

		<!-- dau giao -->
		<template v-if="showTab === '2'">
			<b-row class="header-page">
				<b-col cols="4">
					<span class="title-page">
						DANH SÁCH ĐẦU GIAO
					</span>
				</b-col>
				<b-col cols="4">
					<b-input-group class="input-search">
						<b-input-group-prepend is-text>
							<img :src="getIcon('search-normal')" class="pr-2" />
						</b-input-group-prepend>
						<b-form-input ref="input-search-giao" type="search" placeholder="Tìm kiếm"
							v-model="searchValueGiao"></b-form-input>
					</b-input-group>
				</b-col>
				<b-col cols="4" class="text-right button-action">
					<b-button variant="primary" id="btn-them-dau-nhan" @click="openThemDauGiao">
						<img :src="getIcon('add-circle')" class="pr-2" />
						Thêm Đầu Giao
					</b-button>
				</b-col>
			</b-row>
			<b-row>
				<b-col cols="12">
					<div class="table-dau-giao">
						<b-table sticky-header hover :head-variant="$root.theme" small show-empty
							emptyText="Không có dữ liệu" empty-filtered-text="Không có dữ liệu phù hợp tìm kiếm"
							:fields="fieldsGiao" :items="dataDauGiao" :current-page="currentPageGiao"
							:per-page="perPageGiao" :filter="searchValueGiao" @filtered="onFiltered">
							<template #empty="scope">
								<span>{{ scope.emptyText }}</span>
							</template>
							<template #cell(deliverName)="data">
								<div class="btn-link" @click="rowClicked(data.item)">
									<span>{{ data.item.deliverName }}</span>
									<img :class="{ 'rotate': data.item._showDetails }" :src="getIcon('arrow-down')"
										class="ml-1" />
								</div>
							</template>
							<template #row-details="data">
								<b-table sticky-header hover :head-variant="$root.theme" small show-empty
									emptyText="Không có tài khoản nào lưu giá đầu giao này" :fields="fieldsSuperOfDeliver"
									:items="data.item.dataSuperOfDeliver">
									<template #empty="scope">
										<span>{{ scope.emptyText }}</span>
									</template>
								</b-table>
							</template>
							<template #cell(action)="data">
								<div class="pl-5 text-left button-action">
									<b-button size="sm" :id="'btn-chinh-sua-' + data.item.index" variant="primary"
										@click="chinhSuaDauGiao(data.item)">
										<img :src="getIcon('edit-2')" title="Chỉnh sửa" />
									</b-button>
									<b-button size="sm" :id="'btn-xoa-giao-' + data.item.index" variant="danger"
										@click="setRowSelected(data.item)">
										<img :src="getIcon('trash')" title="Xóa" />
									</b-button>
									<b-popover :id="'popover-xoa-' + data.item.index"
										:ref="'popover-xoa-' + data.item.index" :target="'btn-xoa-giao-' + data.item.index"
										triggers="focus" custom-class="style-for-web">
										<template #title>XÁC NHẬN XÓA ĐẦU GIAO</template>
										<div class="msg-xac-nhan-xoa">
											Bạn Chắc Chắn Muốn Xóa Đầu Giao <b>{{ data.item.deliverName }}</b>
										</div>
										<hr>
										<div class="btn-xac-nhan-xoa">
											<b-button :id="'btn-xac-nhan-ko-xoa-' + data.item.index" class="mr-1"
												@click="huyXoaDauGiao(data.item.index)">
												Không
											</b-button>
											<b-button :id="'btn-xac-nhan-co-xoa-' + data.item.index" variant="danger"
												@click="xoaDauGiao(data.item)">
												Có
											</b-button>

										</div>
									</b-popover>
								</div>
							</template>
						</b-table>
						<b-pagination v-if="dataDauGiao.length > 0" v-model="currentPageGiao" pills
							:total-rows="totalRowsGiao" :per-page="perPageGiao" align="right">
							<template #first-text>
								<b-icon icon="chevron-bar-left"></b-icon>
							</template>
							<template #prev-text>
								<b-icon icon="chevron-left"></b-icon>
							</template>
							<template #next-text>
								<b-icon icon="chevron-right"></b-icon>
							</template>
							<template #last-text>
								<b-icon icon="chevron-bar-right"></b-icon>
							</template>
						</b-pagination>
					</div>
				</b-col>
			</b-row>
		</template>

		<b-modal centered id="modal-them-dau-nhan"
			:title="formDataThemDauNhan.receiverId ? 'CHỈNH SỬA ĐẦU NHẬN' : 'THÊM ĐẦU NHẬN'" scrollable size="lg"
			content-class="style-for-web">
			<div class="d-block">
				<b-row>
					<b-col cols="4">
						<div class="text-left">Tên Đầu Nhận</div>
						<b-input-group class="mb-3">
							<b-form-input v-model="formDataThemDauNhan.receiverName"></b-form-input>
						</b-input-group>
					</b-col>
					<b-col>
						<div class="text-left">Số Điện Thoại</div>
						<b-input-group class="mb-3">
							<b-form-input v-model="formDataThemDauNhan.phoneNumber"></b-form-input>
						</b-input-group>
					</b-col>
					<b-col cols="4">
						<div class="text-left">Chú Thích</div>
						<b-input-group class="mb-3">
							<b-form-input v-model="formDataThemDauNhan.note"></b-form-input>
						</b-input-group>
					</b-col>
				</b-row>
				<div>
					<div v-for="(itemNhaCai, indexNhaCai) in formDataThemDauNhan.listNhaCai" :key="indexNhaCai"
						class="content p-2 mb-2">
						<b-row class="mb-2">
							<b-col cols="12">
								<div class="text-left">Tên Nhà Cái</div>
								<div class="select-nha-cai">
									<b-input-group class="mr-2 w-50">
										<multi-select v-model="itemNhaCai.nhaCai" :options="dataNhaCai" :allow-empty="false"
											label="name" track-by="domainId" :preselect-first="true" class="multi-select"
											placeholder="" @input="changeDomain(itemNhaCai)">
										</multi-select>
									</b-input-group>
									<b-input-group class="w-50">
										<b-form-input :value="itemNhaCai.nhaCai.urlMember" readonly></b-form-input>
									</b-input-group>
								</div>
							</b-col>
						</b-row>
						<b-row v-if="itemNhaCai.listCombo.length > 0">
							<b-col cols="12">
								<div class="text-left">Chọn Cách Tính Tiền</div>
								<b-input-group>
									<multi-select v-model="itemNhaCai.comboObj" :options="itemNhaCai.listCombo"
										:allow-empty="false" label="comboName" track-by="comboId" :preselect-first="true"
										class="multi-select" placeholder="" @input="changeCombo(itemNhaCai)">
									</multi-select>
								</b-input-group>
							</b-col>
						</b-row>
						<template v-if="itemNhaCai.listCombo.length > 0 && itemNhaCai.comboSelected">
							<div class="items-nha-cai">
								<div v-for="(itemLoaiChoi, indexLoaiChoi) in itemNhaCai.listLoaiChoi" :key="indexLoaiChoi"
									class="p-2">
									<b-row class="mb-2">
										<b-col cols="3">
											<div class="text-left">Loại Chơi</div>
											<b-input-group>
												<multi-select v-model="itemLoaiChoi.categoryObj"
													:options="dataFormulaCategory" :allow-empty="false" label="shortname"
													track-by="formulaCategoryId" :preselect-first="true"
													class="multi-select" :disabled="true" placeholder="">
												</multi-select>
											</b-input-group>
										</b-col>
										<b-col cols="3">
											<div class="text-left">Loại Tiền</div>
											<b-input-group>
												<multi-select v-model="itemLoaiChoi.currencyObj" :options="dataCurrency"
													:allow-empty="false" label="name" track-by="key" :preselect-first="true"
													class="multi-select" placeholder=""
													@input="itemLoaiChoi.currency = itemLoaiChoi.currencyObj.key">
												</multi-select>
											</b-input-group>
										</b-col>
										<b-col cols="3">
											<div class="text-left">Hoa Hồng</div>
											<b-input-group>
												<b-form-input type="number" v-model="itemLoaiChoi.coefficients">
												</b-form-input>
											</b-input-group>
										</b-col>
										<b-col cols="3">
											<div class="text-left gia-thau">
												Giá Thầu
												<b-form-checkbox class="ml-2" v-model="itemLoaiChoi.isGiaThauPercen">
													%
												</b-form-checkbox>
											</div>
											<b-input-group>
												<b-form-input type="number" v-model="itemLoaiChoi.bids"
													@paste="changeBids($event, itemLoaiChoi)">
												</b-form-input>
												<template #append v-if="itemLoaiChoi.isGiaThauPercen">
													<b-input-group-text>
														<img :src="getIcon('percentage-square')" />
													</b-input-group-text>
												</template>
											</b-input-group>
										</b-col>
									</b-row>
								</div>
							</div>
						</template>
						<b-row class="mt-2">
							<b-col offset="4" cols="4">
								<b-button
									v-if="(indexNhaCai > 0 || formDataThemDauNhan.listNhaCai.length > 1) && indexNhaCai <= formDataThemDauNhan.listNhaCai.length - 1"
									variant="danger" @click="xoaNhaCai(indexNhaCai)" class="mr-2 w-100 btn-xnc">
									Xóa Nhà Cái
								</b-button>
							</b-col>
							<b-col cols="4">
								<b-button v-if="indexNhaCai === formDataThemDauNhan.listNhaCai.length - 1" variant="primary"
									@click="themNhaCai" class="w-100 btn-tnc">
									Thêm Nhà Cái
								</b-button>
							</b-col>
						</b-row>
					</div>
				</div>
			</div>
			<template #modal-footer>
				<b-row class="w-100 mt-3">
					<b-col offset="4" cols="4">
						<b-button mt-3 block @click="$bvModal.hide('modal-them-dau-nhan')">
							Hủy
						</b-button>
					</b-col>
					<b-col cols="4">
						<b-button variant="primary" mt-3 block @click="submitThemDauNhan">
							{{ formDataThemDauNhan.receiverId ? 'Lưu' : 'Thêm' }}
						</b-button>
					</b-col>
				</b-row>
			</template>
		</b-modal>

		<b-modal centered id="modal-them-dau-giao" :title="deliverId ? 'CHỈNH SỬA ĐẦU GIAO' : 'THÊM ĐẦU GIAO'" scrollable
			size="lg" content-class="style-for-web">
			<div class="d-block">
				<b-row class="mb-3">
					<b-col cols="6">
						<div class="text-left">Tên Đầu Giao</div>
						<b-input-group>
							<b-form-input v-model="deliverName"></b-form-input>
						</b-input-group>
					</b-col>
					<b-col cols="6">
						<div class="text-left">Bí Danh</div>
						<b-input-group>
							<b-form-input v-model="code"></b-form-input>
						</b-input-group>
					</b-col>
				</b-row>
				<b-row class="mb-3">
					<b-col cols="6">
						<div class="text-left">Số Điện Thoại</div>
						<b-input-group>
							<b-form-input v-model="phoneNumber"> </b-form-input>
						</b-input-group>
					</b-col>
					<b-col cols="6">
						<div class="text-left">Chú Thích</div>
						<b-input-group>
							<b-form-input v-model="description"> </b-form-input>
						</b-input-group>
					</b-col>
				</b-row>
			</div>
			<template #modal-footer>
				<b-row class="w-100 mt-3">
					<b-col cols="4"></b-col>
					<b-col cols="4">
						<b-button block @click="$bvModal.hide('modal-them-dau-giao')">
							Hủy
						</b-button>
					</b-col>
					<b-col cols="4">
						<b-button variant="primary" block @click="submitThemDauGiao">
							{{ deliverId ? 'Lưu' : 'Thêm' }}
						</b-button>
					</b-col>
				</b-row>
			</template>
		</b-modal>

		<huong-dan v-if="(dataDauNhan.length > 0 || dataDauGiao.length > 0) && showHuongDan" :mode="modeHuongDan"
			@onClick="onClickHuongDan"></huong-dan>
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Receiver from "../../../service/receiver.service";
import Deliver from "../../../service/deliver.service";
import Map from "../../../service/map.service";
import HuongDanNhanGiaoPage from "../HuongDan/NhanGiao.vue"

export default {
	name: "DauNhanPage",
	components: {
		"huong-dan": HuongDanNhanGiaoPage,
	},
	data() {
		return {
			showTab: '1',
			// data dau giao
			searchValueNhan: '',
			dataDauNhan: [],
			dataDauNhanHienThi: [],
			dataNhaCai: [],
			dataLoaiChoi: [],
			dataFormulaCategory: [],
			dataCombo: [],
			dataCurrency: [
				{
					key: 'VND',
					name: 'VNĐ',
				},
				{
					key: 'USD',
					name: 'USD',
				},
			],
			dauNhanDcChon: {
				detail: {},
			},
			formDataThemDauNhan: {
				receiverName: '',
				phoneNumber: '',
				note: '',
				listNhaCai: [
					{
						nhaCai: {},
						comboSelected: '',
						comboObj: {},
						listCombo: [],
						listLoaiChoi: [
							{
								categoryId: '',
								categoryObj: {},
								bids: '',
								coefficients: 1,
								currency: 'VND',
								currencyObj: {
									key: 'VND',
									name: 'VNĐ',
								},
								isGiaThauPercen: false,
							}
						],
					}
				]
			},
			listDomainDaChon: [],
			listTagsDomainDaChon: [],
			// table dau nhan
			fieldsNhan: [
				{ key: 'index', label: 'STT' },
				{ key: 'domainName', label: 'Nhà Cái', sortable: true },
				{ key: 'categoryName', label: 'Loại Chơi', sortable: true },
				{ key: 'coefficients', label: 'Hoa Hồng', sortable: true },
				{ key: 'bids', label: 'Giá Thầu', sortable: true },
				{ key: 'currency', label: 'Loại Tiền', sortable: true },
			],
			totalRowsNhan: 0,
			currentPageNhan: 1,
			perPageNhan: 25,
			// data dau giao
			searchValueGiao: '',
			// table dau giao
			dataDauGiao: [],
			fieldsGiao: [
				{ key: 'index', label: 'STT' },
				{ key: 'deliverName', label: 'Đầu Giao', sortable: true },
				{ key: 'code', label: 'Bí Danh', sortable: true },
				{ key: 'phoneNumber', label: 'Số Điện Thoại' },
				{ key: 'description', label: 'Ghi Chú' },
				{ key: 'action', label: '' }
			],
			fieldsSuperOfDeliver: [
				{ key: 'receiverName', label: 'Đầu Nhận' },
				{ key: 'userName', label: 'Tên Tài Khoản' },
				{ key: 'domainName', label: 'Nhà Cái' },
			],
			totalRowsGiao: 0,
			currentPageGiao: 1,
			perPageGiao: 25,
			rowSelected: {},
			// form modal dau giao
			deliverId: '',
			deliverName: '',
			code: '',
			phoneNumber: '',
			description: '',
			showHuongDan: this.$root.showHuongDan,
			modeHuongDan: 1,
		}
	},
	watch: {
		"$root.showHuongDan": {
			handler(val) {
				this.showHuongDan = val;
			},
			deep: true,
		},
	},
	computed: {
		...mapState("DauNhanPage", []),
	},
	beforeCreate() {
		console.log("I have not been created yet!");
	},
	created() {
		console.log("I have just been created!");
	},
	beforeMount() {
		console.log("I am about to be mounted to the DOM!");
	},
	mounted() {
		console.log("I am mounted to the DOM!");
		this.forceMovePage();
		this.getReceiver();
		this.getDomain();
		this.getFormulas();
		window.addEventListener('keydown', this.keydown);
	},
	beforeDestroy() {
		console.log("I am about to be removed from the DOM!");
	},
	destroyed() {
		console.log("I no longer exist...");
		window.removeEventListener('keydown', this.keydown);
	},
	methods: {
		...mapActions("DauNhanPage", []),
		keydown(e) {
			let key = e.keyCode || e.which;
			let isForcusDropdown = e.target.className === 'multiselect__input';
			if (isForcusDropdown) {
				return;
			}
			switch (key) {
				case 13: // enter
					if (this.showTab === '1') {
						if (document.getElementById('modal-them-dau-nhan')) {
							this.submitThemDauNhan();
						} else if (document.getElementById('btn-xac-nhan-co-xoa')) {
							this.xoaDauNhan();
						}
					} else {
						if (document.getElementById('modal-them-dau-giao')) {
							this.submitThemDauGiao();
						} else if (document.getElementById('btn-xac-nhan-co-xoa-' + this.rowSelected.index)) {
							this.xoaDauGiao(this.rowSelected);
						}
					}
					e.preventDefault();
					break;
				case 27: // esc
					if (this.showTab === '1') {
						if (document.getElementById('btn-xac-nhan-ko-xoa')) {
							this.huyXoaDauNhan();
						}
					} else {
						if (document.getElementById('btn-xac-nhan-ko-xoa-' + this.rowSelected.index)) {
							this.huyXoaDauGiao(this.rowSelected.index);
						}
					}
					e.preventDefault();
					break;
				case 122: // f11
					if (this.showTab === '1') {
						this.openThemDauNhan();
					} else {
						this.openThemDauGiao();
					}
					e.preventDefault();
					break;
				case 114: // f3
					if (this.showTab === '1') {
						this.$refs['input-search-nhan'].$el.focus();
					} else {
						this.$refs['input-search-giao'].$el.focus();
					}
					e.preventDefault();
					break;
			}
		},
		getReceiver() {
			let _this = this;
			Receiver.getAll(this.$root.url)
				.then((response) => {
					_this.dataDauNhan = response.data || [];
					_this.dataDauNhanHienThi = response.data || [];
					if (_this.dataDauNhanHienThi.length) {
						this.chonDauNhan(_this.dataDauNhanHienThi[0]);
					}
				})
				.catch((error) => {
					_this.errorApi(error, _this);
				});
		},
		getDomain() {
			this.dataNhaCai = JSON.parse(localStorage.getItem('DATA_NHA_CAI'));
		},
		getFormulas() {
			this.dataFormulaCategory = JSON.parse(localStorage.getItem('DATA_FORMULA_CATEGORY'));
		},
		getDetailDauNhan() {
			let _this = this;
			Receiver.getDetail(_this.$root.url, _this.dauNhanDcChon.id)
				.then((response) => {
					_this.dataLoaiChoi = [];
					_this.dataCombo = [];
					_this.listDomainDaChon = [];
					_this.listTagsDomainDaChon = [];

					_this.dauNhanDcChon.detail = response.data;
					_this.dataCombo = response.data.types;
					_this.dataLoaiChoi = response.data.formulas;
					for (const [index, item] of _this.dataLoaiChoi.entries()) {
						item.index = index + 1;
					}
					_this.totalRowsNhan = response.data.formulas.length;
				})
				.catch((error) => {
					_this.errorApi(error, _this);
				});
		},
		getDeliver() {
			let _this = this;
			Deliver.getTree(this.$root.url)
				.then((response) => {
					_this.dataDauGiao = []
					for (const [index, item] of response.data.entries()) {
						item.index = index + 1;
						item._showDetails = false;
						item.dataSuperOfDeliver = [];
						_this.dataDauGiao.push(item);
					}
					_this.totalRowsGiao = response.data.length;
				}).catch((error) => {
					_this.errorApi(error, _this);
				});
		},
		getDetailDauGiao(data, isEdit = false) {
			let _this = this;
			let id;
			if (isEdit) {
				id = data.id;
				_this.deliverId = data.id;
			} else {
				id = data.item.id;
			}
			Deliver.getDetail(this.$root.url, id)
				.then((response) => {
					if (isEdit) {
						_this.isSubmit = false;
						_this.deliverName = response.data.deliverName;
						_this.code = response.data.code;
						_this.phoneNumber = response.data.phoneNumber;
						_this.description = response.data.description;
						_this.openModal('modal-them-dau-giao', 4);
					}
				}).catch((error) => {
					_this.errorApi(error, _this);
				});
		},
		getCombo(data, isEdit = false) {
			data.listCombo = JSON.parse(localStorage.getItem('DATA_COMBO')).filter(item => item.domainId === data.nhaCai.domainId) || [];
			if (!isEdit && data.listCombo.length > 0) {
				data.comboObj = data.listCombo[0] ? data.listCombo[0] : {};
				this.changeCombo(data);
			} else if (data.listCombo.length > 0) {
				data.comboObj = data.listCombo.filter(i => i.comboId === data.comboSelected);
			}
			if (data.listCombo.length === 0) {
				this.showToast(`Nhà cái ${data.nhaCai.name} không có cách tính tiền`, 'Cách tính tiền', this, 'warning');
			}
		},
		getListSuperOfDeliver(data, index) {
			this.dataDauGiao[index].dataSuperOfDeliver = [];
			let _this = this;
			Map.getListSuperOfDeliver(this.$root.url, data)
				.then((response) => {
					_this.dataDauGiao[index].dataSuperOfDeliver = response.data;
				}).catch((error) => {
					_this.errorApi(error, _this);
				});
		},
		onInputsearchValueNhan() {
			let _this = this;
			this.dataDauNhanHienThi = this.dataDauNhan.filter(item => {
				let receiverName = item.receiverName.toLowerCase();
				let searchValueNhan = _this.searchValueNhan.toLowerCase();
				return receiverName.includes(searchValueNhan);
			});
		},
		chonDauNhan(item) {
			this.dauNhanDcChon = {
				...item,
				detail: {},
			};
			this.getDetailDauNhan();
		},
		openModal(key, mode) {
			if (this.$root.showHuongDan) {
				this.showHuongDan = true;
				this.modeHuongDan = mode;
			}
			this.$bvModal.show(key);
		},
		openThemDauNhan() {
			this.resetList();
			this.openModal("modal-them-dau-nhan", 3);
		},
		openSuaDauNhan() {
			this.formatData(true);
			this.openModal("modal-them-dau-nhan", 3);
		},
		openThemDauGiao() {
			this.deliverId = '';
			this.deliverName = '';
			this.code = '';
			this.phoneNumber = '';
			this.description = '';
			this.openModal('modal-them-dau-giao', 4)
		},
		submitThemDauNhan() {
			let _this = this;
			let param = _this.formatData();
			Receiver.post(this.$root.url, param)
				.then(() => {
					_this.getReceiver();
					_this.$bvModal.hide("modal-them-dau-nhan", 3);
					if (param.id) {
						_this.chonDauNhan(_this.dauNhanDcChon);
						_this.showToast("Chỉnh Sửa thành công!", "Chỉnh Sửa  (Cái)", _this, "success");
					} else {
						_this.showToast("Thêm thành công!", "Thêm Đầu Nhận", _this, "success");
					}
				})
				.catch((error) => {
					_this.errorApi(error, _this);
				});
		},
		submitThemDauGiao() {
			let _this = this;
			let param = {
				code: this.code,
				deliverName: this.deliverName,
				description: this.description,
				level: 0,
				parentId: '',
				phoneNumber: this.phoneNumber,
			};
			if (this.deliverId) {
				param.id = this.deliverId;
			}
			Deliver.post(this.$root.url, param)
				.then(() => {
					_this.getDeliver();
					_this.$bvModal.hide("modal-them-dau-giao");
					if (param.id) {
						_this.chonDauNhan(_this.dauNhanDcChon);
						_this.showToast("Chỉnh Sửa thành công!", "Chỉnh Sửa Đầu Giao", _this, "success");
					} else {
						_this.showToast("Thêm thành công!", "Thêm Đầu Giao", _this, "success");
					}
				})
				.catch((error) => {
					_this.errorApi(error, _this);
				});
		},
		formatData(isEdit) {
			if (isEdit) {
				let returnData = {}
				const detail = this.dauNhanDcChon.detail
				this.formDataThemDauNhan = {
					receiverId: detail.id,
					receiverName: detail.receiverName,
					phoneNumber: detail.phoneNumber,
					note: detail.note,
					listNhaCai: []
				}
				let groupComboDaThem = [];
				for (const item of detail.formulas) {
					let nhaCai = this.dataNhaCai.filter((el) => el.domainId === item.domainId)[0];
					if (groupComboDaThem.findIndex(i => i === item.groupComboDomainSort) !== -1) {
						continue;
					} else {
						groupComboDaThem.push(item.groupComboDomainSort);
					}
					let itemNhaCai = {
						nhaCai: nhaCai,
						comboSelected: item.comboId,
						comboObj: {},
						listCombo: [],
						listLoaiChoi: [],
					}
					this.getCombo(itemNhaCai, isEdit);
					for (const item1 of detail.formulas) {
						if (item1.groupComboDomainSort === item.groupComboDomainSort) {
							let itemLoaiChoi = {
								categoryId: item1.categoryId,
								categoryObj: this.dataFormulaCategory.filter(i => i.formulaCategoryId == item1.categoryId)[0],
								bids: item1.bids,
								coefficients: item1.coefficients,
								currency: item1.currency,
								currencyObj: this.dataCurrency.filter(i => i.key == item1.currency)[0],
								isGiaThauPercen: item1.isPercent,
							}
							if (item1.formularId) {
								itemLoaiChoi.formularId = item1.formularId;
							}
							itemNhaCai.listLoaiChoi.push(itemLoaiChoi);
						}

					}
					this.formDataThemDauNhan.listNhaCai.push(itemNhaCai);
				}
				return returnData;
			} else {
				let returnData = {
					receiverName: this.formDataThemDauNhan.receiverName,
					phoneNumber: this.formDataThemDauNhan.phoneNumber,
					note: this.formDataThemDauNhan.note,
					receiverDetails: [],
				};
				if (this.formDataThemDauNhan.receiverId) {
					returnData.id = this.formDataThemDauNhan.receiverId;
				}
				for (const [index, item] of this.formDataThemDauNhan.listNhaCai.entries()) {
					let receiverDetail = {
						index: index,
						domainId: item.nhaCai.domainId,
						formulas: [],
						comboId: item.comboSelected,
					};
					for (const item1 of item.listLoaiChoi) {
						let formula = {
							bids: item1.bids,
							coefficients: item1.coefficients,
							currency: item1.currency,
							currencyObj: this.dataCurrency.filter(i => i.key == item1.currency)[0],
							categoryId: item1.categoryId,
							categoryObj: this.dataFormulaCategory.filter(i => i.formulaCategoryId == item1.categoryId)[0],
							isPercent: item1.isGiaThauPercen,
						};
						if (item1.formularId) {
							formula.formularId = item1.formularId;
						}
						receiverDetail.formulas.push(formula);
					}
					returnData.receiverDetails.push(receiverDetail);
				}
				return returnData;
			}
		},
		themNhaCai() {
			this.formDataThemDauNhan.listNhaCai.push({
				nhaCai: {},
				comboSelected: '',
				comboObj: {},
				listCombo: [],
				listLoaiChoi: [
					{
						categoryId: '',
						categoryObj: {},
						bids: '',
						coefficients: 1,
						currency: 'VND',
						currencyObj: {
							key: 'VND',
							name: 'VNĐ',
						},
						isGiaThauPercen: false,
					}
				],
			});
		},
		xoaNhaCai(index) {
			this.formDataThemDauNhan.listNhaCai.splice(index, 1);
		},
		xoaDauNhan() {
			let _this = this;
			Receiver.delete(this.$root.url, this.dauNhanDcChon.id)
				.then(() => {
					_this.dauNhanDcChon = {};
					_this.getReceiver();
					_this.showToast("Xóa thành công!", "Xóa Đầu Nhận", this, "success");
					_this.$refs["popover-xoa-dau-nhan"].$emit("close");
				})
				.catch((error) => {
					_this.errorApi(error, _this);
				});
		},
		huyXoaDauNhan() {
			this.$refs['popover-xoa-dau-nhan'].$emit('close');
		},
		chinhSuaDauGiao(data) {
			this.getDetailDauGiao(data, true);
		},
		xoaDauGiao(data) {
			let _this = this;
			Deliver.delete(this.$root.url, data.id)
				.then(() => {
					_this.getDeliver();
					_this.showToast('Xóa thành công!', 'Xóa Đầu Giao', this, 'success')
					_this.$refs['popover-xoa-' + data.index].$emit('close');
				}).catch((error) => {
					_this.errorApi(error, _this);
				});
		},
		huyXoaDauGiao(index) {
			this.$refs['popover-xoa-' + index].$emit('close');
		},
		resetList() {
			this.formDataThemDauNhan = {
				receiverName: '',
				phoneNumber: '',
				note: '',
				listNhaCai: [
					{
						nhaCai: this.dataNhaCai[0],
					}
				]
			}
			this.changeDomain(this.formDataThemDauNhan.listNhaCai[0]);
		},
		changeTab(tab) {
			this.showTab = tab;
			this.showHuongDan = true;
			this.modeHuongDan = Number(tab);
			switch (tab) {
				case '1':
					this.getReceiver();
					break;
				case '2':
					this.getDeliver();
					break;
			}
		},
		checkActiveCombo() {
			let keys = Object.keys(this.listDomainDaChon);
			for (const combo of this.dataCombo) {
				for (const key of keys) {
					let index = combo.domains.findIndex(item => item.domainId === key);
					if (index !== -1 && this.listDomainDaChon[key]) {
						combo.isActive = true;
						break;
					} else {
						combo.isActive = false;
					}
				}
			}
		},
		checkDomain() {
			let keys = Object.keys(this.listDomainDaChon);
			let numberItemFalse = 0;
			this.dataLoaiChoi = [];
			this.listTagsDomainDaChon = [];
			for (const key of keys) {
				if (this.listDomainDaChon[key]) {
					// data for tag
					let domain = this.dataNhaCai.filter(item => item.domainId === key)[0];
					if (domain) {
						for (const [index, combo] of this.dataCombo.entries()) {
							let idx = combo.domains.findIndex(item => item.domainId === domain.domainId);
							if (idx !== -1) {
								domain.indexCombo = index;
								break;
							}
						}
						this.listTagsDomainDaChon.push(domain);
					}

					// data for table
					for (const fomula of this.dauNhanDcChon.detail.formulas) {
						if (fomula.domainId === key) {
							this.dataLoaiChoi.push(fomula);
						}
					}
				} else {
					numberItemFalse++;
				}
			}
			if (numberItemFalse === keys.length) {
				this.dataLoaiChoi = this.dauNhanDcChon.detail.formulas;
			}
			this.checkActiveCombo();
		},
		removeTag(data, index) {
			this.listDomainDaChon[data.domainId] = false;
			this.listTagsDomainDaChon.splice(index, 1);
			this.checkDomain();
		},
		changeDomain(data) {
			this.getCombo(data);
		},
		changeCombo(data) {
			data.listLoaiChoi = [];
			data.comboSelected = data.comboObj ? data.comboObj.comboId : '';
			let listFormulaCategory = data.listCombo.filter(item => item.comboId === data.comboSelected)[0].listFormulaCategory
			for (const item of listFormulaCategory) {
				let loaiChoi = {
					categoryId: item.formulaCategoryId,
					categoryObj: this.dataFormulaCategory.filter(i => i.formulaCategoryId == item.formulaCategoryId)[0],
					bids: '',
					coefficients: 1,
					currency: 'VND',
					currencyObj: {
						key: 'VND',
						name: 'VNĐ',
					},
				};
				data.listLoaiChoi.push(loaiChoi);
			}
		},
		getClassTheoCombo(data) {
			let indexComboActive = '';
			for (const [index, item] of this.dataCombo.entries()) {
				let indexDomain = item.domains.findIndex(i => i.domainId === data.domainId);
				if (item.isActive && indexDomain !== -1) {
					indexComboActive = index;
					break;
				}
			}
			return 'active-btn-combo-' + indexComboActive;
		},
		setRowSelected(data) {
			this.rowSelected = this.cloneDeep(data);
		},
		onFiltered(filteredItems) {
			this.totalRows = filteredItems.length;
			this.currentPage = 1;
		},
		changeBids(event, item) {
			let text = event.clipboardData.getData('text');
			if (text.includes('%')) {
				item.isGiaThauPercen = true;
			}
		},
		rowClicked(item) {
			for (const item1 of this.dataDauGiao) {
				if (item.id !== item1.id) {
					item1._showDetails = false;
				}
			}
			if (item._showDetails) {
				item._showDetails = false;
			} else {
				item._showDetails = true;
				this.getListSuperOfDeliver(item, item.index - 1);
			}
		},
		onClickHuongDan(e) {
			this.showHuongDan = e;
		}
	},
};
</script>

<style lang="scss">
.style-for-web .nhan-giao-page {
	.page-dau-nhan {
		display: flex;
		width: 100%;
		background: var(--menu-bar-background);
		border-radius: 8px;
		box-shadow: 0px 2px 6px var(--active-tab-box-shadow);
	}

	.list-dau-nhan {
		width: 200px;
		padding: 20px 0px 0px 5px;
		margin-bottom: 1rem;

		.item-dau-nhan {
			position: relative;
			height: 60px;
		}

		.btn {
			justify-content: flex-start;
			width: 100%;
			align-items: center;
			text-align: left;
			border-top-right-radius: 0px;
			border-bottom-right-radius: 0px;
			box-shadow: none;
			transition: none;

			&.btn-info {
				position: absolute;
				width: 100%;
				color: var(--text-color);
				background-color: var(--btn-page-dau-nhan-back-color);
				border: 3px solid var(--btn-page-dau-nhan-border-color);
				border-right: none;

				&::after {
					content: " ";
					background-color: var(--btn-page-dau-nhan-back-color);
					width: 10px;
					height: 100%;
					position: absolute;
					right: -5px;
					cursor: default;
				}
			}

			img {
				filter: var(--img-filter-icon-menu) !important;
			}
		}

		p {
			margin: 0;
		}
	}

	.thong-tin-dau-nhan {
		width: calc(100% - 200px);
		padding: 10px;
		margin: 10px 0px;
		background-color: var(--btn-page-dau-nhan-back-color);
		border: 3px solid var(--btn-page-dau-nhan-border-color);
		border-radius: 8px;
	}

	.info-dau-nhan {
		background: var(--common-background);
		color: var(--text-color);
		border-radius: 8px;
		box-shadow: 0px 2px 6px var(--active-tab-box-shadow);

		.title-info-dau-nhan {
			font-style: normal;
			font-weight: 700;
			font-size: 20px;
		}

		.info-value {
			font-style: normal;
			font-weight: 700;
			margin-left: 10px;
		}

		.btn {
			width: 100%;

			img {
				filter: var(--img-filter) !important;
			}
		}

		.value-info-dau-nhan {
			display: flex;

			label {
				margin: 0;

				&:first-child {
					width: 130px;
					padding: 0 10px;
					margin-bottom: 5px;
					border-top-left-radius: 8px;
					border-bottom-left-radius: 8px;
					border: 1px solid var(--btn-page-dau-nhan-back-color);
					background: var(--btn-page-dau-nhan-back-color);
				}

				&:last-child {
					width: calc(80% - 130px);
					padding: 0 10px;
					margin-bottom: 5px;
					border-top-right-radius: 8px;
					border-bottom-right-radius: 8px;
					border: 1px solid var(--btn-page-dau-nhan-back-color);
					// background: var(--btn-page-dau-nhan-back-color);
				}
			}
		}
	}

	.filter-dau-nhan {
		background-color: transparent;
		border-radius: 8px;

		.dropdown-dau-nhan {
			width: 100%;

			&.active-btn-combo-0 {
				.dropdown-toggle {
					background: var(--btn-warning-color) !important;
					;

					.label-type {
						color: var(--black-color) !important;
					}

					.icon-arrow {
						color: var(--black-color) !important;
					}
				}
			}

			&.active-btn-combo-1 {
				.dropdown-toggle {
					background: var(--btn-danger-color) !important;
					;

					.label-type {
						color: var(--white-color) !important;
					}

					.icon-arrow {
						color: var(--white-color) !important;
					}
				}
			}

			&.active-btn-combo-2 {
				.dropdown-toggle {
					background: var(--btn-success-color) !important;
					;

					.label-type {
						color: var(--white-color) !important;
					}

					.icon-arrow {
						color: var(--white-color) !important;
					}
				}
			}

			&.active-btn-combo-3 {
				.dropdown-toggle {
					background: var(--btn-primary-color) !important;
					;

					.label-type {
						color: var(--white-color) !important;
					}

					.icon-arrow {
						color: var(--white-color) !important;
					}
				}
			}

			.custom-control-label {
				width: 100%;
			}

			.dropdown-toggle {
				display: flex;
				justify-content: space-between;
				background: var(--menu-bar-background);
				box-shadow: 0px 2px 6px var(--active-tab-box-shadow);
			}

			.dropdown-menu {
				width: 100%;
			}

			.label-type {
				margin: auto;
			}

			.icon-arrow {
				font-size: 10px;
				transition: all 0.3s;
			}

			&.show {
				.dropdown-toggle {
					background: var(--active-setting-back);
				}

				.label-type {
					font-weight: bold;
					color: var(--text-color);
				}

				.icon-arrow {
					transform: rotate(180deg);
					color: var(--text-color);
				}
			}
		}

		.tags-domain {
			display: flex;
			flex-wrap: wrap;

			.btn {
				box-shadow: 0px 2px 6px var(--active-tab-box-shadow);
				height: 30px;
				font-size: 12px;
				white-space: nowrap;

				&:hover {
					&.active-btn-combo-0 {
						background: var(--btn-warning-color-hover);
					}

					&.active-btn-combo-1 {
						background: var(--btn-danger-color-hover);
					}

					&.active-btn-combo-2 {
						background: var(--btn-success-color-hover);
					}

					&.active-btn-combo-3 {
						background: var(--btn-primary-color-hover);
					}
				}
			}

			.active-btn-combo-0 {
				background: var(--btn-warning-color);
				color: var(--black-color) !important;
			}

			.active-btn-combo-1 {
				background: var(--btn-danger-color);
				color: var(--white-color) !important;
			}

			.active-btn-combo-2 {
				background: var(--btn-success-color);
				color: var(--white-color) !important;
			}

			.active-btn-combo-3 {
				background: var(--btn-primary-color);
				color: var(--white-color) !important;
			}
		}
	}

	.chi-tiet-dau-nhan {
		background: var(--common-background);
		border-radius: 8px;
		box-shadow: 0px 2px 6px var(--active-tab-box-shadow);

		.active-btn-combo-0 {
			background: var(--btn-warning-color);
			color: var(--black-color) !important;
			padding: 3px 10px;
			border-radius: 0.25rem;
		}

		.active-btn-combo-1 {
			background: var(--btn-danger-color);
			color: var(--white-color) !important;
			padding: 3px 10px;
			border-radius: 0.25rem;
		}

		.active-btn-combo-2 {
			background: var(--btn-success-color);
			color: var(--white-color) !important;
			padding: 3px 10px;
			border-radius: 0.25rem;
		}

		.active-btn-combo-3 {
			background: var(--btn-primary-color);
			color: var(--white-color) !important;
			padding: 3px 10px;
			border-radius: 0.25rem;
		}

		.b-table-sticky-header {
			max-height: 48vh;
		}
	}


	.table-dau-giao {
		background: var(--common-background);
		border-radius: 8px;
		box-shadow: 0px 2px 6px var(--active-tab-box-shadow);

		.active-btn-combo-0 {
			background: var(--btn-warning-color);
			color: var(--black-color) !important;
			padding: 3px 10px;
			border-radius: 0.25rem;
		}

		.active-btn-combo-1 {
			background: var(--btn-danger-color);
			color: var(--white-color) !important;
			padding: 3px 10px;
			border-radius: 0.25rem;
		}

		.active-btn-combo-2 {
			background: var(--btn-success-color);
			color: var(--white-color) !important;
			padding: 3px 10px;
			border-radius: 0.25rem;
		}

		.active-btn-combo-3 {
			background: var(--btn-primary-color);
			color: var(--white-color) !important;
			padding: 3px 10px;
			border-radius: 0.25rem;
		}
	}
}

#modal-them-dau-nhan .style-for-web {
	.items-nha-cai {
		background-color: var(--items-nha-cai-back-color);
		border: 1px solid var(--modal-input-border);
		border-top: none;
		border-radius: 8px;
	}

	.gia-thau {
		display: flex;
	}

	.select-nha-cai {
		display: flex;
	}
}
</style>
