import ApiService from "./api.service";

const System = {
    getNotification: async (url) => {
        url = url + `/system/get-maintenance-notice`;
        return ApiService.getWithNoToken(url);
    },
};

export default System;
