import ApiService from "./api.service";

const Map = {
    getSuperStatus: async (url, isConnect) => {
        url = url + `/map/super-status-info?isConnect=${isConnect}`;
        return ApiService.get(url);
    },
    getMapSuperDeliverFormula: async (url, data) => {
        url = url + `/map/get-map-super-deliver-formula`;
        return ApiService.get(url, data);
    },
    insertMapSuperDeliverFormula: async (url, data) => {
        url = url + `/map/insert-map-super-deliver-formula`;
        return ApiService.post(url, data);
    },
    updateMapSuperDeliverFormula: async (url, data) => {
        url = url + `/map/update-map-super-deliver-formula`;
        return ApiService.post(url, data);
    },
    deleteMapSuperDeliverFormula: async (url, data) => {
        url = url + `/map/delete-map-super-deliver-formula?deliverId=${data.deliverId}&superId=${data.superId}`;
        return ApiService.delete(url);
    },
    getListSuperOfDeliver: async (url, data) => {
        url = url + `/map/list-super-of-deliver?deliverId=${data.id}`;
        return ApiService.get(url);
    },
};

export default Map;
