import ApiService from "./api.service";

const Super = {
    getAll: async (url) => {
        url = url + "/super/list-details";
        return ApiService.get(url);
    },
    getChildSuper: async (url, id) => {
        url = url + "/super/list-child-super";
        return ApiService.get(url, {id});
    },
    // reloadChildSuper: async (url, id) => {
    //     url = url + "/super/reload-child";
    //     return ApiService.get(url, {id});
    // },
    insert: async (url, data) => {
        url = url + `/super/insert`;
        return ApiService.post(url, data);
    },
    update: async (url, data) => {
        url = url + `/super/update`;
        return ApiService.post(url, data);
    },
    delete: async (url, id) => {
        url = url + `/super/delete?ids=${id}`;
        return ApiService.delete(url);
    },
    getDetail: async (url, id) => {
        url = url + `/super/detail?id=${id}`;
        return ApiService.get(url);
    },
    getSuperStatus: async (url, isConnect) => {
        url = url + `/map/super-status-info?isConnect=${isConnect}`;
        return ApiService.get(url);
    },
};

export default Super;
