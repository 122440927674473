import ApiService from "./api.service";

const Receiver = {
    getAll: async (url) => {
        url = url + "/receiver/list-details";
        return ApiService.get(url);
    },
    post: async (url, data) => {
        url = url + `/receiver/insert-or-update`;
        return ApiService.post(url, data);
    },
    delete: async (url, id) => {
        url = url + `/receiver/delete?ids=${id}`;
        return ApiService.delete(url);
    },
    getDetail: async (url, id) => {
        url = url + `/receiver/detail?id=${id}`;
        return ApiService.get(url);
    },
};

export default Receiver;
