<template>
	<div id="huong-dan-luu-super-page" class="huong-dan-luu-super-page" @click="onClick()">
		<div v-for="(item, index) in dataHuongDan" :key="index" :id="`text-${index + 1}`" class="text-huong-dan">
			<div v-html="item.text"></div>
			<div :class="item.arrow" class="arrow"></div>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
	name: "HuongDanLuuSuperPage",
	props: [
		'mode',
	],
	data() {
		return {
			dataHuongDan: [
				{
					mode: 1,
					text: 'NHẤN ĐỂ XEM TÀI KHOẢN CON',
					arrow: 'arrow-bottom'
				},
				{
					mode: 1,
					text: 'THÊM HOẶC CHỈNH SỬA LƯU GIÁ TÀI KHOẢN',
					arrow: 'arrow-bottom'
				},
				{
					mode: 1,
					text: 'CHỈNH SỬA TÀI KHOẢN',
					arrow: 'arrow-top-right'
				},
				{
					mode: 1,
					text: 'XÓA TÀI KHOẢN',
					arrow: 'arrow-top-left'
				},
				// modal thêm tài khoản
				{
					mode: 2,
					text: 'Bước 1: CHỌN ĐẦU NHẬN',
					arrow: 'arrow-right'
				},
				{
					mode: 2,
					text: 'Bước 2: CHỌN NHÀ CÁI',
					arrow: 'arrow-left'
				},
				{
					mode: 2,
					text: 'Bước 3: NHẬP TÊN ĐĂNG NHẬP<br>(Của nhà cái chọn ở Bước 2)',
					arrow: 'arrow-right'
				},
				{
					mode: 2,
					text: 'Bước 4: NHẬP MẬT KHẨU<br>(Của nhà cái chọn ở Bước 2)',
					arrow: 'arrow-left'
				},
				{
					mode: 2,
					text: 'Bước 5: CHỌN CÁCH TÍNH TIỀN',
					arrow: 'arrow-right'
				},
				{
					mode: 2,
					text: 'Bước 6: THÊM HOẶC LƯU TÀI KHOẢN',
					arrow: 'arrow-top'
				},
				// modal lưu giá tài khoản
				{
					mode: 2,
					text: 'Bước 1: CHỌN TÀI KHOẢN',
					arrow: 'arrow-right'
				},
				{
					mode: 2,
					text: 'Bước 2: CHỌN ĐẦU GIAO',
					arrow: 'arrow-left'
				},
				{
					mode: 2,
					text: 'Bước 3: CHỌN CÁCH TÍNH TIỀN',
					arrow: 'arrow-left'
				},
				{
					mode: 2,
					text: 'Bước 4: CHỌN VÀ NHẬP<br>(Loại Tiền, Hoa Hồng, Giá Thầu)',
					arrow: 'arrow-right'
				},
				{
					mode: 2,
					text: 'Bước 5: THÊM HOẶC LƯU GIÁ TÀI KHOẢN',
					arrow: 'arrow-top'
				},
			]
		};
	},
	computed: {
		...mapState("HuongDanLuuSuperPage", []),
	},
	beforeCreate() {
		console.log("I have not been created yet!");
	},
	created() {
		console.log("I have just been created!");
	},
	beforeMount() {
		console.log("I am about to be mounted to the DOM!");
	},
	mounted() {
		console.log("I am mounted to the DOM!");
		this.forceMovePage();
		this.setPosition();
	},
	beforeDestroy() {
		console.log("I am about to be removed from the DOM!");
	},
	destroyed() {
		console.log("I no longer exist...");
	},
	methods: {
		...mapActions("HuongDanLuuSuperPage", []),
		onClick() {
			this.$emit('onClick', false);
		},
		setPosition() {
			let _this = this;
			setTimeout(() => {
				switch (this.mode) {
					case 1:
						_this.setPosition1();
						break;
					case 2:
						_this.setPosition2();
						break;
					case 3:
						_this.setPosition3();
						break;
					case 4:
					case 5:
						_this.onClick();
						break;
				}
			}, 1000);
		},
		setPosition1() {
			let domImgs = document.getElementById('luu-super-page').getElementsByTagName('img');
			let domLink = document.getElementById('luu-super-page').getElementsByClassName('btn-link color-level-1 padding-level-1')[0];
			let textHuongDan1;
			let textHuongDan2;
			let textHuongDan3;
			let textHuongDan4;
			let top = 0;
			let left = 0;
			if (domLink) {
				textHuongDan1 = document.getElementById('text-1');
				top = domLink.getBoundingClientRect().top - 60;
				left = domLink.getBoundingClientRect().left - (textHuongDan1.clientWidth / 2);
				textHuongDan1.style.top = `${top}px`;
				textHuongDan1.style.left = `${left}px`;
				textHuongDan1.style.opacity = 1;
			}
			for (const ele of domImgs) {
				console.log(ele.title);
				switch (ele.title) {
					case 'Lưu giá tài khoản':
						if (!textHuongDan2) {
							textHuongDan2 = document.getElementById('text-2');
							top = ele.getBoundingClientRect().top - 70;
							left = ele.getBoundingClientRect().left - (textHuongDan2.clientWidth / 2);
							textHuongDan2.style.top = `${top}px`;
							textHuongDan2.style.left = `${left}px`;
							textHuongDan2.style.opacity = 1;
						}
						break;
					case 'Chỉnh sửa tài khoản':
						if (!textHuongDan3) {
							textHuongDan3 = document.getElementById('text-3');
							top = ele.getBoundingClientRect().top + 40;
							left = ele.getBoundingClientRect().left - textHuongDan3.offsetWidth + ele.getBoundingClientRect().width + 8;
							textHuongDan3.style.top = `${top}px`;
							textHuongDan3.style.left = `${left}px`;
							textHuongDan3.style.opacity = 1;
						}
						break;
					case 'Xóa tài khoản':
						if (!textHuongDan4) {
							textHuongDan4 = document.getElementById('text-4');
							top = ele.getBoundingClientRect().top + 40;
							left = ele.getBoundingClientRect().left - 8;
							textHuongDan4.style.top = `${top}px`;
							textHuongDan4.style.left = `${left}px`;
							textHuongDan4.style.opacity = 1;
						}
						break;
				}
			}
		},
		setPosition2() {
			let domModal = document.getElementById('modal-them-super___BV_modal_content_');
			let domText = domModal.getElementsByClassName('text-left');
			let domTable = domModal.getElementsByClassName('table-luu-super')[0];
			let domBtn = domModal.getElementsByClassName('btn btn-primary btn-block')[0];
			let textHuongDan5;
			let textHuongDan6;
			let textHuongDan7;
			let textHuongDan8;
			let textHuongDan9;
			let textHuongDan10;
			let top = 0;
			let left = 0;
			for (const ele of domText) {
				console.log(ele.innerText);
				switch (ele.innerText) {
					case 'Tên Đầu Nhận':
						if (!textHuongDan5) {
							textHuongDan5 = document.getElementById('text-5');
							top = ele.getBoundingClientRect().top;
							left = ele.getBoundingClientRect().left - textHuongDan5.offsetWidth;
							textHuongDan5.style.top = `${top}px`;
							textHuongDan5.style.left = `calc(${left}px - 0.5rem)`;
							textHuongDan5.style.opacity = 1;
						}
						break;
					case 'Nhà Cái':
						if (!textHuongDan6) {
							textHuongDan6 = document.getElementById('text-6');
							top = ele.getBoundingClientRect().top;
							left = ele.getBoundingClientRect().left + ele.getBoundingClientRect().width;
							textHuongDan6.style.top = `${top}px`;
							textHuongDan6.style.left = `calc(${left}px + 0.5rem)`;
							textHuongDan6.style.opacity = 1;
						}
						break;
					case 'Tên Đăng Nhập':
						if (!textHuongDan7) {
							textHuongDan7 = document.getElementById('text-7');
							top = ele.getBoundingClientRect().top;
							left = ele.getBoundingClientRect().left - textHuongDan7.offsetWidth;
							textHuongDan7.style.top = `${top}px`;
							textHuongDan7.style.left = `calc(${left}px - 0.5rem)`;
							textHuongDan7.style.opacity = 1;
						}
						break;
					case 'Mật Khẩu':
						if (!textHuongDan8) {
							textHuongDan8 = document.getElementById('text-8');
							top = ele.getBoundingClientRect().top;
							left = ele.getBoundingClientRect().left + ele.getBoundingClientRect().width;
							textHuongDan8.style.top = `${top}px`;
							textHuongDan8.style.left = `calc(${left}px + 0.5rem)`;
							textHuongDan8.style.opacity = 1;
						}
						break;
				}
			}
			if (!textHuongDan9 && domTable) {
				textHuongDan9 = document.getElementById('text-9');
				top = domTable.getBoundingClientRect().top;
				left = domTable.getBoundingClientRect().left - textHuongDan9.offsetWidth;
				textHuongDan9.style.top = `${top}px`;
				textHuongDan9.style.left = `calc(${left}px - 0.5rem)`;
				textHuongDan9.style.opacity = 1;
			}
			if (!textHuongDan10 && domBtn) {
				textHuongDan10 = document.getElementById('text-10');
				top = domBtn.getBoundingClientRect().top + 50;
				left = domBtn.getBoundingClientRect().left - (textHuongDan10.clientWidth / 2);
				textHuongDan10.style.top = `${top}px`;
				textHuongDan10.style.left = `calc(${left}px + 4rem)`;
				textHuongDan10.style.opacity = 1;
			}
		},
		setPosition3() {
			let domModal = document.getElementById('modal-ket-noi-super___BV_modal_content_');
			let domText = domModal.getElementsByClassName('text-left');
			let domBtnLuu = domModal.getElementsByClassName('btn btn-primary btn-block')[0];
			let textHuongDan11;
			let textHuongDan12;
			let textHuongDan13;
			let textHuongDan14;
			let textHuongDan15;
			let top = 0;
			let left = 0;
			for (const ele of domText) {
				switch (ele.innerText) {
					case 'Tài Khoản':
						if (!textHuongDan11) {
							textHuongDan11 = document.getElementById('text-11');
							top = ele.getBoundingClientRect().top;
							left = ele.getBoundingClientRect().left - textHuongDan11.offsetWidth;
							textHuongDan11.style.top = `${top}px`;
							textHuongDan11.style.left = `calc(${left}px - 0.5rem)`;
							textHuongDan11.style.opacity = 1;
						}
						break;
					case 'Đầu Giao':
						if (!textHuongDan12) {
							textHuongDan12 = document.getElementById('text-12');
							top = ele.getBoundingClientRect().top;
							left = ele.getBoundingClientRect().left + ele.getBoundingClientRect().width;
							textHuongDan12.style.top = `${top}px`;
							textHuongDan12.style.left = `calc(${left}px + 0.5rem)`;
							textHuongDan12.style.opacity = 1;
						}
						break;
					case 'Chọn cách tính tiền':
						if (!textHuongDan13) {
							textHuongDan13 = document.getElementById('text-13');
							top = ele.getBoundingClientRect().top;
							left = ele.getBoundingClientRect().left + ele.getBoundingClientRect().width;
							textHuongDan13.style.top = `${top}px`;
							textHuongDan13.style.left = `calc(${left}px + 0.5rem)`;
							textHuongDan13.style.opacity = 1;
						}
						break;
					case 'Loại Chơi':
						if (!textHuongDan14) {
							textHuongDan14 = document.getElementById('text-14');
							top = ele.getBoundingClientRect().top;
							left = ele.getBoundingClientRect().left - textHuongDan14.offsetWidth;
							textHuongDan14.style.top = `${top}px`;
							textHuongDan14.style.left = `calc(${left}px - 0.5rem)`;
							textHuongDan14.style.opacity = 1;
						}
						break;
				}
			}

			if (!textHuongDan15 && domBtnLuu) {
				textHuongDan15 = document.getElementById('text-15');
				top = domBtnLuu.getBoundingClientRect().top + 50;
				left = domBtnLuu.getBoundingClientRect().left - (textHuongDan15.clientWidth / 2);
				textHuongDan15.style.top = `${top}px`;
				textHuongDan15.style.left = `calc(${left}px + 4rem)`;
				textHuongDan15.style.opacity = 1;
			}
		},
	},
};
</script>

<style lang="scss">
.style-for-web .huong-dan-luu-super-page {
	position: absolute;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	z-index: 9999;
	// display: contents;

	.text-huong-dan {
		position: absolute;
		white-space: nowrap;
		font-weight: bold;
		font-size: 20px;
		background-color: var(--huong-dan-background);
		padding: 10px;
		border: 2px solid var(--huong-dan-border);
		border-radius: 8px;
		opacity: 0;
		transition: all 0.3s;
		z-index: 9999;

		.arrow {
			position: absolute;
			display: block;
			width: 1rem;
			height: 0.5rem;
			margin: 0 0.3rem;

			&::before,
			&::after {
				position: absolute;
				display: block;
				content: "";
				border-color: transparent;
				border-style: solid;
			}
		}

		.arrow-bottom {
			left: 50%;
			bottom: 0;

			&::before,
			&::after {
				transform: rotate(45deg);
				border-bottom-color: var(--huong-dan-border) !important;
				border-width: 0 0 1rem 1rem;
			}
		}

		.arrow-top {
			left: 50%;
			top: -0.5rem;

			&::before,
			&::after {
				transform: rotate(45deg);
				border-top-color: var(--huong-dan-border) !important;
				border-width: 1rem 1rem 0rem 0rem;
			}
		}

		.arrow-top-left {
			left: 1rem;
			top: -0.5rem;

			&::before,
			&::after {
				transform: rotate(45deg);
				border-top-color: var(--huong-dan-border) !important;
				border-width: 1rem 1rem 0rem 0rem;
			}
		}

		.arrow-top-right {
			right: 1rem;
			top: -0.5rem;

			&::before,
			&::after {
				transform: rotate(45deg);
				border-top-color: var(--huong-dan-border) !important;
				border-width: 1rem 1rem 0rem 0rem;
			}
		}

		.arrow-right {
			top: 50%;
			transform: translateY(-50%);
			right: -0.8rem;

			&::before,
			&::after {
				transform: rotate(-45deg);
				border-bottom-color: var(--huong-dan-border) !important;
				border-width: 0 0 1rem 1rem;
			}
		}

		.arrow-left {
			top: 50%;
			transform: translateY(-50%);
			left: -0.8rem;

			&::before,
			&::after {
				transform: rotate(135deg);
				border-bottom-color: var(--huong-dan-border) !important;
				border-width: 0 0 1rem 1rem;
			}
		}
	}
}
</style>
