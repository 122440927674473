<template>
	<div id="luu-super-page" class="luu-super-page">
		<b-row class="header-page mb-2">
			<b-col cols="6">
				<span class="title-page">
					DS TÀI KHOẢN
				</span>
			</b-col>
			<b-col cols="6" class="text-right button-action">
				<b-button variant="primary" @click="openThemSuper" class="mr-2">
					<img :src="getIcon('add-circle')" />
				</b-button>
			</b-col>
		</b-row>
		<b-row class="header-page mb-2">
			<b-col cols="6">
				<b-input-group class="input-search">
					<b-input-group-prepend is-text>
						<img :src="getIcon('search-normal')" class="pr-2" />
					</b-input-group-prepend>
					<b-form-input ref="input-search" type="search" placeholder="Tìm kiếm"
						v-model="searchValue"></b-form-input>
				</b-input-group>
			</b-col>
		</b-row>
		<b-row>
			<b-col cols="12">
				<div class="table-luu-super">
					<b-table sticky-header hover :head-variant="$root.theme" small show-empty emptyText="Không có dữ liệu"
						empty-filtered-text="Không có dữ liệu phù hợp tìm kiếm" :fields="fields" :items="dataSuper"
						:current-page="currentPage" :per-page="perPage" :filter="searchValue" @filtered="onFiltered"
						@row-clicked="onRowClicked">
						<template #empty="scope">
							<span>{{ scope.emptyText }}</span>
						</template>
						<template #cell(index)="data">
							<span>{{ data.item.index }}</span>
						</template>
						<template #cell(userName)="data">
							<div v-if="data.item.level !== 4" :class="getClassLevel(data.item)"
								@click="toggleChildSuper(data.item)">
								<span>{{ data.item.userName }}</span>
								<img :class="{ 'rotate': data.item.showDongCon }" :src="getIcon('arrow-down')"
									class="ml-1" />
							</div>
							<div v-else :class="getClassLevel(data.item)">
								<span>{{ data.item.userName }}</span>
							</div>
						</template>
						<template #cell(level)="data">
							<div class="label-name" :class="'cap-bat-' + data.item.level">
								<span v-if="data.item.level === 1">Super</span>
								<span v-if="data.item.level === 2">Master</span>
								<span v-if="data.item.level === 3">Agent</span>
								<span v-if="data.item.level === 4">Member</span>
							</div>
						</template>
						<template #cell(isActive)="data">
							<div class="label-name" :class="data.item.isActive ? 'dang-hoat-dong' : 'ngung-hoat-dong'">
								<span>{{ data.item.isActive ? 'Đang Hoạt Động' : 'Ngừng Hoạt Động' }}</span>
							</div>
						</template>
						<template #cell(action)="data">
							<div class="text-left button-action">
								<b-button variant="primary" class="mr-2"
									:class="data.item.isShowSetting ? 'active-setting' : ''"
									@click="showSetting(data.item)">
									<img :src="getIcon('more')" />
								</b-button>
								<div class="group-button-action pl-2 pr-2" v-show="data.item.isShowSetting">
									<div @click="ketNoiSuper(data.item)">
										<b-button size="sm" :id="'btn-chinh-sua-' + data.item.index" class="btn-luu-gia">
											<img :src="getIcon('receipt-edit')" />
										</b-button>
										<span class="ml-1">Lưu giá tài khoản</span>
									</div>
									<div @click="chinhSuaSuper(data.item)">
										<b-button size="sm" :id="'btn-chinh-sua-' + data.item.index" variant="primary">
											<img :src="getIcon('edit-2')" />
										</b-button>
										<span class="ml-1">Chỉnh Sửa</span>
									</div>
									<div @click="setRowSelected(data.item)">
										<b-button size="sm" :id="'btn-xoa-giao-' + data.item.index" variant="danger">
											<img :src="getIcon('trash')" />
										</b-button>
										<span class="ml-1">Xóa</span>
									</div>
									<div @click="reloadChild(data.item)">
										<b-button v-if="!data.item.parentId" size="sm"
											:id="'btn-reload-con-' + data.item.index" variant="primary">
											<img :src="getIcon('refresh-2')" />
										</b-button>
										<span class="ml-1">Tải lại tài khoản con</span>
									</div>
								</div>
							</div>
						</template>
					</b-table>
					<b-pagination v-if="dataSuper.length > 0" v-model="currentPage" pills :total-rows="totalRows"
						:per-page="perPage" align="center">
						<template #first-text>
							<b-icon icon="chevron-bar-left"></b-icon>
						</template>
						<template #prev-text>
							<b-icon icon="chevron-left"></b-icon>
						</template>
						<template #next-text>
							<b-icon icon="chevron-right"></b-icon>
						</template>
						<template #last-text>
							<b-icon icon="chevron-bar-right"></b-icon>
						</template>
					</b-pagination>
				</div>
			</b-col>
		</b-row>

		<b-modal centered id="modal-them-super" :title="isEditSuper ? 'CHỈNH SỬA TÀI KHOẢN' : 'THÊM TÀI KHOẢN'" scrollable
			size="lg" content-class="style-for-mobile">
			<div class="d-block">
				<b-row>
					<b-col cols="6">
						<div class="text-left">Tên Đầu Nhận</div>
						<b-input-group class="mb-3">
							<multi-select v-model="receiverObj" :options="dataReceiver" label="receiverName" track-by="id"
								class="multi-select mr-2" placeholder="" :allow-empty="false" @input="changeReceiver()">
							</multi-select>
						</b-input-group>
					</b-col>
					<b-col cols="6">
						<div class="text-left">Nhà Cái</div>
						<b-input-group class="mb-3">
							<multi-select v-model="domainObj" :options="dataDomain" label="domainName" track-by="domainId"
								class="multi-select mr-2" placeholder="" :allow-empty="false" @input="changeDomain()">
							</multi-select>
						</b-input-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col cols="6">
						<div class="text-left">Tên Đăng Nhập</div>
						<b-input-group class="mb-3">
							<b-form-input v-model="logId">
							</b-form-input>
						</b-input-group>
					</b-col>
					<b-col cols="6">
						<div class="text-left">Mật Khẩu</div>
						<b-input-group class="mb-3">
							<b-form-input v-model="passWord">
							</b-form-input>
						</b-input-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col cols="12">
						<div class="text-left">Mật Khẩu Code</div>
						<b-input-group class="mb-3">
							<b-form-input v-model="passCode">
							</b-form-input>
						</b-input-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col cols="4">
						<div class="text-left">Tên Tài Khoản</div>
						<b-input-group class="mb-3">
							<b-form-input v-model="userName" readonly>
							</b-form-input>
						</b-input-group>
					</b-col>
					<b-col cols="4">
						<div class="text-left">Cấp Bậc</div>
						<b-input-group class="mb-3">
							<b-form-input value="Super" readonly>
							</b-form-input>
						</b-input-group>
					</b-col>
					<b-col cols="4">
						<div class="text-left">Trạng Thái</div>
						<b-input-group class="mb-3">
							<b-form-input value="Đang Hoạt Động" readonly>
							</b-form-input>
						</b-input-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col cols="12">
						<div class="table-luu-super">
							<b-row class="header-list-formula">
								<b-col v-for="(field, index) in fieldsFormula" :key="index" :cols="field.cols">
									{{ field.label }}
								</b-col>
							</b-row>
							<b-row v-for="(formula, index) in dataFormula" :key="index" class="body-list-formula"
								:class="{ 'has-radio': (formula.showRadio && index !== 0) }">
								<b-col cols="4">
									{{ formula.categoryName }}
								</b-col>
								<b-col cols="2">
									{{ formula.coefficients }}
								</b-col>
								<b-col cols="2">
									{{ tinhBids(formula) }}
								</b-col>
								<b-col cols="2">
									{{ formula.currency }}
								</b-col>
								<b-col cols="2">
									<div v-if="formula.showRadio">
										<b-form-radio v-model="groupComboDomainSort" name="formula-radio"
											:value="formula.groupComboDomainSort">
										</b-form-radio>
									</div>
								</b-col>
							</b-row>
						</div>
					</b-col>
				</b-row>
			</div>
			<template #modal-footer>
				<b-row class="w-100">
					<b-col cols="12">
						<b-form-checkbox v-if="!isEditSuper" id="checkbox-1" v-model="isThemTaiKhoanKhac">
							Thêm tài khoản khác
						</b-form-checkbox>
					</b-col>
				</b-row>
				<b-row class="w-100 mt-3">
					<b-col offset="4" cols="4">
						<b-button block @click="onClickHuy('modal-them-super')">
							Hủy
						</b-button>
					</b-col>
					<b-col cols="4">
						<b-button variant="primary" block :disabled="checkDisableBtn()" @click="themSuper">
							{{ isEditSuper ? 'Lưu' : 'Thêm' }}
						</b-button>
					</b-col>
				</b-row>
			</template>
		</b-modal>

		<b-modal centered id="modal-ket-noi-super"
			:title="isEditKetNoiSuper ? 'CHỈNH SỬA LƯU GIÁ TÀI KHOẢN' : 'THÊM LƯU GIÁ TÀI KHOẢN'" scrollable size="lg"
			content-class="style-for-mobile">
			<div class="d-block">
				<b-row class="mb-3">
					<b-col cols="6">
						<div class="text-left">Tài Khoản</div>
						<b-input-group>
							<b-form-input :value="superSelected.superName" readonly></b-form-input>
						</b-input-group>
					</b-col>
					<b-col cols="6">
						<div class="text-left">Nhà Cái</div>
						<b-input-group>
							<b-form-input :value="superSelected.domainName" readonly></b-form-input>
						</b-input-group>
					</b-col>
				</b-row>
				<b-row class="mb-3">
					<b-col cols="12">
						<div class="text-left">Đầu Nhận</div>
						<b-input-group>
							<b-form-input :value="superSelected.receiverName" readonly></b-form-input>
						</b-input-group>
					</b-col>
				</b-row>
				<b-row class="mb-3">
					<b-col cols="12">
						<div class="text-left group-label-dau-giao">
							Đầu Giao
							<img :src="getIcon('add-circle')" class="btn-them-dau-giao ml-1" v-b-tooltip.hover
								title="Thêm Đầu Giao" @click="openThemDauGiao" />
						</div>
						<multi-select v-model="dauGiaoSelected" :options="dataDauGiaoChuaChon" label="deliverName"
							track-by="id" class="multi-select mr-2" placeholder="" :allow-empty="false"
							@input="changeDauKhach()">
						</multi-select>
					</b-col>
				</b-row>
				<b-row>
					<b-col cols="12">
						<div class="text-left">Chọn cách tính tiền</div>
						<b-input-group>
							<multi-select v-model="comboObj" :options="dataCombo" :allow-empty="false" label="comboName"
								track-by="comboId" :preselect-first="true" class="multi-select" placeholder=""
								@input="changeCombo()">
							</multi-select>
						</b-input-group>
					</b-col>
				</b-row>
				<div v-if="listFormulaCategory && listFormulaCategory.length > 0" class="items-nha-cai">
					<b-row class="mb-3 p-2" v-for="(item, index) in listFormulaCategory" :key="index">
						<b-col cols="6">
							<div class="text-left">Loại Chơi</div>
							<b-input-group>
								<multi-select v-model="item.formulaCategory" :options="dataFormulaCategory"
									:allow-empty="false" label="shortname" track-by="formulaCategoryId"
									:preselect-first="true" class="multi-select" placeholder="" :disabled="true"
									@input="item.formulaCategoryId = item.formulaCategory.formulaCategoryId">
								</multi-select>
							</b-input-group>
						</b-col>
						<b-col cols="6">
							<div class="text-left">Loại Tiền</div>
							<b-input-group>
								<multi-select v-model="item.currencyObj" :options="dataCurrency" :allow-empty="false"
									label="name" track-by="key" :preselect-first="true" class="multi-select" placeholder=""
									@input="item.currency = item.currencyObj.key">
								</multi-select>
							</b-input-group>
						</b-col>
						<b-col cols="6">
							<div class="text-left">Hoa Hồng</div>
							<b-input-group>
								<b-form-input type="number" v-model="item.coefficients">
								</b-form-input>
							</b-input-group>
						</b-col>
						<b-col cols="6">
							<div class="text-left gia-thau">
								Giá Thầu
								<b-form-checkbox class="ml-2" v-model="item.isGiaThauPercen">
									%
								</b-form-checkbox>
							</div>
							<b-input-group>
								<b-form-input type="number" v-model="item.bids" @paste="changeBids($event, item)"
									@change="changeGiaThau(item)">
								</b-form-input>
								<template #append v-if="item.isGiaThauPercen">
									<b-input-group-text>
										<img :src="getIcon('percentage-square')" />
									</b-input-group-text>
								</template>
							</b-input-group>
						</b-col>
					</b-row>
				</div>
			</div>
			<template #modal-footer>
				<b-row class="w-100 mt-3">
					<b-col cols="4" class="group-btn-chia-phan-tram">
						<b-button :variant="isAnChia ? 'success' : ''" class="mr-1 btn-chia-phan-tram"
							@click="$bvModal.show('modal-chia-gia');">
							<img :src="getIcon('people')" />
						</b-button>
						{{ isAnChia ? 'Đã Chia %' : 'Chưa Chia %' }}
					</b-col>
					<b-col cols="4">
						<b-button block @click="onClickHuy('modal-ket-noi-super')">
							Hủy
						</b-button>
					</b-col>
					<b-col cols="4">
						<b-button variant="primary" block @click="submitKetNoiSuper">
							{{ isEditKetNoiSuper ? 'Lưu' : 'Thêm' }}
						</b-button>
					</b-col>
				</b-row>
			</template>
		</b-modal>

		<b-modal centered id="modal-them-dau-giao" title="THÊM ĐẦU GIAO" scrollable size="lg"
			content-class="style-for-web">
			<div class="d-block">
				<b-row class="mb-3">
					<b-col cols="6">
						<div class="text-left">Tên Đầu Giao</div>
						<b-input-group>
							<b-form-input v-model="objThemDauGiao.deliverName"></b-form-input>
						</b-input-group>
					</b-col>
					<b-col cols="6">
						<div class="text-left">Bí Danh</div>
						<b-input-group>
							<b-form-input v-model="objThemDauGiao.code"></b-form-input>
						</b-input-group>
					</b-col>
				</b-row>
				<b-row class="mb-3">
					<b-col cols="6">
						<div class="text-left">Số Điện Thoại</div>
						<b-input-group>
							<b-form-input v-model="objThemDauGiao.phoneNumber"> </b-form-input>
						</b-input-group>
					</b-col>
					<b-col cols="6">
						<div class="text-left">Chú Thích</div>
						<b-input-group>
							<b-form-input v-model="objThemDauGiao.description"> </b-form-input>
						</b-input-group>
					</b-col>
				</b-row>
			</div>
			<template #modal-footer>
				<b-row class="w-100 mt-3">
					<b-col cols="4"></b-col>
					<b-col cols="4">
						<b-button block @click="onClickHuy('modal-them-dau-giao')">
							Hủy
						</b-button>
					</b-col>
					<b-col cols="4">
						<b-button variant="primary" block @click="submitThemDauGiao">
							Thêm
						</b-button>
					</b-col>
				</b-row>
			</template>
		</b-modal>

		<b-modal centered id="modal-chia-gia" title="CHIA PHẦN TRĂM" scrollable size="lg"
			:content-class="$root.isMobile ? 'style-for-mobile' : 'style-for-web'">
			<b-row class="mb-3">
				<b-col cols="12">
					<div class="text-left group-label-an-chia">
						<b-form-checkbox id="label-an-chia" class="mr-1" v-model="isAnChia"
							@change="changeIsAnChia"></b-form-checkbox>
						<label for="label-an-chia">
							Chia Phần Trăm
							{{ isAnChia ? `(100% = ${tongGia} giá)` : '' }} <br>
							{{ isAnChia ? `Đã chia ${tongPhanTramDaChia}%` : '' }}
							{{ isAnChia ? `- Còn lại ${phanTramConLai}%` : '' }} <br>
						</label>
					</div>
					<template v-if="isAnChia">
						<div class="group-an-chia mb-2" v-for="(item, index) in dataAnChia" :key="index">
							<div>
								<multi-select v-model="item.khachAnChia" :options="dataDauGiaoChuaChon" label="deliverName"
									track-by="id" class="multi-select mr-1" placeholder="" :allow-empty="false"
									@input="changeKhachAnChia()">
								</multi-select>
								<b-button v-if="index < dataAnChia.length - 1" variant="danger" id="btn-them-an-chia"
									@click="xoaKhachAnChia(index)">
									<img :src="getIcon('minus-cirlce')" />
								</b-button>
								<b-button v-else variant="success" id="btn-them-an-chia" @click="themKhachAnChia()">
									<img :src="getIcon('add-circle')" />
								</b-button>
							</div>
							<div>
								<b-input-group class="mr-1">
									<b-form-input type="number" v-model="item.phanTramAnChia"
										@change="tinhPhanTramVaGia(1, item)"
										:disabled="!item.khachAnChia.id"></b-form-input>
									<template #append>
										<b-input-group-text>
											<img :src="getIcon('percentage-square')" />
										</b-input-group-text>
									</template>
								</b-input-group>
								<b-input-group>
									<b-form-input type="number" v-model="item.giaAnChia"
										@change="tinhPhanTramVaGia(2, item)"
										:disabled="!item.khachAnChia.id"></b-form-input>
									<template #append>
										<b-input-group-text>Giá</b-input-group-text>
									</template>
								</b-input-group>
							</div>
						</div>
					</template>
				</b-col>
			</b-row>
			<template #modal-footer>
				<b-row class="w-100 mt-3">
					<b-col cols="4"></b-col>
					<b-col cols="4">
						<b-button id="btn-xac-nhan-ko-xoa" block @click="onClickHuy('modal-chia-gia')">
							Hủy
						</b-button>
					</b-col>
					<b-col cols="4">
						<b-button id="btn-xac-nhan-co-xoa" variant="primary" block @click="onClickLuuChiaGia()">
							Lưu
						</b-button>
					</b-col>
				</b-row>
			</template>
		</b-modal>

		<b-modal centered id="modal-xac-nhan-xoa" title="XÁC NHẬN XÓA TÀI KHOẢN" scrollable size="sm"
			:content-class="$root.isMobile ? 'style-for-mobile' : 'style-for-web'">
			<div class="msg-xac-nhan-xoa">
				Bạn Chắc Chắn Muốn Xóa Tài Khoản <b>{{ rowSelected.userName }}</b>
			</div>
			<template #modal-footer>
				<b-row class="w-100 mt-3">
					<b-col cols="4"></b-col>
					<b-col cols="4">
						<b-button id="btn-xac-nhan-ko-xoa" block @click="huyXoaSuper()">
							Không
						</b-button>
					</b-col>
					<b-col cols="4">
						<b-button id="btn-xac-nhan-co-xoa" variant="danger" block @click="xoaSuper(rowSelected)">
							Có
						</b-button>
					</b-col>
				</b-row>
			</template>
		</b-modal>
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Super from "../../../service/super.service";
import Receiver from "../../../service/receiver.service";
import Map from "../../../service/map.service";
import Deliver from "../../../service/deliver.service";

export default {
	name: "LuuSuperPage",
	data() {
		return {
			fields: [
				{ key: 'action', label: '', cols: 2 },
				{ key: 'receiverName', label: 'Đầu Nhận', sortable: true },
				{ key: 'userName', label: 'Tên Tài Khoản', sortable: true },
				{ key: 'domainName', label: 'Nhà Cái', sortable: true },
				{ key: 'logId', label: 'Tên Đăng Nhập', sortable: true },
				// { key: 'level', label: 'Cấp Bậc', sortable: true },
				// { key: 'isActive', label: 'Trạng Thái', sortable: true },
			],
			fieldsFormula: [
				{ key: 'categoryName', label: 'Loại Chơi', cols: 4 },
				{ key: 'coefficients', label: 'Hoa Hồng', cols: 2 },
				{ key: 'bids', label: 'Giá Thầu', cols: 2 },
				{ key: 'currency', label: 'Loại Tiền', cols: 2 },
				{ key: 'action', label: '', cols: 2 }
			],
			dataCurrency: [
				{
					key: 'VND',
					name: 'VNĐ',
				},
				{
					key: 'USD',
					name: 'USD',
				},
			],
			dataSuper: [],
			dataReceiver: [],
			dataDomain: [],
			dataFormula: [],
			dataCombo: [],
			dataDauGiao: [],
			dataAnChia: [
				{
					khachAnChia: {},
					phanTramAnChia: '',
					giaAnChia: '',
				}
			],
			totalRows: 0,
			currentPage: 1,
			perPage: 15,
			searchValue: '',
			targetPopover: '',
			showPopover: false,
			superId: '',
			receiverObj: {},
			domainId: '',
			domainObj: {},
			logId: '',
			originId: '',
			parentId: '',
			userName: '',
			passCode: '',
			passWord: '',
			userId: '',
			groupComboDomainSort: '',
			level: 1,
			isThemTaiKhoanKhac: false,
			// modal-ket-noi-super
			isEditSuper: false,
			isEditKetNoiSuper: false,
			superSelected: {},
			dauGiaoSelected: {},
			comboId: '',
			comboObj: {},
			listFormulaCategory: [],
			comboSelected: {},
			formulasSelected: {},
			objThemDauGiao: {},
			isAnChia: false,
			tongGia: 0,
			phanTramConLai: 0,
			tongPhanTramDaChia: 0,
			isActiveShowSetting: false,
			rowSelected: {},
		};
	},
	computed: {
		...mapState("LuuSuperPage", []),
		dataDauGiaoChuaChon() {
			let data = [];
			for (const item of this.dataDauGiao) {
				let index = this.dataAnChia.findIndex(i => i.khachAnChia.id === item.id);
				if (index === -1) {
					data.push(item);
				}
			}
			return data;
		}
	},
	beforeCreate() {
		console.log("I have not been created yet!");
	},
	created() {
		console.log("I have just been created!");
	},
	beforeMount() {
		console.log("I am about to be mounted to the DOM!");
	},
	mounted() {
		console.log("I am mounted to the DOM!");
		this.forceMovePage();
		this.getSuper();
		this.getReceiver();
		this.getDeliver();
		this.getFormulas();
		this.getSuperStatus();
		window.addEventListener('keydown', this.keydown);
	},
	beforeDestroy() {
		console.log("I am about to be removed from the DOM!");
	},
	destroyed() {
		console.log("I no longer exist...");
		window.removeEventListener('keydown', this.keydown);
	},
	methods: {
		...mapActions("LuuSuperPage", []),
		keydown(e) {
			let key = e.keyCode || e.which;
			let isForcusDropdown = e.target.className === 'multiselect__input';
			if (isForcusDropdown) {
				return;
			}
			switch (key) {
				case 13: // enter
					if (document.getElementById('modal-them-super')) {
						this.themSuper();
					} else if (document.getElementById('modal-them-dau-giao')) {
						this.submitThemDauGiao();
					} else if (document.getElementById('modal-chia-gia')) {
						this.onClickLuuChiaGia();
					} else if (document.getElementById('modal-ket-noi-super')) {
						this.submitKetNoiSuper();
					} else if (document.getElementById('btn-xac-nhan-co-xoa-' + this.rowSelected.index)) {
						this.xoaSuper(this.rowSelected);
					}
					e.preventDefault();
					break;
				case 27: // esc
					if (document.getElementById('btn-xac-nhan-ko-xoa')) {
						this.huyXoaSuper();
					}
					e.preventDefault();
					break;
				case 122: // f11
					this.openThemSuper();
					e.preventDefault();
					break;
				case 114: // f3
					this.$refs['input-search'].$el.focus();
					e.preventDefault();
					break;
			}
		},
		getSuper() {
			let _this = this;
			Super.getAll(this.$root.url)
				.then((response) => {
					_this.dataSuper = response.data.map((i, index) => {
						i.numberIdx = index + 1;
						i.index = index + 1;
						i.isShowSetting = false;
						return i;
					});
					_this.totalRows = _this.dataSuper.length;
				}).catch((error) => {
					_this.errorApi(error, _this);
				});
		},
		getSuperStatus() {
			let _this = this;
			Map.getSuperStatus(this.$root.url, false)
				.then((response) => {
					_this.dataSuperChuaKetNoi = response.data
				}).catch((error) => {
					_this.errorApi(error, _this);
				});
		},
		async getChildSuper(data) {
			let dataDongCon = [];
			let _this = this;
			await Super.getChildSuper(this.$root.url, data.id)
				.then((response) => {
					if (response.data) {
						dataDongCon = response.data;
					}
				}).catch((error) => {
					_this.errorApi(error, _this);
				});
			return dataDongCon;
		},
		getFormulas() {
			this.dataFormulaCategory = JSON.parse(localStorage.getItem('DATA_FORMULA_CATEGORY'));
		},
		getDeliver() {
			let _this = this;
			Deliver.getTree(this.$root.url)
				.then((response) => {
					_this.dataDauGiao = response.data;
				}).catch((error) => {
					_this.errorApi(error, _this);
				});
		},
		reloadChild(data) {
			console.log(data);
			this.showToast('Tính năng đang phát triển chưa hoạt động được', 'Tính năng đang phát triển', this, "warning");
			// let _this = this;
			// _this.$root.isShowLoading = true;
			// Super.reloadChildSuper(this.$root.url, data.id)
			// 	.then(() => {
			// 		_this.$root.isShowLoading = false;
			// 		_this.toggleChildSuper(data);
			// 	}).catch((error) => {
			// 		_this.$root.isShowLoading = false;
			// 		_this.errorApi(error, _this);
			// 		console.log(error)
			// 	});
		},
		getReceiver() {
			let _this = this;
			Receiver.getAll(this.$root.url)
				.then((response) => {
					_this.dataReceiver = response.data;
				}).catch((error) => {
					_this.errorApi(error, _this);
				});
		},
		getDetailSuper(data) {
			let _this = this;
			let id;
			if (this.isEditSuper) {
				id = data.id;
				_this.superId = data.id;
			} else {
				id = data.id;
			}
			_this.$root.isShowLoading = false;
			Super.getDetail(this.$root.url, id)
				.then((response) => {
					if (_this.isEditSuper) {
						_this.groupComboDomainSort = response.data.groupComboDomainSort;
						_this.receiverId = response.data.receiverId;
						_this.receiverObj = _this.dataReceiver.filter(i => i.id === response.data.receiverId)[0];
						_this.domainId = response.data.domainId;
						_this.logId = response.data.logId;
						_this.userName = response.data.userName;
						_this.passCode = response.data.passCode;
						_this.passWord = response.data.passWord;
						_this.originId = response.data.originId;
						_this.parentId = response.data.parentId;
						_this.userId = response.data.userId;
						_this.level = response.data.level;
						_this.changeReceiver(_this.isEditSuper);
						_this.$bvModal.show('modal-them-super');
					} else {
						data.item.detail = response.data;
						data.toggleDetails();
					}
					_this.$root.isShowLoading = true;
				}).catch((error) => {
					_this.isEditSuper = false;
					_this.errorApi(error, _this);
					_this.$root.isShowLoading = true;
				});
		},
		getDetailReceiver(isEdit) {
			let _this = this;
			Receiver.getDetail(this.$root.url, _this.receiverId)
				.then((response) => {
					let dataNhaCai = [];
					for (const item of response.data.types) {
						for (const item1 of item.domains) {
							if (dataNhaCai.findIndex(i => i.domainId === item1.domainId) === -1) {
								item1.formulas = response.data.formulas.filter(i => i.domainId === item1.domainId);
								dataNhaCai.push(item1);
							}
						}
					}
					_this.dataDomain = dataNhaCai;
					_this.dataFormula = [];
					if (isEdit) {
						_this.domainObj = _this.dataDomain.filter(i => i.domainId === _this.domainId)[0];
						_this.changeDomain(isEdit);
					}
				}).catch((error) => {
					_this.errorApi(error, _this);
				});
		},
		getMapSuperDeliverFormula(data) {
			this.tongGia = 0;
			this.isAnChia = false;
			this.phanTramConLai = 0;
			this.tongPhanTramDaChia = 0;
			this.dauGiaoSelected = {};
			this.dataAnChia = [
				{
					khachAnChia: {},
					phanTramAnChia: '',
					giaAnChia: '',
				}
			]
			this.formulasSelected = [];
			let _this = this;
			Map.getMapSuperDeliverFormula(this.$root.url, { superId: data.id, deliverId: data.deliverId })
				.then((response) => {
					_this.superSelected = {
						domainId: response.data.superDto.domainId,
						domainName: response.data.superDto.domainName,
						level: response.data.superDto.level,
						loginId: response.data.superDto.loginId,
						receiverId: response.data.superDto.receiverId,
						receiverName: response.data.superDto.receiverName,
						superId: response.data.superDto.id,
						superName: response.data.superDto.userName,
						comboId: response.data.superDto.comboId,
						receiverDetail: response.data.superDto.receiverDetail,
					};
					if (response.data.formulas.length > 0) {
						if (response.data.sharers && response.data.sharers.length > 0) {
							_this.dataAnChia = [];
							for (const item of response.data.sharers) {
								_this.dataAnChia.push({
									khachAnChia: item.deliver,
									phanTramAnChia: item.percent,
									giaAnChia: 0,
								})
							}
						}
						_this.dauGiaoSelected = response.data.deliver;
						_this.comboId = response.data.formulas[0].comboId;
						_this.formulasSelected = response.data.formulas;
						_this.isEditKetNoiSuper = true;
					} else {
						let superChuaKetNoi = _this.dataSuperChuaKetNoi.filter(i => i.superId === _this.superSelected.superId)[0];
						if (superChuaKetNoi) {
							_this.superSelected.comboId = superChuaKetNoi.comboId;
						}
						_this.isEditKetNoiSuper = false;
					}
					_this.changeSuper();
				}).catch((error) => {
					_this.errorApi(error, _this);
				});
		},
		changeSuper(isReset = false) {
			if (isReset) {
				this.isAnChia = false;
				this.dataAnChia = [
					{
						khachAnChia: {},
						phanTramAnChia: '',
						giaAnChia: '',
					}
				];
				this.comboId = '';
				this.comboObj = {};
				this.comboSelected = {};
				this.listFormulaCategory = [];
				this.getCombo();
			} else {
				this.getCombo(this.isEditKetNoiSuper);
			}
		},
		getCombo(isEdit = false) {
			this.dataCombo = JSON.parse(localStorage.getItem('DATA_COMBO')).filter(i => i.domainId === this.superSelected.domainId);
			if (isEdit) {
				this.comboObj = this.dataCombo.filter(i => i.comboId === this.comboId)[0];
				this.changeCombo(isEdit);
			} else {
				this.comboObj = this.dataCombo.filter(i => i.comboId === this.superSelected.comboId)[0];
				if (this.comboObj) {
					this.changeCombo();
				}
			}
		},
		changeCombo(isEdit = false) {
			this.comboId = this.comboObj.comboId;
			this.comboSelected = this.comboObj;
			this.listFormulaCategory = [];
			if (isEdit) {
				for (const item of this.formulasSelected) {
					this.listFormulaCategory.push({
						formulaCategory: this.dataFormulaCategory.filter(i => i.formulaCategoryId === item.categoryId)[0],
						formulaCategoryId: item.categoryId,
						currency: item.currency,
						coefficients: item.coefficients,
						bids: item.bids,
						isGiaThauPercen: item.isPercent,
					})
				}
			} else {
				for (const item of this.comboSelected.listFormulaCategory) {
					this.listFormulaCategory.push({
						formulaCategory: this.dataFormulaCategory.filter(i => i.formulaCategoryId === item.formulaCategoryId)[0],
						formulaCategoryId: item.formulaCategoryId,
						currency: 'VND',
						coefficients: 1,
						bids: '',
						isGiaThauPercen: '',
					});
				}
			}
			if (this.dataAnChia[0].khachAnChia.id) {
				this.isAnChia = true;
				this.changeIsAnChia();
				for (const item of this.dataAnChia) {
					this.tinhPhanTramVaGia(1, item);
				}
			}
		},
		openThemSuper() {
			this.superId = '';
			this.dataDomain = [];
			this.dataFormula = [];
			this.receiverId = '';
			this.receiverObj = {};
			this.domainId = '';
			this.domainObj = {};
			this.logId = '';
			this.userName = '';
			this.passCode = '';
			this.passWord = '';
			this.level = 1;
			this.$bvModal.show('modal-them-super');
		},
		openKetNoiSuper(taiKhoan) {
			this.getMapSuperDeliverFormula(taiKhoan);
			this.$bvModal.show('modal-ket-noi-super');
		},
		openThemDauGiao() {
			this.objThemDauGiao.deliverName = '';
			this.objThemDauGiao.code = '';
			this.objThemDauGiao.phoneNumber = '';
			this.objThemDauGiao.description = '';
			this.$bvModal.show('modal-them-dau-giao')
		},
		submitThemDauGiao() {
			let _this = this;
			let param = {
				code: this.objThemDauGiao.code,
				deliverName: this.objThemDauGiao.deliverName,
				description: this.objThemDauGiao.description,
				level: 0,
				parentId: '',
				phoneNumber: this.objThemDauGiao.phoneNumber,
			};
			Deliver.post(this.$root.url, param)
				.then(() => {
					_this.getDeliver();
					_this.$bvModal.hide("modal-them-dau-giao");
					_this.showToast("Thêm thành công!", "Thêm Đầu Giao", _this, "success");
				})
				.catch((error) => {
					_this.errorApi(error, _this);
				});
		},
		submitKetNoiSuper() {
			this.mapSuperDeliverFormula();
		},
		mapSuperDeliverFormula() {
			let formulas = [];
			for (const item of this.listFormulaCategory) {
				let obj = {
					bids: item.bids,
					categoryId: item.formulaCategoryId,
					coefficients: item.coefficients,
					comboId: this.comboSelected.comboId,
					currency: item.currency,
					isPercent: item.isGiaThauPercen,
					groupComboDomainSort: this.comboSelected.groupComboDomainSort,
				}
				formulas.push(obj);
			}
			let anChia = [];
			for (const item of this.dataAnChia) {
				if (item.khachAnChia && item.khachAnChia.id) {
					anChia.push({
						deliverId: item.khachAnChia.id,
						percent: Number(item.phanTramAnChia),
					})
				}
			}
			let data = {
				superId: this.superSelected.superId,
				deliverId: this.dauGiaoSelected.id,
				sharers: anChia,
				formulas: formulas,
			}
			let _this = this;
			let api = this.isEditKetNoiSuper ? 'updateMapSuperDeliverFormula' : 'insertMapSuperDeliverFormula';
			Map[api](this.$root.url, data)
				.then(() => {
					_this.$bvModal.hide('modal-ket-noi-super');
					if (_this.isEditKetNoiSuper) {
						_this.showToast("Chỉnh Sửa thành công!", "Chỉnh Sửa Lưu Giá", _this, "success");
						_this.isEditKetNoiSuper = false;
					} else {
						_this.showToast("Thêm thành công!", "Thêm Lưu Giá", _this, "success");
					}
				}).catch((error) => {
					_this.isEditKetNoiSuper = false;
					_this.errorApi(error, _this);
				});
		},
		ketNoiSuper(taiKhoan) {
			this.openKetNoiSuper(taiKhoan);
		},
		themSuper() {
			let _this = this;
			let comboSeleted = this.dataFormula.filter(i => i.groupComboDomainSort === this.groupComboDomainSort);
			let param = {
				groupComboDomainSort: comboSeleted[0] ? comboSeleted[0].groupComboDomainSort : null,
				comboId: comboSeleted[0] ? comboSeleted[0].comboId : null,
				domainId: comboSeleted[0] ? comboSeleted[0].domainId : null,
				domainName: comboSeleted[0] ? comboSeleted[0].domainName : null,
				isActive: true,
				level: this.level,
				logId: this.logId.trim(),
				originId: this.originId,
				parentId: this.parentId,
				passCode: this.passCode,
				passWord: this.passWord,
				receiverId: this.receiverId,
				typeDomain: null,
				userId: this.userId,
				userName: this.userName,
			}

			let api = 'insert';
			if (_this.superId) {
				param.id = _this.superId;
				api = 'update';
			}
			Super[api](this.$root.url, param)
				.then(() => {
					_this.getSuper();
					_this.logId = '';
					if (!_this.isThemTaiKhoanKhac) {
						_this.$bvModal.hide('modal-them-super');
					}
					if (param.id) {
						_this.showToast('Chỉnh Sửa thành công!', 'Chỉnh Sửa tài khoản', this, 'success');
						_this.isEditSuper = false;
					} else {
						_this.showToast('Thêm thành công!', 'Thêm tài khoản', this, 'success');
					}
				}).catch((error) => {
					_this.isEditSuper = false;
					_this.errorApi(error, _this);
				});
		},
		chinhSuaSuper(data) {
			this.isEditSuper = true;
			this.getDetailSuper(data);
		},
		xoaSuper(data) {
			let _this = this;
			Super.delete(this.$root.url, data.id)
				.then(() => {
					_this.getSuper();
					_this.showToast('Xóa thành công!', 'Xóa tài khoản', this, 'success')
					_this.$refs['popover-xoa-' + data.index].$emit('close');
				}).catch((error) => {
					_this.errorApi(error, _this);
				});
		},
		changeReceiver(isEdit) {
			if (!isEdit) {
				this.dataDomain = [];
				this.dataFormula = [];
				this.domainId = '';
				this.domainObj = {};
				this.receiverId = this.receiverObj.id;
			}
			this.getDetailReceiver(isEdit);
		},
		changeDomain(isEdit) {
			let groupComboDaThem = [];
			this.domainId = this.domainObj.domainId;
			for (const domain of this.dataDomain) {
				if (domain.domainId === this.domainId) {
					for (const item of domain.formulas) {
						if (groupComboDaThem.findIndex(i => i === item.groupComboDomainSort) !== -1) {
							continue;
						} else {
							groupComboDaThem.push(item.groupComboDomainSort);
						}
						item.showRadio = true;
					}
					this.dataFormula = domain.formulas;
				}
			}
			if (!isEdit) {
				this.groupComboDomainSort = this.dataFormula[0].groupComboDomainSort;
			}
		},
		tinhBids(data) {
			let returnValue;
			if (data.bids < 1) {
				data.isGiaThauPercen = true;
				returnValue = (parseFloat(data.bids) * 100) + "%";
			} else {
				returnValue = data.bids;
			}
			return returnValue.toString();
		},
		async toggleChildSuper(data) {
			for (const item of this.dataSuper) {
				if (item.index !== data.index && item.level === data.level && item.showDongCon) {
					this.toggleChildSuper(item);
				}
			}
			data.showDongCon = !data.showDongCon;
			if (data.showDongCon) {
				let levelDongNgoaiCung = !data.parentId ? data.level : data.levelDongNgoaiCung;
				let dataDongCon = await this.getChildSuper({ id: data.id });
				if (dataDongCon && dataDongCon.length === 0) {
					this.showToast(data.userName + " không có tài khoản cấp dưới", "Thông báo", this, "warning");
					data.showDongCon = false;
					return;
				}
				data.dataDongCon = [];
				for (const item of dataDongCon) {
					if ((item.level - 1) === data.level) {
						data.dataDongCon.push({
							...item,
							levelDongNgoaiCung: levelDongNgoaiCung,
						});
					}
				}
				let i = this.cloneDeep(data.numberIdx);
				for (const [idx, item] of data.dataDongCon.entries()) {
					item.index = data.index + '.' + (idx + 1);
					this.dataSuper.splice(i, 0, item);
					i++;
				}
				this.dataSuper.forEach((i, index) => {
					i.numberIdx = index + 1;
					i.isShowSetting = false;
				})
				this.totalRows = this.dataSuper.length;
				this.perPage = this.perPage + data.dataDongCon.length;
			} else {
				let dataDongCon = data.dataDongCon;
				for (const item of dataDongCon) {
					let idxDong = this.dataSuper.findIndex(i => i.index === item.index);
					let itemDong = this.dataSuper[idxDong];
					if (itemDong && itemDong.showDongCon) {
						this.toggleChildSuper(itemDong);
					}
					this.dataSuper.splice(idxDong, 1);
				}
			}

			this.dataSuper.forEach((i, index) => {
				i.numberIdx = index + 1;
				i.isShowSetting = false;
			})
		},
		huyXoaSuper() {
			this.$bvModal.hide('modal-xac-nhan-xoa');
		},
		setRowSelected() {
			this.$bvModal.show('modal-xac-nhan-xoa');
		},
		showSetting(item) {
			for (const i of this.dataSuper) {
				if (item.index === i.index) {
					item.isShowSetting = !item.isShowSetting;
				} else {
					i.isShowSetting = false;
				}
			}
			if (item.isShowSetting) {
				this.rowSelected = item;
			} else {
				this.rowSelected = {};
			}
		},
		onRowClicked() {
			for (const i of this.dataSuper) {
				i.isShowSetting = false;
			}
		},
		onFiltered(filteredItems) {
			this.totalRows = filteredItems.length;
			this.currentPage = 1;
		},
		checkDisableBtn() {
			if (this.dataDomain.length === 0 ||
				this.dataFormula.length === 0 ||
				this.receiverId === '' ||
				this.domainId === '' ||
				this.logId === '' ||
				this.passWord === '') {
				return true;
			} else {
				return false;
			}
		},
		onClickHuy(key) {
			this.$bvModal.hide(key);
			switch (key) {
				case 'modal-them-super':
					this.isEditSuper = false;
					break;
				case 'modal-ket-noi-super':
					this.isEditKetNoiSuper = false;
					break;
				case 'modal-chia-gia':
					if (!this.isEditKetNoiSuper) {
						this.isAnChia = false;
						this.dataAnChia = [
							{
								khachAnChia: {},
								phanTramAnChia: '',
								giaAnChia: '',
							}
						];
					}
					break;
			}
		}, onClickLuuChiaGia() {
			if (this.isAnChia) {
				this.$bvModal.hide('modal-chia-gia');
			}
		},
		themKhachAnChia() {
			this.dataAnChia.push(
				{
					khachAnChia: {},
					phanTramAnChia: '',
					giaAnChia: '',
				}
			)
		},
		xoaKhachAnChia(index) {
			this.dataAnChia.splice(index, 1);
		},
		changeIsAnChia() {
			if (this.listFormulaCategory.length > 0) {
				let tongBids1 = 0;
				let trungBinhGiaGiao = 0;
				let tyGia = localStorage.getItem('rateUsd');
				for (const item of this.listFormulaCategory) {
					if (item.bids === '') {
						this.showToast("Chưa nhập giá thầu", "Thông báo", this, "warning");
						this.isAnChia = false;
						return;
					}
					let bids = item.isGiaThauPercen ? ((item.bids / 100) * tyGia) : item.bids;
					tongBids1 += Number(bids);
				}
				trungBinhGiaGiao = tongBids1 / this.listFormulaCategory.length;

				let listFormulas = this.superSelected.receiverDetail.formulas;
				let tongBids2 = 0;
				let trungBinhGiaNhan = 0;
				let combo = listFormulas.filter(i => i.comboId === this.superSelected.comboId);
				for (const item of combo) {
					let bids = item.isPercent ? ((item.bids / 100) * tyGia) : item.bids;
					tongBids2 += Number(bids);
				}
				trungBinhGiaNhan = tongBids2 / combo.length;

				this.tongGia = (trungBinhGiaGiao - trungBinhGiaNhan).toFixed(2);
				this.tinhConLai();
			}
		},
		changeKhachAnChia() {
			let dauGiaoDcChon = this.dataAnChia.filter(i => typeof i.khachAnChia.id !== 'undefined');
			this.tongPhanTramDaChia = 0
			for (const item of dauGiaoDcChon) {
				item.phanTramAnChia = item.phanTramAnChia ? item.phanTramAnChia : 0;
				item.giaAnChia = item.giaAnChia ? item.giaAnChia : 0;
				this.tongPhanTramDaChia += Number(item.phanTramAnChia);
			}
			this.phanTramConLai = (100 - this.tongPhanTramDaChia).toFixed(2);
			this.tongPhanTramDaChia = (this.tongPhanTramDaChia).toFixed(2);
		},
		tinhPhanTramVaGia(mode, item) {
			switch (mode) {
				case 1:
					item.giaAnChia = ((this.tongGia * item.phanTramAnChia) / 100).toFixed(2);
					item.phanTramAnChia = Number(item.phanTramAnChia).toFixed(2);
					break;
				case 2:
					item.phanTramAnChia = ((item.giaAnChia / this.tongGia) * 100).toFixed(2);
					item.giaAnChia = Number(item.giaAnChia).toFixed(2);
					break;
			}
			let tongPhanTram = 0;
			let tongGia = 0;
			for (const i of this.dataAnChia) {
				tongPhanTram += Number(i.phanTramAnChia);
				tongGia += Number(i.giaAnChia);
			}
			if (tongPhanTram > 100 || tongGia > this.tongGia) {
				item.phanTramAnChia = 0;
				item.giaAnChia = 0;
				this.showToast('Giá của bạn nhập cao hơn giá giao, không thể thực hiện !', 'Lỗi nhập số', this, "danger");
			}
			this.tinhConLai();
		},
		tinhConLai() {
			let dauGiaoDcChon = this.dataAnChia.filter(i => typeof i.khachAnChia.id !== 'undefined');
			this.tongPhanTramDaChia = 0
			for (const item of dauGiaoDcChon) {
				this.tongPhanTramDaChia += Number(item.phanTramAnChia);
			}
			this.phanTramConLai = (100 - this.tongPhanTramDaChia).toFixed(2);
			this.tongPhanTramDaChia = (this.tongPhanTramDaChia).toFixed(2);
		},
		changeGiaThauPercen(item) {
			if (item.isGiaThauPercen) {
				item.currency = 'USD';
			} else {
				item.currency = 'VND';
			}
			this.changeGiaThau(item);
		},
		changeGiaThau(item) {
			setTimeout(() => {
				if (item.bids <= 0) {
					item.bids = 1
				}
			}, 100);
			if (this.isAnChia) {
				this.changeIsAnChia();
				for (const i of this.dataAnChia) {
					this.tinhPhanTramVaGia(1, i);
				}
			}
		},
		getClassLevel(item) {
			let classStr = '';
			let levelColor = item.level;
			let levelPadding = 0;
			if (!item.parentId) {
				levelPadding = 1;
			} else {
				levelPadding = item.level - (item.levelDongNgoaiCung - 1);
			}
			switch (levelPadding) {
				case 1:
				case 2:
				case 3:
					classStr = `btn-link color-level-${levelColor} padding-level-${levelPadding}`;
					break;
				case 4:
					classStr = `color-level-${levelColor} padding-level-${levelPadding}`;
					break;
			}
			return classStr;
		},
		changeBids(event, item) {
			let text = event.clipboardData.getData('text');
			if (text.includes('%')) {
				item.isGiaThauPercen = true;
				this.changeGiaThauPercen(item);
			}
		},
		changeDauKhach() {
			this.isAnChia = false;
			this.dataAnChia = [];
		}
	},
};
</script>

<style lang="scss">
.style-for-mobile .luu-super-page {
	.btn-an-chi-tiet {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.b-table-sticky-header {
		max-height: calc(100vh - 225px) !important;
	}

	.table-luu-super {
		background: var(--common-background);
		border-radius: 8px;
		box-shadow: 0px 2px 6px var(--active-tab-box-shadow);
	}

	.cap-bat-1 {
		color: var(--cap-bat-1-color);
		background-color: var(--cap-bat-1-back-color);
		border-radius: 8px;
		padding: 2px;
		text-align: center;
		font-weight: bold;
	}

	.cap-bat-2 {
		color: var(--cap-bat-2-color);
		background-color: var(--cap-bat-2-back-color);
		border-radius: 8px;
		padding: 2px;
		text-align: center;
		font-weight: bold;
	}

	.cap-bat-3 {
		color: var(--cap-bat-3-color);
		background-color: var(--cap-bat-3-back-color);
		border-radius: 8px;
		padding: 2px;
		text-align: center;
		font-weight: bold;
	}

	.cap-bat-4 {
		color: var(--cap-bat-4-color);
		background-color: var(--cap-bat-4-back-color);
		border-radius: 8px;
		padding: 2px;
		text-align: center;
		font-weight: bold;
	}

	.dang-hoat-dong {
		color: var(--dang-hoat-dong-color);
		background-color: var(--dang-hoat-dong-back-color);
		border-radius: 8px;
		padding: 2px;
		text-align: center;
		font-weight: bold;
	}

	.ngung-hoat-dong {
		color: var(--ngung-hoat-dong-color);
		background-color: var(--ngung-hoat-dong-back-color);
		border-radius: 8px;
		padding: 2px;
		text-align: center;
		font-weight: bold;
	}
}

#modal-ket-noi-super .style-for-mobile {
	.items-nha-cai {
		background-color: var(--items-nha-cai-back-color);
		border: 1px solid var(--modal-input-border);
		border-top: none;
		border-radius: 8px;
	}

	.input-group>.input-group-append>.input-group-text {
		height: calc(1.5em + 0.9rem + 4px);
	}

	.modal-body {
		min-height: 50vh;
	}

	.gia-thau {
		display: flex;
	}

	.group-label-dau-giao {
		display: flex;

		.btn-them-dau-giao {
			width: 20px;
			filter: var(--img-filter-icon-menu) !important;
			cursor: pointer;

			&:hover {
				filter: var(--img-filter-blue) !important;
			}
		}
	}
}

#modal-them-super .style-for-mobile {
	.table-luu-super {
		background: var(--common-background);
		border-radius: 8px;
		box-shadow: 0px 2px 6px var(--active-tab-box-shadow);
		padding: 0px 5px;
	}

	.header-list-formula {
		>div {
			background: var(--table-header-background);
			border-color: var(--table-header-background);
			border-bottom: 2px solid var(--table-header-background);
			padding: 0.75rem !important;

			&:last-child {
				border-top-right-radius: 8px;
			}

			&:first-child {
				border-top-left-radius: 8px;
			}
		}
	}

	.body-list-formula {
		>div {
			background-color: var(--body-list-formula-background);
			border-color: var(--body-list-formula-background);
			color: var(--text-color);
			padding: 0.75rem !important;
		}

		&.has-radio {
			border-top: 1px solid #dee2e6;
		}

		&:last-child {
			>div {
				&:last-child {
					border-bottom-right-radius: 8px;
				}

				&:first-child {
					border-bottom-left-radius: 8px;
				}
			}
		}
	}
}

#modal-chia-gia .style-for-mobile {
	.group-label-an-chia {
		display: flex;
	}

	.group-an-chia {
		>div {
			display: flex;
			margin-bottom: 3px;
		}

		button {
			height: 38px;
			padding: 0 0.75rem;

			img {
				filter: var(--img-filter) !important;
			}
		}
	}

	.modal-body {
		min-height: 50vh;
	}
}
</style>
