import ApiService from "./api.service";

const Account = {
    getAll: async (url) => {
        url = url + `/account/list-details`;
        return ApiService.get(url);
    },
    getDetail: async (url, id) => {
        url = url + `/account/detail?userId=${id}`;
        return ApiService.get(url);
    },
    post: async (url, data) => {
        url = url + `/account/insert-or-update`;
        return ApiService.post(url, data);
    },
    delete: async (url, ids) => {
        url = url + `/account/delete?ids=${ids}`;
        return ApiService.delete(url);
    },
    changePassword: async (url, data) => {
        url = url + "/account/change-password";
        return ApiService.post(url, data);
    },
    updateRate: async (url, data) => {
        url = url + "/account/update-rate?rateUsd=" + data;
        return ApiService.post(url);
    },
};

export default Account;
