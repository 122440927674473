import Vue from "vue";
import VueRouter from "vue-router";

// Web
import LoginPage from "../components/Web/Login/Login.vue";
import HomePage from "../components/Web/Home/Home.vue";
import CauHinhPage from "../components/Web/CaiDat/CauHinh.vue";
import NhanGiaoPage from "../components/Web/CaiDat/NhanGiao.vue";
import LuuSuperPage from "../components/Web/CaiDat/LuuSuper.vue";
import KiemTraLoiLoPage from "../components/Web/KeToan/KiemTraLoiLo.vue";
import KiemTraDauGiaoPage from "../components/Web/KeToan/KiemTraDauGiao.vue";
import KiemTraTheoPhanTramPage from "../components/Web/KeToan/KiemTraTheoPhanTram.vue";
import LuuTruBaoCaoPage from "../components/Web/KeToan/LuuTruBaoCao.vue";
import LuuTruCongNoPage from "../components/Web/KeToan/LuuTruCongNo.vue";
import LichSuXuatHoaDonPage from "../components/Web/KeToan/LichSuXuatHoaDon.vue";
import QuanLyTaiKhoanPage from "../components/Web/TaiKhoan/QuanLyTaiKhoan.vue";

// Mobile
import LoginMobilePage from "../components/Mobile/Login/Login.vue";
import HomeMobilePage from "../components/Mobile/Home/Home.vue";
import CauHinhMobilePage from "../components/Mobile/CaiDat/CauHinh.vue";
import NhanGiaoMobilePage from "../components/Mobile/CaiDat/NhanGiao.vue";
import LuuSuperMobilePage from "../components/Mobile/CaiDat/LuuSuper.vue";
import KiemTraLoiLoMobilePage from "../components/Mobile/KeToan/KiemTraLoiLo.vue";
import KiemTraDauGiaoMobilePage from "../components/Mobile/KeToan/KiemTraDauGiao.vue";
import KiemTraTheoPhanTramMobilePage from "../components/Mobile/KeToan/KiemTraTheoPhanTram.vue";
import LuuTruBaoCaoMobilePage from "../components/Mobile/KeToan/LuuTruBaoCao.vue";
import LuuTruCongNoMobilePage from "../components/Mobile/KeToan/LuuTruCongNo.vue";
import LichSuXuatHoaDonMobilePage from "../components/Mobile/KeToan/LichSuXuatHoaDon.vue";
import QuanLyTaiKhoanMobilePage from "../components/Mobile/TaiKhoan/QuanLyTaiKhoan.vue";


Vue.use(VueRouter);

const router = new VueRouter({
  routes: [
    {
      path: "/",
      component: LoginPage,
    },
    {
      path: "/login",
      component: LoginPage,
    },
    {
      path: "/trang-chu",
      component: HomePage,
    },
    {
      path: "/cau-hinh",
      component: CauHinhPage,
    },
    {
      path: "/nhan-giao",
      component: NhanGiaoPage,
    },
    {
      path: "/luu-super",
      component: LuuSuperPage,
    },
    {
      path: "/kiem-tra-loi-lo",
      component: KiemTraLoiLoPage,
    },
    {
      path: "/kiem-tra-dau-giao",
      component: KiemTraDauGiaoPage,
    },
    {
      path: "/kiem-tra-theo-phan-tram",
      component: KiemTraTheoPhanTramPage,
    },
    {
      path: "/luu-tru-bao-cao",
      component: LuuTruBaoCaoPage,
    },
    {
      path: "/luu-tru-cong-no",
      component: LuuTruCongNoPage,
    },
    {
      path: "/lich-su-xuat-hoa-don",
      component: LichSuXuatHoaDonPage,
    },
    {
      path: "/quan-ly-tai-khoan",
      component: QuanLyTaiKhoanPage,
    },
    // Mobile
    {
      path: "/mb",
      component: LoginMobilePage,
    },
    {
      path: "/mb-login",
      component: LoginMobilePage,
    },
    {
      path: "/mb-trang-chu",
      component: HomeMobilePage,
    },
    {
      path: "/mb-cau-hinh",
      component: CauHinhMobilePage,
    },
    {
      path: "/mb-nhan-giao",
      component: NhanGiaoMobilePage,
    },
    {
      path: "/mb-luu-super",
      component: LuuSuperMobilePage,
    },
    {
      path: "/mb-kiem-tra-loi-lo",
      component: KiemTraLoiLoMobilePage,
    },
    {
      path: "/mb-kiem-tra-dau-giao",
      component: KiemTraDauGiaoMobilePage,
    },
    {
      path: "/mb-kiem-tra-theo-phan-tram",
      component: KiemTraTheoPhanTramMobilePage,
    },
    {
      path: "/mb-luu-tru-bao-cao",
      component: LuuTruBaoCaoMobilePage,
    },
    {
      path: "/mb-luu-tru-cong-no",
      component: LuuTruCongNoMobilePage,
    },
    {
      path: "/mb-lich-su-xuat-hoa-don",
      component: LichSuXuatHoaDonMobilePage,
    },
    {
      path: "/mb-quan-ly-tai-khoan",
      component: QuanLyTaiKhoanMobilePage,
    },
  ],
});

export default router;
