import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import moment from "moment";
import VueMomentJS from "vue-momentjs";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import Multiselect from 'vue-multiselect';
import DateRangePicker from 'vue2-daterange-picker'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "vue-multiselect/dist/vue-multiselect.min.css";
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

import { Socket } from '@/websocket/websocket'
import System from '../src/service/system.service'

Vue.config.productionTip = false;

moment.locale("vi");

Vue.use(VueMomentJS, moment);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

// register globally
Vue.component('multi-select', Multiselect)
Vue.component('date-range-picker', DateRangePicker)

Vue.mixin({
    methods: {
        detectMob() {
            const toMatch = [
                /Android/i,
                /webOS/i,
                /iPhone/i,
                /iPad/i,
                /iPod/i,
                /BlackBerry/i,
                /Windows Phone/i
            ];

            return toMatch.some((toMatchItem) => {
                return navigator.userAgent.match(toMatchItem);
            });
        },
        movePage(path, method = "push") {
            let isMob = this.detectMob();
            path = isMob ? `mb-${path}` : path.replace('mb-', '');
            switch (method) {
                case "replace":
                    if (this.$route.path !== ('/' + path)) {
                        this.$router.replace(path);
                    }
                    break;
                default:
                    if (this.$route.path !== ('/' + path)) {
                        this.$router.push(path);
                    }
                    break;
            }
        },
        forceMovePage() {
            let _this = this;
            System.getNotification(this.$root.url)
                .then((response) => {
                    _this.$root.isSystemMaintenance = response.data.isEnable;
                    _this.$root.dataMaintenance = response.data;
                    if (_this.$root.isSystemMaintenance) {
                        Socket.onDisconnect();
                        let theme = localStorage.getItem("themeColor");
                        let isMiniMenu = localStorage.getItem("isMiniMenu");
                        localStorage.clear();
                        localStorage.setItem("themeColor", theme);
                        localStorage.setItem('isMiniMenu', isMiniMenu);
                        _this.$root.isLogin = false;
                        _this.deleteCookie('token');
                        _this.deleteCookie('userId');
                        _this.demNguocThoiGian(response.data.endDate);
                        _this.movePage("login", "replace");
                    }
                })
                .catch((error) => {
                    _this.errorApi(error, _this);
                });
            let routePath = this.$route.path;
            if ((this.$root.isMobile && !routePath.includes("mb-")) ||
                (!this.$root.isMobile && routePath.includes("mb-"))) {
                this.movePage(routePath.substring(1), "replace");
            }
        },
        switchHtmlTheme(theme = 'light') {
            let html = document.querySelector("html");
            html.dataset.theme = theme;
            this.$root.theme = theme
        },
        getIcon(icon, type) {
            return require(`./assets/${type ? type : "iconsax-svg"}/${icon}.svg`);
        },
        getImg(icon, type) {
            return require(`./assets/img/${icon}.${type}`);
        },
        getLogoNhaCai(name) {
            let type = 'png';
            let nameNhaCai = name;
            switch (nameNhaCai) {
                case 'SBOBET HK':
                    nameNhaCai = 'SBOBET';
                    break;
            }
            return require(`./assets/img/logo-nha-cai/${nameNhaCai.toUpperCase()}.${type}`);
        },
        errorApi: (error, app) => {
            localStorage.setItem("loading", 0);
            let message = "";
            let code = "";

            if (error.response && error.response.status === 401) {
                Socket.onDisconnect();
                setTimeout(() => {
                    if (app.$root.isLogin) {
                        app.$root.isLogin = false;
                        app.showToast(
                            "Xin đăng nhập lại",
                            "Hết giờ!",
                            app,
                            "danger"
                        );
                    }
                }, 100);
                let theme = localStorage.getItem("themeColor");
                let isMiniMenu = localStorage.getItem("isMiniMenu");
                localStorage.clear();
                localStorage.setItem("themeColor", theme);
                localStorage.setItem('isMiniMenu', isMiniMenu);
                app.movePage("login", "replace");
                return
            } else if (error.response) {
                message = error.response.data.message;
                code = error.response.data.code;
            } else {
                message = error.message;
                code = error.code;
            }
            if (code === 'ERR_NETWORK') {
                message = 'Lỗi kết nối do mất mạng hoặc server dừng hoạt động';
            }
            let title = `Lỗi ${code}`
            console.error(error);
            app.showToast(message, title, app, "danger", error.time);
        },
        showToast: (message, title, app, variant, time = 3000) => {
            app.$bvToast.toast(message, {
                title: title,
                autoHideDelay: time,
                variant: variant,
                solid: true
            });
        },
        cloneDeep(value) {
            return value ? JSON.parse(JSON.stringify(value)) : value;
        },
        getRangeDate(range, number = 1) {
            let dateFrom = '';
            let dateTo = '';
            switch (range) {
                case 'homNay':
                    dateFrom = this.$moment(new Date().setHours(0, 0, 0, 0));
                    dateTo = this.$moment(new Date().setHours(23, 59, 59, 999));
                    break;
                case 'homQua':
                    dateFrom = this.$moment(new Date().setHours(0, 0, 0, 0)).subtract(1, 'days');
                    dateTo = this.$moment(new Date().setHours(23, 59, 59, 999)).subtract(1, 'days');
                    break;
                case 'homTruoc':
                    dateFrom = this.$moment(new Date().setHours(0, 0, 0, 0)).subtract(number, 'days');
                    dateTo = this.$moment(new Date().setHours(23, 59, 59, 999));
                    break;
                case 'tuanNay':
                    dateFrom = this.$moment(new Date().setHours(0, 0, 0, 0)).startOf('week');
                    dateTo = this.$moment(new Date().setHours(23, 59, 59, 999));
                    break;
                case 'tuanTruoc':
                    dateFrom = this.$moment(new Date().setHours(0, 0, 0, 0)).subtract(number, 'weeks').startOf('week');
                    dateTo = this.$moment(new Date().setHours(23, 59, 59, 999)).subtract(number, 'weeks').endOf('week');
                    break;
                case 'thangNay':
                    dateFrom = this.$moment(new Date().setHours(0, 0, 0, 0)).startOf('month');
                    dateTo = this.$moment(new Date().setHours(23, 59, 59, 999));
                    break;
                case 'thangTruoc':
                    dateFrom = this.$moment(new Date().setHours(0, 0, 0, 0)).subtract(number, 'months').startOf('month');
                    dateTo = this.$moment(new Date().setHours(23, 59, 59, 999)).subtract(number, 'months').endOf('month');
                    break;
            }
            return [dateFrom.toDate(), dateTo.toDate()]
        },
        getDateAdd(type, number) {
            let date = '';
            switch (type) {
                case 'day':
                    date = this.$moment().add(number, 'days');
                    break;
                case 'week':
                    date = this.$moment().add(number, 'weeks');
                    break;
                case 'month':
                    date = this.$moment().add(number, 'months');
                    break;
                case 'year':
                    date = this.$moment().add(number, 'years');
                    break;
            }
            return date.toDate();
        },
        getDateSubtract(type, number) {
            let date = '';
            switch (type) {
                case 'day':
                    date = this.$moment().subtract(number, 'days');
                    break;
                case 'week':
                    date = this.$moment().subtract(number, 'weeks');
                    break;
                case 'month':
                    date = this.$moment().subtract(number, 'months');
                    break;
                case 'year':
                    date = this.$moment().subtract(number, 'years');
                    break;
            }
            return date.toDate();
        },
        getFormatDate(date, format) {
            return date ? this.$moment(date).format(this.$root[format]) : '';
        },
        getFormatNumber(value) {
            if (value || value === 0) {
                let returnData = Math.round((value + Number.EPSILON) * 100) / 100;
                return returnData.toLocaleString(['vi-VN']);
            } else {
                return '-';
            }
        },
        groupBy(xs, key) {
            return xs.reduce(function (rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
            }, {});
        },
        randomPass() {
            const charUpStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
            const charLowStr = 'abcdefghijklmnopqrstuvwxyz';
            const numberStr = '0123456789';
            const specStr = '!@#$%';
            const randomCharUp = charUpStr.charAt(Math.floor(Math.random() * charUpStr.length));
            const randomCharLow = charLowStr.charAt(Math.floor(Math.random() * charLowStr.length));
            const randomNumber = numberStr.charAt(Math.floor(Math.random() * numberStr.length));
            const randomSpec = specStr.charAt(Math.floor(Math.random() * specStr.length));
            let counter = 0;
            let result = '';
            while (counter < 1) {
                result += randomCharUp;
                counter += 1;
            }
            while (counter < 4) {
                result += randomCharLow;
                counter += 1;
            }
            while (counter < 8) {
                result += randomNumber;
                counter += 1;
            }
            while (counter < 9) {
                result += randomSpec;
                counter += 1;
            }
            return result;
        },
        createCookie() {
            let userId = localStorage.getItem("userId");
            let token = localStorage.getItem("jwt");

            let today = new Date();
            let expire = new Date();
            expire.setTime(today.getTime() + 3600000 * 24 * 15);

            document.cookie = "userId=" + userId + ";path=/" + ";expires=" + expire.toUTCString();
            document.cookie = "token=" + encodeURI(token) + ";path=/" + ";expires=" + expire.toUTCString();
            //can only write one entity at a time (name, pass)
        },
        getCookie(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) {
                return parts.pop().split(';').shift();
            } else {
                return '';
            }
        },
        deleteCookie(name) {
            document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        },
        demNguocThoiGian(date) {
            var countDownDate = new Date(date).getTime();

            let _this = this;
            var x = setInterval(function () {
                var now = new Date().getTime();
                var distance = countDownDate - now;

                var days = Math.floor(distance / (1000 * 60 * 60 * 24));
                var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                var seconds = Math.floor((distance % (1000 * 60)) / 1000);

                _this.$root.demNguocNgay = (days).toString().length === 1 ? '0' + days : days;
                _this.$root.demNguocGio = (hours).toString().length === 1 ? '0' + hours : hours;
                _this.$root.demNguocPhut = (minutes).toString().length === 1 ? '0' + minutes : minutes;
                _this.$root.demNguocGiay = (seconds).toString().length === 1 ? '0' + seconds : seconds;

                if (distance < 0) {
                    _this.$root.demNguocNgay = '00';
                    _this.$root.demNguocGio = '00';
                    _this.$root.demNguocPhut = '00';
                    _this.$root.demNguocGiay = '00';
                    clearInterval(x);
                }
            }, 1000);
        }
    },
});

new Vue({
    router,
    store,
    render: (h) => h(App),
    data: {
        url: "https://be.zf1.us/api/v1",
        theme: "light",
        isLogin: false,
        isLoginLanDau: false,
        isMobile: false,
        isShowLoading: true,
        isAdmin: false,
        formatDate: "YYYY/MM/DD",
        formatDateTimeNoSecond: "YYYY/MM/DD HH:mm",
        formatDateTime: "YYYY/MM/DD HH:mm:ss",
        formatDateAPI: "YYYY-MM-DD",
        formatDateTimeNoSecondAPI: "YYYY-MM-DD HH:mm",
        formatDateTimeAPI: "YYYY-MM-DD HH:mm:ss",
        formatDateTimeFileName: "YYYY-MM-DD_HH-mm-ss",
        formatDateTimeNoSecondVN: "DD/MM/YYYY HH:mm",
        formatThoiGianDau: "HH:mm DD/MM/YYYY",
        localeData: {
            direction: 'ltr',
            format: 'dd/mm/yyyy',
            separator: ' tới ',
            applyLabel: 'Áp dụng',
            cancelLabel: 'Hủy',
            weekLabel: 'W',
            customRangeLabel: 'Custom Range',
            daysOfWeek: ['CN', 'Hai', 'Ba', 'Tư', 'Năm', 'Sáu', 'Bảy'],
            monthNames: ['Một', 'Hai', 'Ba', 'Bốn', 'Năm', 'Sáu', 'Bảy', 'Tám', 'Chín', 'Mười', 'Mười Một', 'Mười Hai'],
            firstDay: 1,
        },
        isConnectedSocket: false,
        isChangeRateUSD: false,
        rateUsd: 0,
        isSystemMaintenance: false,
        dataMaintenance: {
            description: "",
            endDate: "",
            isEnable: false,
            startDate: "",
            title: "",
        },
        demNguocNgay: "",
        demNguocGio: "",
        demNguocPhut: "",
        demNguocGiay: "",
        showHuongDan: false,
    },
}).$mount("#app");
