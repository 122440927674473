<template>
	<div id="kiem-tra-loi-lo" class="kiem-tra-loi-lo">
		<b-row class="header-page">
			<b-col cols="4">
				<span class="title-page">
					KIỂM TRA LỜI & LỖ
				</span>
			</b-col>
		</b-row>

		<b-row>
			<b-col>
				<div class="ty-gia-tham-khao p-2 mb-2">
					<div>
						Tỷ giá USD:
						<span class="ty-gia" @click="$bvModal.show('modal-chinh-sua-ty-gia')">
							{{ getFormatNumber(tyGia) }}
							<b-icon icon="pencil-fill"></b-icon>
							(Nhấn để sửa tỷ giá)
						</span>
					</div>
				</div>
			</b-col>
		</b-row>

		<b-row class="thong-tin-tong-hop">
			<!-- thông tin tra cứu -->
			<b-col cols="5">
				<div class="area-filter p-2">
					<b-row>
						<b-col cols="12" class="mb-2">
							<div class="text-left">Đầu Giao</div>
							<b-input-group>
								<multi-select v-model="dauGiaoDaChon" :options="dataDauGiao" :multiple="true"
									:close-on-select="false" :clear-on-select="false" :preserve-search="true"
									label="deliverName" track-by="id" :preselect-first="true" class="multi-select"
									placeholder="" @input="changeDauGiao()">
								</multi-select>
							</b-input-group>
						</b-col>
					</b-row>
					<b-row>
						<b-col cols="12" class="mb-2">
							<div class="text-left">Thời gian kiểm tra</div>
							<date-range-picker v-model="rangeDateSelected" :single-date-picker="false" opens="right"
								:locale-data="$root.localeData" :autoApply="true" :ranges="ranges"></date-range-picker>
						</b-col>
					</b-row>
					<b-row class="footer-thong-tin-tong-hop">
						<b-col>
							<b-button block variant="primary" :disabled="!$root.isConnectedSocket || isRunning"
								@click="profitWinLoss()">
								<img :src="getIcon('search-normal')" class="pr-2" />
								Kiểm Tra
							</b-button>
						</b-col>
						<b-col v-if="isRunning">
							<b-button block variant="primary" :disabled="!isRunning" @click="dungKiemTra()">
								<img :src="getIcon('timer-start')" class="pr-2" />
								Dừng
							</b-button>
						</b-col>
						<b-col v-if="!isRunning">
							<b-button block variant="primary" :disabled="isRunning || !isScanDone"
								@click="xuatBaoCao('PDF')" title="Xuất ảnh chất lượng cao">
								<b-icon icon="file-earmark-pdf" aria-hidden="true" class="mr-1"></b-icon>
								Hình Nét
							</b-button>
						</b-col>
						<b-col v-if="!isRunning">
							<b-button block variant="primary" :disabled="isRunning || !isScanDone"
								@click="xuatBaoCao('EXCEL')" title="Xuất bảng tính">
								<b-icon icon="file-earmark-excel" aria-hidden="true" class="mr-1"></b-icon>
								Bảng Tính
							</b-button>
						</b-col>
					</b-row>
				</div>
			</b-col>

			<!-- tiến độ & thông tin đang tra cứu -->
			<b-col cols="7">
				<div class="area-account-error">
					<b-row>
						<b-col cols="12" class="tab-btn mb-1">
							<div>
								<b-button size="sm" :variant="showTabAccount === '1' ? 'primary' : 'light'"
									@click="changeTabAccount('1')" class="mr-1"
									:class="{ 'active-tab': showTabAccount === '1' }">
									Tiến độ kiểm tra
								</b-button>
								<b-button size="sm" :variant="showTabAccount === '2' ? 'primary' : 'light'"
									@click="changeTabAccount('2')" class="mr-1"
									:class="{ 'active-tab': showTabAccount === '2' }">
									Tài khoản không có dữ liệu
									<span class="ml-1" v-if="dataAccount1.length > 0">({{ dataAccount1.length }})</span>
								</b-button>
								<b-button size="sm" :variant="showTabAccount === '3' ? 'primary' : 'light'"
									@click="changeTabAccount('3')" class="ml-1"
									:class="{ 'active-tab': showTabAccount === '3' }">
									Tài khoản lỗi
									<span class="ml-1" v-if="dataAccount2.length > 0">({{ dataAccount2.length }})</span>
								</b-button>
							</div>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<div v-if="showTabAccount === '1'" class="message-tien-do p-2">
								<template v-if="isRunning">
									<b-spinner v-if="isRunning" small></b-spinner>
									{{ percentHoanThanhScan }}%
									<b-progress :value="percentHoanThanhScan" :max="100" :animated="isRunning"
										variant="success"></b-progress>
									<div v-if="dataDangChay.status === 'START'">
										{{ dataDangChay.deliverName }}: Bắt đầu quét.
									</div>
									<div v-if="dataDangChay.status === 'PROCESSING'">
										{{ dataDangChay.deliverName }}:
										Đang quét tài khoản {{ dataDangChay.superName }}.
									</div>
								</template>
								<template v-else>
									<!-- Kết quả kiểm tra -->
									<b-row class="mt-2">
										<b-col>
											<div class="ket-qua-kiem-tra p-2">
												<b-row class="mb-2">
													<b-col cols="5" class="label-ket-qua-kiem-tra">
														<span>
															Kết quả kiểm tra
														</span>
													</b-col>
													<b-col cols="7" class="group-chu-thich">
														<div class="mr-2">
															Chú thích:
														</div>
														<div>
															<div>
																<div class="btn-icon-an-chia icon-loi mr-1">
																</div>
																Lời
															</div>
															<div>
																<div class="btn-icon-an-chia icon-lo mr-1">
																</div>
																Lỗ
															</div>
															<div>
																<div
																	class="btn-primary btn-icon-an-chia icon-an-chia-phan-tram mr-1">
																	<b-icon icon="people-fill"></b-icon>
																</div>
																Có chia %
															</div>
														</div>
													</b-col>
												</b-row>

												<b-row class="header-ket-qua mb-2">
													<b-col class="p-2">
														Turn Over
													</b-col>
													<b-col class="p-2">
														Thu Bù Khách
													</b-col>
													<b-col class="p-2">
														Lợi Nhuận
													</b-col>
												</b-row>

												<b-row class="body-ket-qua">
													<b-col class="p-2">
														<div :class="tongTurnOver < 0 ? 'so-am' : 'so-duong'">
															{{ getFormatNumber(tongTurnOver) }}
														</div>
													</b-col>
													<b-col class="p-2 group-ket-qua">
														<div class="mr-1">
															<span :class="tongTienThuKhachUSD < 0 ? 'so-am' : 'so-duong'">
																{{ getFormatNumber(tongTienThuKhachUSD) }}
															</span>
															<br>
															<span :class="tongTienThuKhachVND < 0 ? 'so-am' : 'so-duong'">
																{{ getFormatNumber(tongTienThuKhachVND) }}
															</span>
														</div>
														<div>
															<span class="color-usd">USD</span>
															<br>
															<span class="color-vnd">VNĐ</span>
														</div>
													</b-col>
													<b-col class="p-2">
														<div :class="ketQuaCuoiCung < 0 ? 'so-am' : 'so-duong'">
															<span class="mr-1">
																{{ getFormatNumber(ketQuaCuoiCung) }}
															</span>
															<span class="color-vnd">VNĐ</span>
														</div>
													</b-col>
												</b-row>
											</div>
										</b-col>
									</b-row>
								</template>
							</div>
							<div v-if="showTabAccount === '2'">
								<b-table sticky-header hover :head-variant="$root.theme" small show-empty
									emptyText="Không có dữ liệu" empty-filtered-text="Không có dữ liệu phù hợp tìm kiếm"
									:fields="fieldsAccount1" :items="dataAccount1">
									<template #cell(index)="data">
										<span>{{ data.index + 1 }}</span>
									</template>
								</b-table>
							</div>
							<div v-if="showTabAccount === '3'">
								<b-table sticky-header hover :head-variant="$root.theme" small show-empty
									emptyText="Không có dữ liệu" empty-filtered-text="Không có dữ liệu phù hợp tìm kiếm"
									:fields="fieldsAccount2" :items="dataAccount2">
									<template #cell(index)="data">
										<span>{{ data.index + 1 }}</span>
									</template>
								</b-table>
							</div>
						</b-col>
					</b-row>
				</div>
			</b-col>
		</b-row>

		<!-- Danh sách thắng thua chi tiết -->
		<b-row class="mt-2">
			<b-col>
				<div class="ket-qua-kiem-tra">
					<b-table ref="table-ket-qua-kiem-tra" hover :head-variant="$root.theme" small show-empty
						emptyText="Không có dữ liệu" empty-filtered-text="Không có dữ liệu phù hợp tìm kiếm"
						:tbody-tr-class="rowClass" :fields="fieldsWinLoss" :items="dataWinLoss" :busy="isRunning">
						<!-- head -->
						<template #head(deliverName)="scope">
							<div class="head-table-custom" @click="changeSort('deliverName')">
								<span class="label-dau-giao">{{ scope.label }}</span>
								<div class="group-icon-sort">
									<b-icon class="icon-sort" :icon="getIconSort('deliverName', 'up')">
									</b-icon>
									<b-icon class="icon-sort-down" :icon="getIconSort('deliverName', 'down')">
									</b-icon>
								</div>
							</div>
						</template>
						<template #head(totalTurnOver)="scope">
							<div class="head-table-custom" @click="changeSort('totalTurnOver')">
								<span>{{ scope.label }}</span>
								<div class="group-icon-sort">
									<b-icon class="icon-sort" :icon="getIconSort('totalTurnOver', 'up')">
									</b-icon>
									<b-icon class="icon-sort-down" :icon="getIconSort('totalTurnOver', 'down')">
									</b-icon>
								</div>
							</div>
						</template>
						<template #head(totalWinLoss)="scope">
							<div class="head-table-custom" @click="changeSort('totalWinLoss')">
								<span>{{ scope.label }}</span>
								<div class="group-icon-sort">
									<b-icon class="icon-sort" :icon="getIconSort('totalWinLoss', 'up')">
									</b-icon>
									<b-icon class="icon-sort-down" :icon="getIconSort('totalWinLoss', 'down')">
									</b-icon>
								</div>
							</div>
						</template>
						<template #head(totalCom)="scope">
							<div class="head-table-custom" @click="changeSort('totalCom')">
								<span>{{ scope.label }}</span>
								<div class="group-icon-sort">
									<b-icon class="icon-sort" :icon="getIconSort('totalCom', 'up')">
									</b-icon>
									<b-icon class="icon-sort-down" :icon="getIconSort('totalCom', 'down')">
									</b-icon>
								</div>
							</div>
						</template>
						<template #head(deliverTotalUsd)="scope">
							<div class="group-header-loai-tien">{{ scope.label }}</div>
							<div class="header-loai-tien">
								<div @click="changeSort('deliverTotalUsd')">
									<span class="color-usd">USD</span>
									<div class="group-icon-sort">
										<b-icon class="icon-sort" :icon="getIconSort('deliverTotalUsd', 'up')">
										</b-icon>
										<b-icon class="icon-sort-down" :icon="getIconSort('deliverTotalUsd', 'down')">
										</b-icon>
									</div>
								</div>
								<div @click="changeSort('deliverTotalVnd')">
									<span class="color-vnd">VNĐ</span>
									<div class="group-icon-sort">
										<b-icon class="icon-sort" :icon="getIconSort('deliverTotalVnd', 'up')">
										</b-icon>
										<b-icon class="icon-sort-down" :icon="getIconSort('deliverTotalVnd', 'down')">
										</b-icon>
									</div>
								</div>
							</div>
						</template>
						<template #head(receiverTotalUsd)="scope">
							<div class="group-header-loai-tien">{{ scope.label }}</div>
							<div class="header-loai-tien">
								<div @click="changeSort('receiverTotalUsd')">
									<span class="color-usd">USD</span>
									<div class="group-icon-sort">
										<b-icon class="icon-sort" :icon="getIconSort('receiverTotalUsd', 'up')">
										</b-icon>
										<b-icon class="icon-sort-down" :icon="getIconSort('receiverTotalUsd', 'down')">
										</b-icon>
									</div>
								</div>
								<div @click="changeSort('receiverTotalVnd')">
									<span class="color-vnd">VNĐ</span>
									<div class="group-icon-sort">
										<b-icon class="icon-sort" :icon="getIconSort('receiverTotalVnd', 'up')">
										</b-icon>
										<b-icon class="icon-sort-down" :icon="getIconSort('receiverTotalVnd', 'down')">
										</b-icon>
									</div>
								</div>
							</div>
						</template>
						<template #head(companyTotalUsd)="scope">
							<div class="group-header-loai-tien">{{ scope.label }}</div>
							<div class="header-loai-tien">
								<div @click="changeSort('companyTotalUsd')">
									<span class="color-usd">USD</span>
									<div class="group-icon-sort">
										<b-icon class="icon-sort" :icon="getIconSort('companyTotalUsd', 'up')">
										</b-icon>
										<b-icon class="icon-sort-down" :icon="getIconSort('companyTotalUsd', 'down')">
										</b-icon>
									</div>
								</div>
								<div @click="changeSort('companyTotalVnd')">
									<span class="color-vnd">VNĐ</span>
									<div class="group-icon-sort">
										<b-icon class="icon-sort" :icon="getIconSort('companyTotalVnd', 'up')">
										</b-icon>
										<b-icon class="icon-sort-down" :icon="getIconSort('companyTotalVnd', 'down')">
										</b-icon>
									</div>
								</div>
							</div>
						</template>
						<!-- body -->
						<template #cell(select)="data">
							<div @click="changeSelectedRow(data)" class="checkbox-icon"
								:class="data.item.selected ? 'checked' : ''">
								<img :src="getIcon(data.item.selected ? 'tick-square' : 'minus-square')" />
							</div>
						</template>
						<template #cell(deliverName)="data">
							<div class="btn-link ten-dau-giao" @click="toggleChiTietItem(data)">
								<span>{{ data.item.deliverName }}</span>
								<img class="ml-1" :class="{ 'rotate': data.detailsShowing }" :src="getIcon('arrow-down')" />
							</div>
						</template>
						<template #cell(totalTurnOver)="data">
							<span :class="data.item.totalTurnOver < 0 ? 'so-am' : 'so-duong'">
								{{ getFormatNumber(data.item.totalTurnOver) }}
							</span>
						</template>
						<template #cell(totalWinLoss)="data">
							<span :class="data.item.totalWinLoss < 0 ? 'so-am' : 'so-duong'">
								{{ getFormatNumber(data.item.totalWinLoss) }}
							</span>
						</template>
						<template #cell(totalCom)="data">
							<span>
								{{ getFormatNumber(data.item.totalCom) }}
							</span>
						</template>
						<template #cell(deliverTotalUsd)="data">
							<div class="cot-thanh-tien">
								<div :class="data.item.deliverTotalUsd < 0 ? 'so-am' : 'so-duong'">
									{{ getFormatNumber(data.item.deliverTotalUsd) }}
								</div>
								<div :class="data.item.deliverTotalVnd < 0 ? 'so-am' : 'so-duong'">
									{{ getFormatNumber(data.item.deliverTotalVnd) }}
								</div>
							</div>
						</template>
						<template #cell(receiverTotalUsd)="data">
							<div class="cot-thanh-tien">
								<div :class="data.item.receiverTotalUsd < 0 ? 'so-am' : 'so-duong'">
									{{ getFormatNumber(data.item.receiverTotalUsd) }}
								</div>
								<div :class="data.item.receiverTotalVnd < 0 ? 'so-am' : 'so-duong'">
									{{ getFormatNumber(data.item.receiverTotalVnd) }}
								</div>
							</div>
						</template>
						<template #cell(companyTotalUsd)="data">
							<div class="cot-thanh-tien">
								<div
									:class="(data.item.sharingUsd ? (data.item.sharingUsd < 0) : (data.item.companyTotalUsd < 0)) ? 'so-am' : 'so-duong'">
									{{ data.item.sharingUsd ? getFormatNumber(data.item.sharingUsd) :
										getFormatNumber(data.item.companyTotalUsd) }}
									<div v-if="data.item.sharingUsd" class="btn-primary btn-icon-an-chia ml-1"
										@click="showDsAnChiaDauKhach(data.item)">
										<b-icon icon="people-fill" title="Xem Danh Sách Đối Tác"></b-icon>
									</div>
								</div>
								<div
									:class="(data.item.sharingVnd ? (data.item.sharingVnd < 0) : (data.item.companyTotalVnd < 0)) ? 'so-am' : 'so-duong'">
									{{ data.item.sharingVnd ? getFormatNumber(data.item.sharingVnd) :
										getFormatNumber(data.item.companyTotalVnd) }}
									<div v-if="data.item.sharingVnd" class="btn-primary btn-icon-an-chia ml-1"
										@click="showDsAnChiaDauKhach(data.item)">
										<b-icon icon="people-fill" title="Xem Danh Sách Đối Tác"></b-icon>
									</div>
								</div>
							</div>
						</template>

						<template #table-busy>
							<div class="text-center text-danger my-2">
								<b-spinner class="align-middle mr-2"></b-spinner>
								<strong>Đang tải dữ liệu...</strong>
							</div>
						</template>

						<template #row-details="data">
							<div v-for="(item, index) in data.item.dataNhaCai" :key="index">
								<div class="title-row-details">
									<span>Nhà cái: {{ item.name }}</span>
									<span>
										<img class="logo-nha-cai" :src="getLogoNhaCai(item.name)" />
									</span>
								</div>
								<b-table hover :head-variant="$root.theme" small show-empty emptyText="Không có dữ liệu"
									empty-filtered-text="Không có dữ liệu phù hợp tìm kiếm" :fields="fieldsChiTiet"
									:items="item.data">
									<!-- head -->
									<template #head(domainName)="scope">
										<div class="head-table-custom" @click="changeSort('domainName', data.item)">
											<span>{{ scope.label }}</span>
											<div class="group-icon-sort">
												<b-icon class="icon-sort" :icon="getIconSort('domainName', 'up', 'con')">
												</b-icon>
												<b-icon class="icon-sort-down"
													:icon="getIconSort('domainName', 'down', 'con')">
												</b-icon>
											</div>
										</div>
									</template>
									<template #head(userName)="scope">
										<div class="head-table-custom" @click="changeSort('userName', data.item)">
											<span>{{ scope.label }}</span>
											<div class="group-icon-sort">
												<b-icon class="icon-sort" :icon="getIconSort('userName', 'up', 'con')">
												</b-icon>
												<b-icon class="icon-sort-down"
													:icon="getIconSort('userName', 'down', 'con')">
												</b-icon>
											</div>
										</div>
									</template>
									<template #head(type)="scope">
										<div class="head-table-custom" @click="changeSort('type', data.item)">
											<span>{{ scope.label }}</span>
											<div class="group-icon-sort">
												<b-icon class="icon-sort" :icon="getIconSort('type', 'up', 'con')">
												</b-icon>
												<b-icon class="icon-sort-down" :icon="getIconSort('type', 'down', 'con')">
												</b-icon>
											</div>
										</div>
									</template>
									<template #head(totalWinLoss)="scope">
										<div class="head-table-custom" @click="changeSort('totalWinLoss', data.item)">
											<span>{{ scope.label }}</span>
											<div class="group-icon-sort">
												<b-icon class="icon-sort" :icon="getIconSort('totalWinLoss', 'up', 'con')">
												</b-icon>
												<b-icon class="icon-sort-down"
													:icon="getIconSort('totalWinLoss', 'down', 'con')">
												</b-icon>
											</div>
										</div>
									</template>
									<template #head(totalCom)="scope">
										<div class="head-table-custom" @click="changeSort('totalCom', data.item)">
											<span>{{ scope.label }}</span>
											<div class="group-icon-sort">
												<b-icon class="icon-sort" :icon="getIconSort('totalCom', 'up', 'con')">
												</b-icon>
												<b-icon class="icon-sort-down"
													:icon="getIconSort('totalCom', 'down', 'con')">
												</b-icon>
											</div>
										</div>
									</template>
									<template #head(deliverTotalUsd)="scope">
										<div class="group-header-loai-tien">{{ scope.label }}</div>
										<div class="header-loai-tien">
											<div @click="changeSort('deliverTotalUsd', data.item)">
												<span class="color-usd">USD</span>
												<div class="group-icon-sort">
													<b-icon class="icon-sort"
														:icon="getIconSort('deliverTotalUsd', 'up', 'con')">
													</b-icon>
													<b-icon class="icon-sort-down"
														:icon="getIconSort('deliverTotalUsd', 'down', 'con')">
													</b-icon>
												</div>
											</div>
											<div @click="changeSort('deliverTotalVnd', data.item)">
												<span class="color-vnd">VNĐ</span>
												<div class="group-icon-sort">
													<b-icon class="icon-sort"
														:icon="getIconSort('deliverTotalVnd', 'up', 'con')">
													</b-icon>
													<b-icon class="icon-sort-down"
														:icon="getIconSort('deliverTotalVnd', 'down', 'con')">
													</b-icon>
												</div>
											</div>
										</div>
									</template>
									<template #head(receiverTotalUsd)="scope">
										<div class="group-header-loai-tien">{{ scope.label }}</div>
										<div class="header-loai-tien">
											<div @click="changeSort('receiverTotalUsd', data.item)">
												<span class="color-usd">USD</span>
												<div class="group-icon-sort">
													<b-icon class="icon-sort"
														:icon="getIconSort('receiverTotalUsd', 'up', 'con')">
													</b-icon>
													<b-icon class="icon-sort-down"
														:icon="getIconSort('receiverTotalUsd', 'down', 'con')">
													</b-icon>
												</div>
											</div>
											<div @click="changeSort('receiverTotalVnd', data.item)">
												<span class="color-vnd">VNĐ</span>
												<div class="group-icon-sort">
													<b-icon class="icon-sort"
														:icon="getIconSort('receiverTotalVnd', 'up', 'con')">
													</b-icon>
													<b-icon class="icon-sort-down"
														:icon="getIconSort('receiverTotalVnd', 'down', 'con')">
													</b-icon>
												</div>
											</div>
										</div>
									</template>
									<template #head(companyTotalUsd)="scope">
										<div class="group-header-loai-tien">{{ scope.label }}</div>
										<div class="header-loai-tien">
											<div @click="changeSort('companyTotalUsd', data.item)">
												<span class="color-usd">USD</span>
												<div class="group-icon-sort">
													<b-icon class="icon-sort"
														:icon="getIconSort('companyTotalUsd', 'up', 'con')">
													</b-icon>
													<b-icon class="icon-sort-down"
														:icon="getIconSort('companyTotalUsd', 'down', 'con')">
													</b-icon>
												</div>
											</div>
											<div @click="changeSort('companyTotalVnd', data.item)">
												<span class="color-vnd">VNĐ</span>
												<div class="group-icon-sort">
													<b-icon class="icon-sort"
														:icon="getIconSort('companyTotalVnd', 'up', 'con')">
													</b-icon>
													<b-icon class="icon-sort-down"
														:icon="getIconSort('companyTotalVnd', 'down', 'con')">
													</b-icon>
												</div>
											</div>
										</div>
									</template>
									<!-- body -->
									<template #cell(select)="data">
										<div @click="changeSelectedRow(data, item)" class="checkbox-icon"
											:class="data.item.selected ? 'checked' : ''">
											<img :src="getIcon(data.item.selected ? 'tick-square' : 'minus-square')" />
										</div>
									</template>
									<template #cell(userName)="data">
										<div v-if="data.item.status === 'ERROR'">
											<span class="error-text">{{ data.item.userName }} (Lỗi)</span>
										</div>
										<div v-else-if="data.item.level !== 4" :class="getClassLevel(data.item)"
											@click="toggleDongCon(data.item, item)">
											<span>
												{{ data.item.userName }}
											</span>
											<img class="ml-1" :class="{ 'rotate': data.item.showDongCon }"
												:src="getIcon('arrow-down')" />
										</div>
										<div v-else :class="getClassLevel(data.item)">
											<span>
												{{ data.item.userName }}
											</span>
										</div>
									</template>
									<template #cell(type)="data">
										<div v-if="data.item.winLoss">
											<div v-for="(itemWinLoss, index) in data.item.winLoss" :key="index">
												<span>
													{{ itemWinLoss.type }}
												</span>
												<hr v-if="index < data.item.winLoss.length - 1">
											</div>
										</div>
										<div v-else>
											<span>
												{{ getFormatNumber(data.item.type) }}
											</span>
										</div>
									</template>
									<template #cell(totalWinLoss)="data">
										<div v-if="data.item.winLoss">
											<div v-for="(itemWinLoss, index) in data.item.winLoss" :key="index">
												<span :class="itemWinLoss.totalWinLoss < 0 ? 'so-am' : 'so-duong'">
													{{ getFormatNumber(itemWinLoss.totalWinLoss) }}
												</span>
												<hr v-if="index < data.item.winLoss.length - 1">
											</div>
										</div>
										<div v-else>
											<span :class="data.item.totalWinLoss < 0 ? 'so-am' : 'so-duong'">
												{{ getFormatNumber(data.item.totalWinLoss) }}
											</span>
										</div>
									</template>
									<template #cell(totalCom)="data">
										<div v-if="data.item.winLoss">
											<div v-for="(itemWinLoss, index) in data.item.winLoss" :key="index">
												<span>
													{{ getFormatNumber(itemWinLoss.totalCom) }}
												</span>
												<hr v-if="index < data.item.winLoss.length - 1">
											</div>
										</div>
										<div v-else>
											<span>
												{{ getFormatNumber(data.item.totalCom) }}
											</span>
										</div>
									</template>
									<template #cell(deliverTotalUsd)="data">
										<div v-if="data.item.winLoss">
											<div v-for="(itemWinLoss, index) in data.item.winLoss" :key="index">
												<div class="cot-thanh-tien">
													<div :class="itemWinLoss.deliverTotalUsd < 0 ? 'so-am' : 'so-duong'">
														{{ getFormatNumber(itemWinLoss.deliverTotalUsd) }}
													</div>
													<div :class="itemWinLoss.deliverTotalVnd < 0 ? 'so-am' : 'so-duong'">
														{{ getFormatNumber(itemWinLoss.deliverTotalVnd) }}
													</div>
												</div>
												<hr v-if="index < data.item.winLoss.length - 1">
											</div>
										</div>
										<div v-else>
											<div class="cot-thanh-tien">
												<div :class="data.item.deliverTotalUsd < 0 ? 'so-am' : 'so-duong'">
													{{ getFormatNumber(data.item.deliverTotalUsd) }}
												</div>
												<div :class="data.item.deliverTotalVnd < 0 ? 'so-am' : 'so-duong'">
													{{ getFormatNumber(data.item.deliverTotalVnd) }}
												</div>
											</div>
										</div>
									</template>
									<template #cell(receiverTotalUsd)="data">
										<div v-if="data.item.winLoss">
											<div v-for="(itemWinLoss, index) in data.item.winLoss" :key="index">
												<div class="cot-thanh-tien">
													<div :class="itemWinLoss.receiverTotalUsd < 0 ? 'so-am' : 'so-duong'">
														{{ getFormatNumber(itemWinLoss.receiverTotalUsd) }}
													</div>
													<div :class="itemWinLoss.receiverTotalVnd < 0 ? 'so-am' : 'so-duong'">
														{{ getFormatNumber(itemWinLoss.receiverTotalVnd) }}
													</div>
												</div>
												<hr v-if="index < data.item.winLoss.length - 1">
											</div>
										</div>
										<div v-else>
											<div class="cot-thanh-tien">
												<div :class="data.item.receiverTotalUsd < 0 ? 'so-am' : 'so-duong'">
													{{ getFormatNumber(data.item.receiverTotalUsd) }}
												</div>
												<div :class="data.item.receiverTotalVnd < 0 ? 'so-am' : 'so-duong'">
													{{ getFormatNumber(data.item.receiverTotalVnd) }}
												</div>
											</div>
										</div>
									</template>
									<template #cell(companyTotalUsd)="data">
										<div v-if="data.item.winLoss">
											<div v-for="(itemWinLoss, index) in data.item.winLoss" :key="index">
												<div v-if="itemWinLoss.percentDeliverSuper < 100" class="cot-thanh-tien">
													<div :class="itemWinLoss.sharingUsd < 0 ? 'so-am' : 'so-duong'">
														{{ getFormatNumber(itemWinLoss.sharingUsd) }}
														{{ itemWinLoss.sharingUsd !== 0 ?
															`(${itemWinLoss.percentDeliverSuper}%)` : '' }}
														<div v-if="itemWinLoss.sharingUsd"
															class="btn-primary btn-icon-an-chia ml-1"
															@click="showDsAnChia(itemWinLoss)">
															<b-icon icon="people-fill"
																title="Xem Danh Sách Đối Tác"></b-icon>
														</div>
													</div>
													<div :class="itemWinLoss.sharingVnd < 0 ? 'so-am' : 'so-duong'">
														{{ getFormatNumber(itemWinLoss.sharingVnd) }}
														{{ itemWinLoss.sharingVnd !== 0 ?
															`(${itemWinLoss.percentDeliverSuper}%)` : '' }}
														<div v-if="itemWinLoss.sharingVnd"
															class="btn-primary btn-icon-an-chia ml-1"
															@click="showDsAnChia(itemWinLoss)">
															<b-icon icon="people-fill"
																title="Xem Danh Sách Đối Tác"></b-icon>
														</div>
													</div>
												</div>
												<div v-else class="cot-thanh-tien">
													<div :class="itemWinLoss.companyTotalUsd < 0 ? 'so-am' : 'so-duong'">
														{{ getFormatNumber(itemWinLoss.companyTotalUsd) }}
													</div>
													<div :class="itemWinLoss.companyTotalVnd < 0 ? 'so-am' : 'so-duong'">
														{{ getFormatNumber(itemWinLoss.companyTotalVnd) }}
													</div>
												</div>
												<hr v-if="index < data.item.winLoss.length - 1">
											</div>
										</div>
										<div v-else>
											<div v-if="data.item.percentDeliverSuper < 100" class="cot-thanh-tien">
												<div :class="data.item.sharingUsd < 0 ? 'so-am' : 'so-duong'">
													{{ getFormatNumber(data.item.sharingUsd) }}
													{{ data.item.sharingUsd !== 0 ? `(${data.item.percentDeliverSuper}%)` :
														'' }}
													<b-icon v-if="data.item.sharingUsd" icon="people-fill"
														class="ml-1 so-duong" title="Có chia %"></b-icon>
												</div>
												<div :class="data.item.sharingVnd < 0 ? 'so-am' : 'so-duong'">
													{{ getFormatNumber(data.item.sharingVnd) }}
													{{ data.item.sharingVnd !== 0 ? `(${data.item.percentDeliverSuper}%)` :
														'' }}
													<b-icon v-if="data.item.sharingVnd" icon="people-fill"
														class="ml-1 so-duong" title="Có chia %"></b-icon>
												</div>
											</div>
											<div v-else class="cot-thanh-tien">
												<div :class="data.item.companyTotalUsd < 0 ? 'so-am' : 'so-duong'">
													{{ getFormatNumber(data.item.companyTotalUsd) }}
												</div>
												<div :class="data.item.companyTotalVnd < 0 ? 'so-am' : 'so-duong'">
													{{ getFormatNumber(data.item.companyTotalVnd) }}
												</div>
											</div>
										</div>
									</template>
								</b-table>
							</div>
						</template>
					</b-table>
				</div>
			</b-col>
		</b-row>

		<!-- modal -->
		<b-modal centered id="modal-ds-an-chia" title="DANH SÁCH ĐỐI TÁC" scrollable size="md"
			:content-class="$root.isMobile ? 'style-for-mobile' : 'style-for-web'">
			<div class="d-block">
				<div class="header-ds-an-chia">
					<div>Đầu Giao</div>
					<div>Phân Trăm Theo</div>
				</div>
				<div v-for="(item, index) in dsAnChia" :key="index" class="body-ds-an-chia">
					<div>{{ item.deliverName }}</div>
					<div>{{ item.percent }}%</div>
				</div>
			</div>
			<template #modal-footer>
				<b-row class="w-100 mt-3">
					<b-col cols="8"></b-col>
					<b-col cols="4">
						<b-button block @click="$bvModal.hide('modal-ds-an-chia')">
							Hủy
						</b-button>
					</b-col>
				</b-row>
			</template>
		</b-modal>

		<b-modal centered id="modal-ds-an-chia-dau-khach" title="DANH SÁCH ĐỐI TÁC" scrollable size="lg"
			:content-class="$root.isMobile ? 'style-for-mobile' : 'style-for-web'">
			<div class="d-block">
				<div class="header-ds-an-chia">
					<div>Tài Khoản</div>
					<div>Nhà Cái</div>
					<div>Đầu Giao</div>
					<div>Phân Trăm Theo</div>
				</div>
				<div v-for="(item, index) in dsAnChiaDauKhach" :key="index" class="body-ds-an-chia">
					<div>{{ item.userName }}</div>
					<div>{{ item.domainName }}</div>
					<div>{{ item.deliverName }}</div>
					<div>{{ item.percent }}%</div>
				</div>
			</div>
			<template #modal-footer>
				<b-row class="w-100 mt-3">
					<b-col cols="8"></b-col>
					<b-col cols="4">
						<b-button block @click="$bvModal.hide('modal-ds-an-chia-dau-khach')">
							Hủy
						</b-button>
					</b-col>
				</b-row>
			</template>
		</b-modal>
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { Socket } from '../../../websocket/websocket'
import Deliver from "../../../service/deliver.service";
import Scanner from "../../../service/scanner.service";
import Accounting from "../../../service/accounting.service";

export default {
	name: "KiemTraLoiLo",
	data() {
		return {
			topicScannerId: '',
			dataDauGiao: [],
			dauGiaoDaChon: [
				{
					id: '0000',
					deliverName: 'Tất cả',
				}
			],
			ranges: {
				'Hôm Nay': this.getRangeDate('homNay'),
				'Hôm Qua': this.getRangeDate('homQua'),
				'Tuần Này': this.getRangeDate('tuanNay'),
				'Tuần Trước': this.getRangeDate('tuanTruoc'),
				'Tháng Này': this.getRangeDate('thangNay'),
				'Tháng Trước': this.getRangeDate('thangTruoc'),
			},
			rangeDateSelected: {
				startDate: this.getRangeDate('tuanTruoc')[0],
				endDate: this.getRangeDate('tuanTruoc')[1],
			},
			showTabAccount: '1',
			fieldsAccount1: [
				{ key: 'deliverName', label: 'Đầu Giao' },
				{ key: 'domainName', label: 'Nhà Cái' },
				{ key: 'userName', label: 'Tài Khoản' },
			],
			dataAccount1: [],
			fieldsAccount2: [
				{ key: 'deliverName', label: 'Đầu Giao' },
				{ key: 'domainName', label: 'Nhà Cái' },
				{ key: 'userName', label: 'Tài Khoản' },
				{ key: 'note', label: 'Chú Thích' },
			],
			dataAccount2: [],
			fieldsWinLoss: [
				{ key: 'select', label: '' },
				{ key: 'deliverName', label: 'Đầu Giao\n ' },
				{ key: 'totalTurnOver', label: 'Cược Thực Tế\nTurn Over' },
				{ key: 'totalWinLoss', label: 'Thắng Thua\nWinLoss' },
				{ key: 'totalCom', label: 'Hoa Hồng\nCom' },
				{ key: 'deliverTotalUsd', label: 'Tiền Thu Khách' },
				{ key: 'receiverTotalUsd', label: 'Tiền Bù Cái' },
				{ key: 'companyTotalUsd', label: 'Lợi Nhuận' },
			],
			fieldsChiTiet: [
				{ key: 'select', label: '' },
				{ key: 'userName', label: 'Tài Khoản' },
				{ key: 'type', label: 'Loại Chơi' },
				{ key: 'totalWinLoss', label: 'Thắng Thua\nWinLoss' },
				{ key: 'totalCom', label: 'Hoa Hồng\nCom' },
				{ key: 'deliverTotalUsd', label: 'Tiền Thu Khách' },
				{ key: 'receiverTotalUsd', label: 'Tiền Bù Cái' },
				{ key: 'companyTotalUsd', label: 'Lợi Nhuận' },
			],
			dataWinLoss: [],
			dataWinLossOrigin: [],
			dataDangChay: [],
			percentHoanThanhScan: 0,
			tongTurnOver: 0,
			tongThangThuaThucTe: 0,
			tongTienThuKhachUSD: 0,
			tongTienThuKhachVND: 0,
			tongTienBuCaiUSD: 0,
			tongTienBuCaiVND: 0,
			tongLoiNhuanUSD: 0,
			tongLoiNhuanVND: 0,
			tongLoiNhuanThucTeUSD: 0,
			tongLoiNhuanThucTeVND: 0,
			tongPhanTheoUSD: 0,
			tongPhanTheoVND: 0,
			isRunning: false,
			isScanDone: false,
			errorMsg: {
				'E-ZZZZ007': 'Xử lý không thành công.',
				'E-ZZZZ020': '{0} Trang web đang bảo trì hoặc không hoạt động.',
				'E-ZZZZ021': '{0} Web đang bảo trì.',
				'E-ZZZZ022': '{0} Đăng nhập không thành công.',
				'E-ZZZZ023': '{0} Không giải được Captcha.',
				'E-ZZZZ024': '{0} Nhập mã Pass không thành công.',
				'E-ZZZZ025': '{0} Không thu thập thông tin và lưu dữ liệu.',
				'E-ZZZZ026': '{0} Không lưu được dữ liệu.',
				'E-ZZZZ027': '{0} Không đọc được dữ liệu.',
				'E-ZZZZ028': '{0} Cần thay đổi mật khẩu.',
				'E-ZZZZ029': '{0} Mật khẩu sai.',
				'E-ZZZZ030': '{0} Không xử lý được dữ liệu thu thập thông tin công việc.',
				'E-ZZZZ031': '{0} Không thành công.',
				'E-ZZZZ032': '{0} Bị văng khi đang đọc, có ai đó đang đăng nhập.',
				'E-ZZZZ033': '{0} Login có mã OTP. Hãy vô hiệu hóa để đăng nhập tự đông!',
				'E-ZZZZ034': '{0} Không thành công. \r\n {1}',
				'E-ZZZZ035': 'File không đúng định dạng.',
				'E-ZZZZ036': 'Lỗi server vui lòng liên hệ support!',
				'E-ZZZZ037': '{0} Sai username hoặc password.',
				'E-ZZZZ038': '{0} số không được âm.',
				'E-ZZZZ039': '{0} không hỗ trợ tài khoản này.',
			},
			sortBy: '',
			sortDesc: true,
			sortConBy: '',
			sortConDesc: true,
			dsAnChia: [],
			dsAnChiaDauKhach: [],
		};
	},
	watch: {
		"$root.isConnectedSocket": {
			handler(val) {
				if (!val) {
					this.dungKiemTra();
				}
			},
			deep: true,
		},
	},
	computed: {
		...mapState("KiemTraLoiLo", []),
		ketQuaCuoiCung() {
			return (this.tongLoiNhuanThucTeUSD * this.tyGia) + this.tongLoiNhuanThucTeVND;
		},
		tyGia() {
			return Number(this.$root.rateUsd);
		},
	},
	beforeCreate() {
		console.log("I have not been created yet!");
	},
	created() {
		console.log("I have just been created!");
	},
	beforeMount() {
		console.log("I am about to be mounted to the DOM!");
	},
	mounted() {
		console.log("I am mounted to the DOM!");
		this.forceMovePage();
		this.getDeliver();
		window.addEventListener('keydown', this.keydown);
	},
	beforeDestroy() {
		console.log("I am about to be removed from the DOM!");
	},
	destroyed() {
		console.log("I no longer exist...");
		window.removeEventListener('keydown', this.keydown);
	},
	methods: {
		...mapActions("KiemTraLoiLo", []),
		keydown(e) {
			let key = e.keyCode || e.which;
			let isForcusDropdown = e.target.className === 'multiselect__input';
			if (isForcusDropdown) {
				return;
			}
			switch (key) {
				case 13: // enter
					if (!this.$root.isChangeRateUSD) {
						this.profitWinLoss();
						e.preventDefault();
					}
					break;
				case 27: // esc
					this.dungKiemTra();
					e.preventDefault();
					break;
			}
		},
		getDeliver() {
			let _this = this;
			Deliver.getTree(this.$root.url)
				.then((response) => {
					_this.dataDauGiao = response.data;
					_this.dataDauGiao.unshift({
						id: '0000',
						deliverName: 'Tất cả',
					})
				}).catch((error) => {
					_this.errorApi(error, _this);
				});
		},
		profitWinLoss() {
			let deliverIds = [];
			for (const deliver of this.dauGiaoDaChon) {
				if (deliver.id !== '0000') {
					deliverIds.push(deliver.id)
				}
			}
			let data = {
				startDate: this.$moment(new Date(this.rangeDateSelected.startDate).setHours(0, 0, 0, 0)).format(),
				endDate: this.$moment(new Date(this.rangeDateSelected.endDate).setHours(23, 59, 59, 999)).format(),
				deliverIds: deliverIds,
			}
			let _this = this;
			_this.dataWinLoss = [];
			Scanner.profitWinLoss(this.$root.url, data, true)
				.then((response) => {
					_this.isRunning = true;
					_this.isScanDone = false;
					if (_this.topicScannerId) {
						Socket.onUnsubscribe(_this.topicScannerId);
					}
					_this.percentHoanThanhScan = 0;
					_this.tongThangThuaThucTe = 0;
					_this.tongTurnOver = 0;
					_this.tongThangThuaThucTe = 0;
					_this.tongTienThuKhachUSD = 0;
					_this.tongTienThuKhachVND = 0;
					_this.tongTienBuCaiUSD = 0;
					_this.tongTienBuCaiVND = 0;
					_this.tongLoiNhuanUSD = 0;
					_this.tongLoiNhuanVND = 0;
					_this.tongLoiNhuanThucTeUSD = 0;
					_this.tongLoiNhuanThucTeVND = 0;
					_this.tongPhanTheoUSD = 0;
					_this.tongPhanTheoVND = 0;
					_this.topicScannerId = response.data.topicId;
					_this.dataAccount1 = [];
					_this.dataAccount2 = [];
					_this.sortBy = '';
					_this.sortDesc = true;
					_this.sortConBy = '';
					_this.sortConDesc = true;
					Socket.onWatchScanner(_this.topicScannerId, _this.onWatchScanner);
				})
				.catch((error) => {
					_this.isRunning = false;
					_this.errorApi(error, _this);
				});
		},
		async getDetail(deliverId, item) {
			let data = {
				accountId: null,
				startDate: this.$moment(new Date(this.rangeDateSelected.startDate).setHours(0, 0, 0, 0)).format(),
				endDate: this.$moment(new Date(this.rangeDateSelected.endDate).setHours(23, 59, 59, 999)).format(),
				level: item.level,
				superId: item.superId,
				userId: item.userId,
				userName: item.userName,
				deliverId: deliverId,
			};
			let _this = this;
			let dataReturn = [];
			await Scanner.detail(this.$root.url, data)
				.then((response) => {
					if (response.data.status === "500" || response.data.status === "ERROR") {
						let error = {
							message: response.data.code + ': ' + this.errorMsg[response.data.code].replace('{0}', ''),
							code: response.data.code,
							time: 5000,
						}
						_this.errorApi(error, _this);
					} else {
						dataReturn = response.data ? response.data.winLossData.childMembers : [];
					}
				})
				.catch((error) => {
					_this.errorApi(error, _this);
				});
			return dataReturn;
		},
		dungKiemTra() {
			this.isRunning = false;
			// this.dataDangChay = '';
			// this.percentHoanThanhScan = 0;
			Socket.onUnsubscribe(this.topicScannerId);
			let _this = this;
			setTimeout(() => {
				_this.$refs['table-ket-qua-kiem-tra'].refresh();
			}, 100);
		},
		xuatBaoCao(loaiFile) {
			let deliverWinLoss = [];
			for (const item of this.dataWinLoss) {
				let winLossDetails = [];
				for (const item1 of item.dataNhaCai) {
					for (const item2 of item1.data) {
						if (item2.selected) {
							for (const item3 of item2.winLoss) {
								item3.domainId = item2.domainId;
								item3.domainName = item2.domainName;
								item3.level = item2.level;
								item3.superId = item2.superId;
								item3.userId = item2.userId;
								item3.userName = item2.userName;
								winLossDetails.push(item3);
							}
						}
					}
				}
				if (winLossDetails.length > 0) {
					deliverWinLoss.push({
						deliverId: item.deliverId,
						deliverName: item.deliverName,
						winLossDetails: this.cloneDeep(winLossDetails),
					})
				}
			}
			console.log(deliverWinLoss);
			if (deliverWinLoss.length === 0) {
				this.showToast("Chưa chọn dữ liệu để xuất báo cáo", "Lỗi xuất báo cáo", this, "danger");
				return;
			}
			let data = {
				deliverWinLoss: deliverWinLoss,
				startDate: this.$moment(new Date(this.rangeDateSelected.startDate).setHours(0, 0, 0, 0)).format(),
				endDate: this.$moment(new Date(this.rangeDateSelected.endDate).setHours(23, 59, 59, 999)).format(),
			};
			let _this = this;
			Accounting.printInvoiceExcel(this.$root.url, data, loaiFile, true)
				.then((response) => {
					let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
					let fileName = '';
					let matches = filenameRegex.exec(response.headers["content-disposition"]);
					if (matches != null && matches[1]) {
						fileName = matches[1].replace(/['"]/g, '');
					}
					let type = '';
					if (loaiFile === 'EXCEL') {
						type = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,"
					} else {
						type = 'application/pdf';
					}
					let downloadLink = window.document.createElement('a');
					downloadLink.href = window.URL.createObjectURL(new Blob([response.data], { type: type }),);
					downloadLink.download = fileName;
					document.body.appendChild(downloadLink);
					downloadLink.click();
					document.body.removeChild(downloadLink);
				})
				.catch((error) => {
					_this.errorApi(error, _this);
				});
		},
		changeSelectedRow(data) {
			data.item.selected = !data.item.selected;
			if (data.item.deliverId) {
				for (const nhaCai of data.item.dataNhaCai) {
					for (const item of nhaCai.data) {
						item.selected = data.item.selected;
						if (item && item.childMembers) {
							for (const item1 of item.childMembers) {
								item1.selected = data.item.selected;
							}
						}
					}
				}
			} else {
				this.setSelectedRowCon(data.item);
			}
		},
		setSelectedRowCon(data) {
			if (data.dataDongCon) {
				for (const item of data.dataDongCon) {
					item.selected = data.selected;
					if (item.dataDongCon) {
						this.setSelectedRowCon(item);
					}
				}
			}
		},
		onWatchScanner(message) {
			let dataScanner = JSON.parse(message);
			if (dataScanner.verb !== 'UPDATE_STATUS') {
				this.percentHoanThanhScan = dataScanner.percent;
			}
			let agentDetailDto = dataScanner.data;
			if (agentDetailDto) {
				let item = agentDetailDto;
				if (item.status === 'ERROR') {
					let idx = this.dataAccount2.findIndex(i => i.userName === item.winLossData.userName && i.deliverId === item.deliverId);
					if (idx === -1) {
						item.index = this.cloneDeep(this.dataAccount2.length + 1);
						let userName = item.winLossData.userName ? item.winLossData.userName : '';
						let messageError = this.errorMsg[item.code] ? this.errorMsg[item.code].replace('{0}', userName) : '';
						let error = {
							message: messageError,
							code: item.code ? item.code : '',
							time: 5000,
						}
						this.errorApi(error, this);
						this.dataAccount2.push({
							deliverId: item.deliverId,
							deliverName: item.deliverName,
							domainName: item.winLossData.domainName,
							userName: item.winLossData.userName,
							note: item.code ? item.code + ': ' + messageError : 'Lỗi không xác định.',
						})
					}
				} else {
					if (Number(item.totalWinLoss) === 0) {
						let idx = this.dataAccount1.findIndex(i => i.userName === item.winLossData.userName && i.deliverId === item.deliverId);
						if (idx === -1) {
							this.dataAccount1.push({
								deliverId: item.deliverId,
								deliverName: item.deliverName,
								domainName: item.winLossData.domainName,
								userName: item.winLossData.userName,
							})
						}
					}
					this.getDataNhaCai(agentDetailDto);
				}
			}
			let index = -1;
			switch (dataScanner.verb) {
				case 'UPDATE':
					if (agentDetailDto) {
						index = this.dataWinLoss.findIndex(i => i.deliverId === agentDetailDto.deliverId);
						if (index === -1) {
							agentDetailDto.index = this.dataWinLoss.length + 1;
							agentDetailDto.selected = false;
							agentDetailDto.level === 1;
							this.dataWinLoss.push(this.cloneDeep(agentDetailDto));
						} else {
							agentDetailDto.index = index;
							agentDetailDto.selected = false;
							if (!this.dataWinLoss[index].dataNhaCai) {
								this.dataWinLoss[index].dataNhaCai = [];
							}
							if (agentDetailDto.dataNhaCai) {
								for (const item of agentDetailDto.dataNhaCai) {
									let itemNhaCai = this.dataWinLoss[index].dataNhaCai.filter(i => i.name === item.name)[0];
									if (itemNhaCai) {
										item.data[0].indexCuaDong = this.cloneDeep(item.data.length);
										itemNhaCai.data.push(item.data[0]);
									} else {
										this.dataWinLoss[index].dataNhaCai.push(item);
									}
								}
							}
						}
					}
					break;
				case 'NEW':
					if (agentDetailDto) {
						agentDetailDto.index = this.dataWinLoss.length + 1;
						agentDetailDto.selected = false;
						this.dataWinLoss.push(this.cloneDeep(agentDetailDto));
					}
					break;
				case 'FINISH':
					this.isScanDone = true;
					this.dataDangChay = '';
					this.dungKiemTra(this.topicScannerId)
					for (const item of this.dataWinLoss) {
						if (item) {
							let percentDeliverSuper = item.percentDeliverSuper;
							if (item.winLossData.winLoss && item.winLossData.winLoss.length > 0) {
								for (const item1 of item.winLossData.winLoss) {
									if (item1.percentDeliverSuper < 100) {
										percentDeliverSuper = item1.percentDeliverSuper;
										break;
									}
								}
							}
							this.tongTurnOver += item.totalTurnOver ? Number(item.totalTurnOver) : 0;
							this.tongThangThuaThucTe += item.totalWinLoss ? Number(item.totalWinLoss) : 0;
							this.tongTienThuKhachUSD += item.deliverTotalUsd ? Number(item.deliverTotalUsd) : 0;
							this.tongTienThuKhachVND += item.deliverTotalVnd ? Number(item.deliverTotalVnd) : 0;
							this.tongTienBuCaiUSD += item.receiverTotalUsd ? Number(item.receiverTotalUsd) : 0;
							this.tongTienBuCaiVND += item.receiverTotalVnd ? Number(item.receiverTotalVnd) : 0;
							this.tongLoiNhuanUSD += item.companyTotalUsd ? Number(item.companyTotalUsd) : 0;
							this.tongLoiNhuanVND += item.companyTotalVnd ? Number(item.companyTotalVnd) : 0;
							this.tongPhanTheoUSD += percentDeliverSuper < 100 ? Number(item.sharingUsd) : 0;
							this.tongPhanTheoVND += percentDeliverSuper < 100 ? Number(item.sharingVnd) : 0;
						}
					}
					this.tongLoiNhuanThucTeUSD = this.tongLoiNhuanUSD - this.tongPhanTheoUSD;
					this.tongLoiNhuanThucTeVND = this.tongLoiNhuanVND - this.tongPhanTheoVND;
					break;
				case 'UPDATE_STATUS':
					this.dataDangChay = agentDetailDto;
					break;
			}
			this.dataWinLossOrigin = this.cloneDeep(this.dataWinLoss);
		},
		changeTabAccount(number) {
			this.showTabAccount = number;
			switch (number) {
				case '2':
					this.fieldsAccount = [
						{ key: 'index', label: 'STT' },
						{ key: 'name', label: 'Tên Đại Lý' },
						{ key: 'note', label: 'Chú Thích' },
					];
					this.dataAccount = [];
					break;
				case '3':
					this.fieldsAccount = [
						{ key: 'index', label: 'STT' },
						{ key: 'name', label: 'Tên Đại Lý' },
						{ key: 'note', label: 'Chú Thích' },
					];
					this.dataAccount = [];
					break;
			}
		},
		toggleChiTietItem(data) {
			data.toggleDetails();
		},
		async toggleDongCon(data, dataNhaCai, isForceClose) {
			// get nhà cái
			let nhaCaiCuaDongCha = dataNhaCai.data;

			// get data dòng con
			let indexDongCha = nhaCaiCuaDongCha.findIndex(i => i.indexCuaDong === data.indexCuaDong);
			let dataDongCon = data.dataDongCon ? data.dataDongCon : [];
			data.showDongCon = isForceClose ? !isForceClose : !data.showDongCon;
			if (dataDongCon.length === 0) {
				if (data && data.childMembers) {
					dataDongCon = data.childMembers;
				} else if (data.level) {
					dataDongCon = await this.getDetail(dataNhaCai.deliverId, data);
				}
				for (const [index, item] of dataDongCon.entries()) {
					item.indexDongCha = data.indexCuaDong;
					item.indexCuaDong = indexDongCha + '-' + index;
					item.levelDongNgoaiCung = data.levelDongNgoaiCung;
					item.selected = nhaCaiCuaDongCha[indexDongCha].selected;
				}
			}
			if (dataDongCon.length === 0) {
				data.showDongCon = !data.showDongCon;
			}
			data.dataDongCon = dataDongCon;

			// ẩn/hiển dong con
			if (data.showDongCon) {
				for (const item of dataDongCon) {
					nhaCaiCuaDongCha.splice(indexDongCha + 1, 0, item);
					indexDongCha++;
				}
			} else {
				for (const item of dataDongCon) {
					let itemDongCon = nhaCaiCuaDongCha.filter(i => i.userName === item.userName)[0];
					if (itemDongCon && itemDongCon.showDongCon) {
						this.toggleDongCon(itemDongCon, dataNhaCai, true);
					}
					nhaCaiCuaDongCha.splice(indexDongCha + 1, 1);
				}
			}
		},
		changeSort(key, data) {
			if (data) {
				if (!data.winLossDataOrigin) {
					data.winLossDataOrigin = this.cloneDeep(data.winLossData);
				}
				if (this.sortConBy !== key) {
					this.sortConBy = key;
					this.sortConDesc = false;
					data.winLossData = this.cloneDeep(data.winLossDataOrigin);
				} else {
					this.sortConDesc = !this.sortConDesc;
				}
				data.winLossData.sort((a, b) => {
					let dk = '';
					if (this.sortConDesc) {
						dk = b[key] - a[key];
					} else {
						dk = a[key] - b[key];
					}
					return dk;
				});
			} else {
				if (this.sortBy !== key) {
					this.sortBy = key;
					this.sortDesc = false;
					this.dataWinLoss = this.cloneDeep(this.dataWinLossOrigin);
				} else {
					this.sortDesc = !this.sortDesc;
				}
				this.dataWinLoss.sort((a, b) => {
					let dk = '';
					if (this.sortDesc) {
						dk = b[key] - a[key];
					} else {
						dk = a[key] - b[key];
					}
					return dk;
				});
			}
		},
		getIconSort(key, type, typeList) {
			if (typeList === 'con') {
				if (this.sortConBy === key) {
					if (this.sortConDesc) {
						return type === 'up' ? 'triangle' : 'triangle-fill';
					} else {
						return type === 'up' ? 'triangle-fill' : 'triangle';
					}
				}
				return 'triangle';
			} else {
				if (this.sortBy === key) {
					if (this.sortDesc) {
						return type === 'up' ? 'triangle' : 'triangle-fill';
					} else {
						return type === 'up' ? 'triangle-fill' : 'triangle';
					}
				}
				return 'triangle';
			}
		},
		getDataNhaCai(data) {
			if (!data.dataNhaCai) {
				data.dataNhaCai = [];
			}
			let item = data.winLossData;
			let dataDomain = [];
			if (item) {
				let idx = data.dataNhaCai.findIndex(i => i.name === item.domainName);
				if (idx === -1) {
					data.dataNhaCai.push({
						name: item.domainName,
						deliverId: data.deliverId,
						data: dataDomain,
					});
				} else {
					dataDomain = data.dataNhaCai[idx].data;
				}
				item.selected = false;
				item.levelDongNgoaiCung = item.level;
				if (item) {
					for (const item2 of item.childMembers) {
						item2.selected = false;
					}
				}
				dataDomain.push(item);
			}
		},
		getLevel(level) {
			return level ? level - 1 : 0;
		},
		getClassLevel(item) {
			let classStr = '';
			let levelColor = item.level;
			let levelPadding = item.level;
			if (item.levelDongNgoaiCung) {
				levelPadding = item.level - (item.levelDongNgoaiCung - 1);
			}
			switch (levelPadding) {
				case 1:
				case 2:
				case 3:
					classStr = `btn-link color-level-${levelColor} padding-level-${levelPadding}`;
					break;
				case 4:
					classStr = `color-level-${levelColor} padding-level-${levelPadding}`;
					break;
			}
			return classStr;
		},
		rowClass(item, type) {
			if (!item || type !== 'row') {
				return;
			}

			let percentDeliverSuper = item.percentDeliverSuper;
			if (item.winLossData.winLoss && item.winLossData.winLoss.length > 0) {
				for (const item1 of item.winLossData.winLoss) {
					if (item1.percentDeliverSuper < 100) {
						percentDeliverSuper = item1.percentDeliverSuper;
						break;
					}
				}
			}
			if (percentDeliverSuper && percentDeliverSuper < 100) {
				return 'is-chia-gia';
			}
		},
		showDsAnChia(item) {
			this.dsAnChia = item.sharers;
			this.$bvModal.show('modal-ds-an-chia');
		},
		showDsAnChiaDauKhach(data) {
			this.dsAnChiaDauKhach = [];
			for (const item of data.dataNhaCai) {
				for (const item1 of item.data) {
					for (const item2 of item1.winLoss) {
						for (const item3 of item2.sharers) {
							let idx = this.dsAnChiaDauKhach.findIndex(i => i.domainId === item1.domainId && i.userName === item1.userName);
							if (idx === -1) {
								let obj = {
									domainId: item1.domainId,
									domainName: item1.domainName,
									userName: item1.userName,
									deliverId: item3.deliverId,
									deliverName: item3.deliverName,
									percent: item3.percent,
								}
								this.dsAnChiaDauKhach.push(obj);
							}
						}
					}
				}
			}
			console.log(this.dsAnChiaDauKhach);
			this.$bvModal.show('modal-ds-an-chia-dau-khach');
		},
		changeDauGiao() {
			let indexTatCa = this.dauGiaoDaChon.findIndex(i => i.id === '0000');
			if (indexTatCa !== -1 && indexTatCa === 0) {
				this.dauGiaoDaChon.splice(indexTatCa, 1);
			}
			if (indexTatCa !== -1 && indexTatCa !== 0) {
				this.dauGiaoDaChon = {
					id: '0000',
					deliverName: 'Tất cả',
				}
			}
		},
	},
};
</script>

<style lang="scss">
.style-for-web .kiem-tra-loi-lo {
	table {
		tr {
			td {
				font-size: 12px;
				white-space: pre-wrap;
				word-break: break-all;

				hr {
					margin: 5px -5px;
					border-color: var(--table-hr-border);
				}
			}
		}
	}

	.ket-qua-kiem-tra {
		table {
			border-radius: 8px;
			font-size: 12px;

			tr {
				th {
					border-top: none;

					&:first-child {
						border-top-left-radius: 8px;
					}

					&:last-child {
						border-top-right-radius: 8px;
					}

					&:nth-child(1) {
						width: 24px;
					}

					&:nth-child(2) {
						width: 150px;
					}

					&:nth-child(3),
					&:nth-child(4),
					&:nth-child(5) {
						width: 115px;
					}

					&:nth-child(6),
					&:nth-child(7),
					&:nth-child(8) {
						width: 230px;
					}
				}
			}

			.is-chia-gia {
				// td {
				// 	background-color: var(--is-chia-gia-background);
				// }
			}
		}
	}

	.label-ket-qua-kiem-tra {
		display: flex;
		align-items: center;
		font-size: 0.9rem;
	}

	.btn-icon-an-chia {
		min-height: 18px;
		min-width: 20px;
		border-radius: 8px;
		text-align: center;
		cursor: pointer;

		.b-icon {
			margin: auto;
			height: 100%;
			font-size: 12px;
		}

		&.icon-loi {
			background-color: var(--text-so-duong-color);
		}

		&.icon-lo {
			background-color: var(--text-so-am-color);
		}

		&.icon-an-chia-phan-tram {
			background-color: var(--btn-primary-color);
		}
	}

	.checkbox-icon {
		border-radius: 5px;
		height: 15px;
		width: 15px;
		display: flex;
		margin-top: 3px;

		img {
			filter: var(--img-filter-icon-menu) !important;
			height: 15px;
			width: 15px;
		}

		&.checked {
			background: var(--btn-link);

			img {
				filter: var(--img-filter) !important;
			}
		}
	}

	.title-row-details {
		display: inline-block;
		margin-bottom: 5px;
		padding: 5px;
		border-radius: 8px;
		background-color: var(--ten-nha-cai-back-color);
		color: var(--white-color);
		font-weight: bold;
		font-size: 14px;

		span {
			vertical-align: middle;
		}
	}

	.label-dau-giao {
		color: var(--btn-primary-color);
	}

	.ten-dau-giao {
		color: var(--ten-dau-giao-color);
		background-color: var(--ten-dau-giao-back-color);
		border-radius: 8px;
		padding: 2px 5px;
		margin-right: 15px;
		font-weight: bold;
	}

	.head-table-custom {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		cursor: pointer;
	}

	.group-header-loai-tien {
		margin-bottom: 5px;
		border-bottom: 1px solid var(--modal-input-border);
	}

	.header-loai-tien {
		display: flex;

		>div {
			width: 50%;
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
			cursor: pointer;

			&:last-child {
				margin-left: 3px;
			}
		}
	}

	.cot-thanh-tien {
		display: flex;

		>div {
			width: 50%;
		}

		.so-am {
			display: flex;
		}

		.so-duong {
			display: flex;
		}
	}

	.group-icon-sort {
		position: relative;
		height: 15px;
		width: 15px;
		margin-bottom: 3px;
		text-align: center;

		.icon-sort {
			font-size: 7px;
			position: absolute;
			top: 0;
			right: 0;
		}

		.icon-sort-down {
			font-size: 7px;
			transform: rotate(180deg);
			position: absolute;
			bottom: 0;
			right: 0;
		}
	}

	.ty-gia-tham-khao {
		background: var(--background-ty-gia);
		border-radius: 8px;
		color: var(--black-color);
		font-weight: bold;

		.ty-gia {
			cursor: pointer;

			&:hover {
				color: #007bff;
			}
		}

		img {
			height: 18px;
			width: 18px;
		}
	}

	.thong-tin-tong-hop {
		.area-filter {
			background: var(--menu-bar-background);
			border-radius: 8px;
			box-shadow: 0px 2px 6px var(--active-tab-box-shadow);
			min-height: 220px;
			background-color: var(--menu-bar-background);
		}

		.footer-thong-tin-tong-hop {
			.btn {
				padding-left: 0;
				padding-right: 0;
			}

			img {
				filter: var(--img-filter) !important;
			}
		}

		.area-account-error {
			min-height: 220px;

			.tab-btn {
				justify-content: flex-start;

				>div {
					padding: 5px;
				}
			}

			.message-tien-do {
				background: var(--menu-bar-background);
				border-radius: 8px;
				font-weight: bold;
				box-shadow: 0px 2px 6px var(--active-tab-box-shadow);
			}

			.b-table-sticky-header {
				border-radius: 8px;
				height: calc(300px - (41px + 0.25rem));
				box-shadow: 0px 2px 6px var(--active-tab-box-shadow);
				margin-bottom: 0;
			}

			.b-table-empty-row {
				border-radius: 8px;
				height: calc(300px - (77px + 0.25rem));
			}
		}
	}

	.ket-qua-kiem-tra {
		background: var(--menu-bar-background);
		border-radius: 8px;
		box-shadow: 0px 2px 6px var(--active-tab-box-shadow);
		font-size: 12px;

		.row {
			margin: 0;
		}

		.header-ket-qua {
			background: var(--header-ket-qua-background);
			border-radius: 8px;
			font-weight: bold;
		}

		.body-ket-qua {
			background: var(--header-ket-qua-background);
			border-radius: 8px;
			align-items: center;

			.group-ket-qua {
				display: flex;
			}
		}
	}

	.error-text,
	.so-am {
		color: var(--text-so-am-color);
		white-space: nowrap;
		font-size: 12px;
	}

	.so-duong {
		color: var(--text-so-duong-color);
		white-space: nowrap;
		font-size: 12px;
	}

	.text-binh-thuong {
		font-size: 12px;
	}

	.group-chu-thich {
		display: flex;
		justify-content: end;

		>div {
			display: flex;
			justify-content: end;
			align-items: center;
			flex-wrap: wrap;
			white-space: nowrap;

			&:first-child {
				width: calc(100% - 100px);
			}

			&:last-child {
				width: 100px;
			}

			>div {
				display: flex;
				align-items: center;
				width: 50%;
				padding: 1px;

				&:last-child {
					width: 100%;
				}
			}
		}

		.btn-icon-an-chia {
			cursor: default;
		}
	}
}

#modal-ds-an-chia .style-for-web {
	.d-block {
		background-color: var(--ds-an-chia-background);
		border-radius: 8px;
		box-shadow: 0px 2px 6px var(--active-tab-box-shadow);

		.header-ds-an-chia {
			display: flex;
			border-bottom: 2px solid var(--modal-input-border);
			background-color: var(--header-ds-an-chia-background);
			border-top-left-radius: 8px;
			border-top-right-radius: 8px;

			>div {
				width: 50%;
				padding: 5px;
			}
		}

		.body-ds-an-chia {
			display: flex;
			border-bottom: 1px solid var(--modal-input-border);

			>div {
				width: 50%;
				padding: 5px;
				white-space: pre-wrap;
				word-break: break-all;
			}

			&:last-child {
				border-bottom-left-radius: 8px;
				border-bottom-right-radius: 8px;
			}
		}
	}
}

#modal-ds-an-chia-dau-khach .style-for-web {
	.d-block {
		background-color: var(--ds-an-chia-background);
		border-radius: 8px;
		box-shadow: 0px 2px 6px var(--active-tab-box-shadow);

		.header-ds-an-chia {
			display: flex;
			border-bottom: 2px solid var(--modal-input-border);
			background-color: var(--header-ds-an-chia-background);
			border-top-left-radius: 8px;
			border-top-right-radius: 8px;

			>div {
				width: 25%;
				padding: 5px;
			}
		}

		.body-ds-an-chia {
			display: flex;
			border-bottom: 1px solid var(--modal-input-border);

			>div {
				width: 25%;
				padding: 5px;
				white-space: pre-wrap;
				word-break: break-all;
			}

			&:last-child {
				border-bottom-left-radius: 8px;
				border-bottom-right-radius: 8px;
			}
		}
	}
}
</style>
