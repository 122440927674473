import ApiService from "./api.service";

const Scanner = {
    profitWinLoss: async (url, data) => {
        url = url + "/scanner/profit-win-loss";
        return ApiService.post(url, data);
    },
    detail: async (url, data) => {
        url = url + "/scanner/detail";
        return ApiService.post(url, data);
    },
};

export default Scanner;
