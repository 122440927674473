import { Client } from '@stomp/stompjs';

export class SetupSocket {
    StompClient = (null);
    isConnected = false;
    constructor() {
        const newClient = new Client();
        this.StompClient = newClient;
    }

    onConnect(url, token) {
        if (this.StompClient) {
            // console.log(wsId);
            this.StompClient.configure({
                connectHeaders: {
                    Authorization: token,
                },
                reconnectDelay: 2000,
                heartbeatIncoming: 10000,
                heartbeatOutgoing: 10000,
                // ...configs,
                brokerURL: url + '/websocket',
                beforeConnect: () => {
                    console.log('before connect socket');
                },
                // default connect...
                onConnect: () => {
                    console.log('connected socket');
                    this.isConnected = true;
                },
                onStompError: (frame) => {
                    console.error(frame);
                    this.isConnected = false;
                    if (frame.headers.message && frame.headers.message.includes('JWT expired')) {
                        this.onDisconnect();
                    }
                },
            });
            this.StompClient.activate();
        }
    }

    onDisconnect() {
        if (this.StompClient) {
            this.StompClient.deactivate();
            this.isConnected = false;
        }
    }

    onUnsubscribe(topicId) {
        if (this.StompClient) {
            this.StompClient.unsubscribe(topicId);
        }
    }

    onWatchScanner(topicId, callback) {
        if (this.StompClient.connected) {
            const subscription = this.StompClient.subscribe(`/topic/scanner/${topicId}`, (message) => {
                if (message && message.body) {
                    callback(message.body);
                }
            }, { id: topicId });
            return subscription;
        }
        return null;
    }
}

export const Socket = new SetupSocket();