<template>
	<div id="home-page" class="home-page">
		page for mobile chua lam
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
	name: "HomePage",
	data() {
		return {

		};
	},
	computed: {
		...mapState("HomePage", []),
	},
	beforeCreate() {
		console.log("I have not been created yet!");
	},
	created() {
		console.log("I have just been created!");
	},
	beforeMount() {
		console.log("I am about to be mounted to the DOM!");
	},
	mounted() {
		console.log("I am mounted to the DOM!");
		this.forceMovePage();
	},
	beforeDestroy() {
		console.log("I am about to be removed from the DOM!");
	},
	destroyed() {
		console.log("I no longer exist...");
	},
	methods: {
		...mapActions("HomePage", []),
	},
};
</script>

<style lang="scss">
.home-page {
	.area-1 {
		display: flex;
		width: 100%;

		>div {
			width: 100%;
			padding-right: 10px;
		}

		.data-area {
			width: 100%;
			height: 12.5rem;
		}
	}

	.area-2 {
		display: flex;
		width: 100%;

		>div {
			width: 50%;
			padding-right: 10px;
		}

		.data-area {
			width: 100%;
			height: 12.5rem;
		}
	}

	.label-title {
		font-size: 1rem;
		font-weight: bold;
		color: #344767;
	}
}
</style>
