<template>
	<div id="quan-ly-tai-khoan" class="quan-ly-tai-khoan">
		<b-row class="header-page">
			<b-col cols="6">
				<span class="title-page">
					DANH SÁCH TÀI KHOẢN
				</span>
			</b-col>
			<b-col cols="6" class="text-right button-action">
				<b-button variant="primary" @click="openThemTaiKhoan" class="mr-2">
					<img :src="getIcon('add-circle')" />
				</b-button>
			</b-col>
		</b-row>
		<b-row class="header-page">
			<b-col cols="6">
				<b-input-group class="input-search">
					<b-input-group-prepend is-text>
						<img :src="getIcon('search-normal')" class="pr-2" />
					</b-input-group-prepend>
					<b-form-input ref="input-search" type="search" placeholder="Tìm kiếm"
						v-model="searchValue"></b-form-input>
				</b-input-group>
			</b-col>
		</b-row>
		<b-row class="mt-3">
			<b-col cols="12">
				<div class="danh-sach-tai-khoan">
					<b-table sticky-header hover :head-variant="$root.theme" small show-empty emptyText="Không có dữ liệu"
						empty-filtered-text="Không có dữ liệu phù hợp tìm kiếm" :fields="fieldsTaiKhoan"
						:items="dataTaiKhoan" :filter="searchValue" @filtered="onFiltered" @row-clicked="onRowClicked">
						<template #cell(name)="data">
							<span>{{ data.item.firstName + ' ' + data.item.lastName }}</span>
						</template>
						<template #cell(roles)="data">
							<span>{{ getRole(data.item) }}</span>
						</template>
						<template #cell(expirationDate)="data">
							<span>{{ getFormatDate(data.item.expirationDate, 'formatDateTimeNoSecondVN') }}</span>
						</template>
						<template #cell(action)="data">
							<div class="text-left button-action">
								<b-button variant="primary" class="mr-2"
									:class="data.item.isShowSetting ? 'active-setting' : ''"
									@click="showSetting(data.item)">
									<img :src="getIcon('more')" />
								</b-button>
								<div class="group-button-action pl-2 pr-2" v-show="data.item.isShowSetting">
									<div @click="chinhSuaTaiKhoan(data.item)">
										<b-button size="sm" :id="'btn-chinh-sua-' + data.item.index" variant="primary">
											<img :src="getIcon('edit-2')" />
										</b-button>
										<span class="ml-1">Chỉnh Sửa</span>
									</div>
									<div @click="setRowSelected(data.item)">
										<b-button size="sm" :id="'btn-xoa-tai-khoan-' + data.item.index" variant="danger">
											<img :src="getIcon('trash')" />
										</b-button>
										<span class="ml-1">Xóa</span>
									</div>
								</div>
							</div>
						</template>
					</b-table>
					<b-pagination v-if="dataTaiKhoan.length > 0" v-model="currentPage" pills :total-rows="totalRows"
						:per-page="perPage" align="right">
						<template #first-text>
							<b-icon icon="chevron-bar-left"></b-icon>
						</template>
						<template #prev-text>
							<b-icon icon="chevron-left"></b-icon>
						</template>
						<template #next-text>
							<b-icon icon="chevron-right"></b-icon>
						</template>
						<template #last-text>
							<b-icon icon="chevron-bar-right"></b-icon>
						</template>
					</b-pagination>
				</div>
			</b-col>
		</b-row>

		<b-modal centered id="modal-them-tai-khoan" :title="taiKhoanId ? 'CHỈNH SỬA TÀI KHOẢN' : 'THÊM TÀI KHOẢN'"
			scrollable size="lg" content-class="style-for-mobile">
			<div class="d-block">
				<b-row>
					<b-col cols="6">
						<div class="text-left">Tên Tài Khoản</div>
						<b-input-group class="mb-3">
							<b-form-input v-model="userName">
							</b-form-input>
						</b-input-group>
					</b-col>
					<b-col cols="6">
						<div class="text-left">Mật Khẩu</div>
						<b-input-group class="mb-3">
							<b-form-input v-model="password">
							</b-form-input>
							<b-input-group-append>
								<b-button variant="primary" @click="getPass">
									<img :src="getIcon('repeat')" title="Mật khẩu ngẫu nhiên" />
								</b-button>
							</b-input-group-append>
						</b-input-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col cols="6">
						<div class="text-left">Họ</div>
						<b-input-group class="mb-3">
							<b-form-input v-model="firstName">
							</b-form-input>
						</b-input-group>
					</b-col>
					<b-col cols="6">
						<div class="text-left">Tên</div>
						<b-input-group class="mb-3">
							<b-form-input v-model="lastName">
							</b-form-input>
						</b-input-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col cols="6">
						<div class="text-left">Số ĐT</div>
						<b-input-group class="mb-3">
							<b-form-input v-model="phone">
							</b-form-input>
						</b-input-group>
					</b-col>
					<b-col cols="6">
						<div class="text-left">Loại Tài Khoản</div>
						<b-input-group class="mb-3">
							<multi-select v-model="roles" :options="rolesOptions" :multiple="true" :close-on-select="false"
								:clear-on-select="false" :preserve-search="true" label="text" track-by="text"
								:preselect-first="true" class="multi-select" placeholder="">
							</multi-select>
						</b-input-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col cols="6">
						<div class="text-left">Ngày Hết Hạn</div>
						<b-input-group class="mb-3">
							<date-range-picker v-model="ngayHetHan" :single-date-picker="true" opens="left"
								:locale-data="$root.localeData" :autoApply="true" :ranges="false"
								@toggle="toggleDatePicker($event)"></date-range-picker>
						</b-input-group>
					</b-col>
					<b-col cols="6">
						<div class="text-left">Số Lần Quét Trong 1 Giờ</div>
						<b-input-group class="mb-3">
							<b-form-input type="number" v-model="soLanQuet">
							</b-form-input>
						</b-input-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col cols="6">
						<div class="text-left">Số Lần Lưu Lịch Sử</div>
						<b-input-group class="mb-3">
							<b-form-input type="number" v-model="soLanLuuLichSu">
							</b-form-input>
						</b-input-group>
					</b-col>
					<b-col cols="6">
						<div class="text-left">Tiền Hợp Đồng</div>
						<b-input-group class="mb-3">
							<b-form-input type="number" v-model="tienHopDong">
							</b-form-input>
						</b-input-group>
					</b-col>
				</b-row>
			</div>
			<template #modal-footer>
				<b-row class="w-100 mt-3">
					<b-col cols="4"></b-col>
					<b-col cols="4">
						<b-button block @click="$bvModal.hide('modal-them-tai-khoan')">
							Hủy
						</b-button>
					</b-col>
					<b-col cols="4">
						<b-button variant="primary" block @click="themTaiKhoan">
							{{ taiKhoanId ? 'Lưu' : 'Thêm' }}
						</b-button>
					</b-col>
				</b-row>
			</template>
		</b-modal>

		<b-modal centered id="modal-xac-nhan-xoa" title="XÁC NHẬN XÓA TÀI KHOẢN" scrollable size="sm"
			:content-class="$root.isMobile ? 'style-for-mobile' : 'style-for-web'">
			<div class="msg-xac-nhan-xoa">
				Bạn Chắc Chắn Muốn Xóa Tài Khoản
				<b>{{ `${rowSelected.userName} (${rowSelected.firstName} ${rowSelected.lastName})` }}</b>
			</div>
			<template #modal-footer>
				<b-row class="w-100 mt-3">
					<b-col cols="4"></b-col>
					<b-col cols="4">
						<b-button id="btn-xac-nhan-ko-xoa" block @click="huyXoaTaiKhoan()">
							Không
						</b-button>
					</b-col>
					<b-col cols="4">
						<b-button id="btn-xac-nhan-co-xoa" variant="danger" block @click="xoaTaiKhoan(rowSelected)">
							Có
						</b-button>
					</b-col>
				</b-row>
			</template>
		</b-modal>
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Account from "../../../service/account.service";

export default {
	name: "QuanLyTaiKhoan",
	data() {
		return {
			searchValue: '',
			fieldsTaiKhoan: [
				{ key: 'action', label: '' },
				{ key: 'userName', label: 'Tên Tài Khoản', sortable: true },
				{ key: 'name', label: 'Họ Tên' },
				{ key: 'phone', label: 'Số ĐT' },
				{ key: 'rateUsd', label: 'Tỷ Giá USD' },
				{ key: 'expirationDate', label: 'Ngày Hết Hạn', sortable: true },
				{ key: 'roles', label: 'Loại Tài Khoản' },
			],
			rolesOptions: [
				{
					text: 'Member',
					key: 'ROLE_MEMBER',
				},
				{
					text: 'Admin',
					key: 'ROLE_ADMIN',
				},
			],
			dataTaiKhoan: [],
			totalRows: 0,
			currentPage: 1,
			perPage: 15,
			taiKhoanId: '',
			userName: '',
			password: '',
			firstName: '',
			lastName: '',
			phone: '',
			roles: [],
			rateUsd: '',
			ngayHetHan: {
				startDate: null,
				endDate: null,
			},
			soLanQuet: '',
			soLanLuuLichSu: '',
			tienHopDong: '',
			rowSelected: {},
		};
	},
	computed: {
		...mapState("QuanLyTaiKhoan", []),
	},
	beforeCreate() {
		console.log("I have not been created yet!");
	},
	created() {
		console.log("I have just been created!");
	},
	beforeMount() {
		console.log("I am about to be mounted to the DOM!");
	},
	mounted() {
		console.log("I am mounted to the DOM!");
		this.forceMovePage();
		this.getAllAccount();
		window.addEventListener('keydown', this.keydown);
	},
	beforeDestroy() {
		console.log("I am about to be removed from the DOM!");
	},
	destroyed() {
		console.log("I no longer exist...");
		window.removeEventListener('keydown', this.keydown);
	},
	methods: {
		...mapActions("QuanLyTaiKhoan", []),
		keydown(e) {
			let key = e.keyCode || e.which;
			let isForcusDropdown = e.target.className === 'multiselect__input';
			if (isForcusDropdown) {
				return;
			}
			switch (key) {
				case 13: // enter
					if (document.getElementById('modal-them-tai-khoan')) {
						this.themTaiKhoan();
					} else
						if (document.getElementById('btn-xac-nhan-co-xoa')) {
							this.xoaTaiKhoan(this.rowSelected);
						}
					e.preventDefault();
					break;
				case 27: // esc
					if (document.getElementById('btn-xac-nhan-ko-xoa')) {
						this.huyXoaTaiKhoan();
					}
					e.preventDefault();
					break;
				case 122: // f11
					this.openThemTaiKhoan();
					e.preventDefault();
					break;
				case 114: // f3
					this.$refs['input-search'].$el.focus();
					e.preventDefault();
					break;
			}
		},
		getAllAccount() {
			let _this = this;
			Account.getAll(this.$root.url)
				.then((response) => {
					_this.dataTaiKhoan = response.data.map((i, index) => {
						i.index = index + 1;
						i.isShowSetting = false;
						return i
					});
					_this.totalRows = _this.dataTaiKhoan.length;
				}).catch((error) => {
					_this.errorApi(error, _this);
				});
		},
		getRole(data) {
			let returnStr = '';
			for (const [index, role] of data.roles.entries()) {
				let str = this.getStrRole(role);
				returnStr += str + (index < data.roles.length - 1 ? ', ' : '');
			}
			return returnStr;
		},
		getStrRole(value) {
			let str = value.toLowerCase();
			str = str.split('_');
			for (var i = 0; i < str.length; i++) {
				str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
			}
			return str.join(' ').replace(/Role /g, '');
		},
		openThemTaiKhoan() {
			this.taiKhoanId = '';
			this.userName = '';
			this.password = this.randomPass();
			this.firstName = '';
			this.lastName = '';
			this.phone = '';
			this.roles = '';
			this.rateUsd = 24;
			this.ngayHetHan = {
				startDate: this.getDateAdd('year', 1),
				endDate: this.getDateAdd('year', 1),
			};
			this.soLanQuet = 20;
			this.soLanLuuLichSu = 20;
			this.tienHopDong = '';
			this.$bvModal.show('modal-them-tai-khoan');
		},
		chinhSuaTaiKhoan(data) {
			let roles = data.roles.map(role => {
				return {
					text: this.getStrRole(role),
					key: role,
				}
			})

			this.taiKhoanId = data.id;
			this.userName = data.userName;
			this.password = '';
			this.firstName = data.firstName;
			this.lastName = data.lastName;
			this.phone = data.phone;
			this.roles = roles;
			this.rateUsd = data.rateUsd;
			this.ngayHetHan = {
				startDate: this.getFormatDate(data.expirationDate, this.$root.formatDateTimeNoSecondVN),
				endDate: this.getFormatDate(data.expirationDate, this.$root.formatDateTimeNoSecondVN),
			};
			this.soLanQuet = data.maxCrawlData;
			this.soLanLuuLichSu = data.maxHistory;
			this.tienHopDong = data.amountVnd;
			this.$bvModal.show('modal-them-tai-khoan');
		},
		themTaiKhoan() {
			let _this = this;
			let roles = this.roles.map(item => {
				return item.key;
			})
			let param = {
				userName: this.userName,
				passWord: this.password,
				firstName: this.firstName,
				lastName: this.lastName,
				phone: this.phone,
				roles: roles,
				rateUsd: this.rateUsd,
				expirationDate: this.$moment(new Date(this.ngayHetHan.startDate).setHours(0, 0, 0, 0)).format(),
				maxCrawlData: this.soLanQuet,
				maxHistory: this.soLanLuuLichSu,
				amountVnd: this.tienHopDong,
			};
			if (this.taiKhoanId) {
				param.id = this.taiKhoanId;
			}
			Account.post(this.$root.url, param)
				.then(() => {
					_this.getAllAccount();
					_this.$bvModal.hide("modal-them-tai-khoan");
					if (param.id) {
						_this.showToast("Chỉnh Sửa thành công!", "Chỉnh Sửa Tài Khoản", this, "success");
					} else {
						_this.showToast("Thêm thành công!", "Thêm Tài Khoản", this, "success");
					}
				})
				.catch((error) => {
					_this.errorApi(error, _this);
				});
		},
		huyXoaTaiKhoan() {
			this.$bvModal.hide('modal-xac-nhan-xoa');
		},
		xoaTaiKhoan(data) {
			let _this = this;
			Account.delete(this.$root.url, data.id)
				.then(() => {
					_this.getAllAccount();
					_this.showToast('Xóa thành công!', 'Xóa Tài Khoản', this, 'success')
					_this.$refs['popover-xoa-' + data.index].$emit('close');
				}).catch((error) => {
					_this.errorApi(error, _this);
				});
		},
		onFiltered(filteredItems) {
			this.totalRows = filteredItems.length;
			this.currentPage = 1;
		},
		setRowSelected(item) {
			this.rowSelected = this.cloneDeep(item);
			this.$bvModal.show('modal-xac-nhan-xoa');
		},
		showSetting(item) {
			for (const i of this.dataTaiKhoan) {
				if (item.index === i.index) {
					item.isShowSetting = !item.isShowSetting;
				} else {
					i.isShowSetting = false;
				}
			}
			if (item.isShowSetting) {
				this.rowSelected = item;
			} else {
				this.rowSelected = {};
			}
		},
		onRowClicked() {
			for (const i of this.dataTaiKhoan) {
				i.isShowSetting = false;
			}
		},
		toggleDatePicker(isOpen) {
			setTimeout(() => {
				if (isOpen) {
					let objDiv = document.getElementById('modal-them-tai-khoan___BV_modal_body_');
					objDiv.scrollTop = objDiv.scrollHeight;
				}
			}, 100);
		},
		getPass() {
			this.password = this.randomPass();
		},
	},
};
</script>

<style lang="scss">
.style-for-mobile .quan-ly-tai-khoan {
	.danh-sach-tai-khoan {
		background-color: var(--common-background);
		border-radius: 8px;
		box-shadow: 0px 2px 6px var(--active-tab-box-shadow);

		.btn {
			img {
				filter: var(--img-filter) !important;
			}
		}
	}
}
</style>
